import { Component, OnDestroy, OnInit } from "@angular/core";
import { ConstantsService } from "./global/constants/constants.service";
import { LoginService } from "./login/login.service";
import { SsoService } from "./login/sso.service";
import { LocaleMap } from "./global/constants/text/text-interface";
import { TextProvider } from "./global/constants/text/text-provider";
import { WebSocketService } from "./global/websocket/websocket.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-root",
	templateUrl: "./app.html",
	styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy {
	title = "IRIS";
	messages: any[] = [];

	private readonly text: () => LocaleMap;
	private subscriptions: Subscription = new Subscription();

	constructor(
		// all services that require to be instantiated on boot are injected here
		constantsService: ConstantsService,
		private loginService: LoginService,
		private ssoService: SsoService,
		private websocketService: WebSocketService,
		textProv: TextProvider
	) {
		this.text = textProv.getStringMap;

		if (constantsService.CLARITY_ID) this.loadClarity(constantsService.CLARITY_ID);
	} // code that needs to run on application boot should go here

	ngOnInit(): void {
		this.subscriptions.add(
			this.loginService.$onInitialLoadIng.subscribe(() => {
				this.loginService.hideSplashScreen = true;
			})
		);

		this.subscriptions.add(
			this.loginService.$token.asObservable().subscribe((token) => {
				if (token) {
					this.websocketService.connectWebSocketWithToken(token);
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.websocketService.close();
	}

	get authenticated(): boolean {
		return this.ssoService.authenticated || this.loginService.logged;
	}

	public readonly initialLoading = (): boolean => {
		return !this.loginService.splashScreenReady || !this.loginService.hideSplashScreen;
	};

	public readonly redirectToLogin = (): void => {
		if (!location.href.match(/login/)) this.loginService.onLogout();
	};

	public readonly splashScreenLoaded = (): boolean => {
		return (this.loginService.splashScreenReady = true);
	};

	public readonly loadClarity = (clarity_id: string): void => {
		(function (c, l, a, r, i, t, y) {
			// @ts-ignore
			c[a] =
				// @ts-ignore
				c[a] ||
				function () {
					// @ts-ignore
					(c[a].q = c[a].q || []).push(arguments);
				};
			// @ts-ignore
			t = l.createElement(r);
			// @ts-ignore
			t.async = 1;
			// @ts-ignore
			t.src = "https://www.clarity.ms/tag/" + i;
			// @ts-ignore
			y = l.getElementsByTagName(r)[0];
			// @ts-ignore
			y.parentNode.insertBefore(t, y);
		})(window, document, "clarity", "script", clarity_id);
	};
}
