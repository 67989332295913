import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ITEM_STATE, Stateful } from "src/app/dto/items/status";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-status-select-popup",
	templateUrl: "status-select.component.html",
	styleUrls: ["status-select.css"]
})
export class StatusSelectPopupComponent {
	@Input() currentStatus: ITEM_STATE = ITEM_STATE.CURRENT;

	@Output() statusChange = new Subject<ITEM_STATE>();

	public readonly States = ITEM_STATE;

	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	public readonly selectStatus: (state: ITEM_STATE) => void = (state) => {
		this.currentStatus = state;
		this.statusChange.next(state);
	};

	public readonly isStatusPredict = (): boolean => {
		return this.currentStatus === ITEM_STATE.PREDICT;
	};

	public readonly isStatusOver = (): boolean => {
		return this.currentStatus === ITEM_STATE.OVER;
	};

	public readonly isStatusCurrent = (): boolean => {
		return this.currentStatus === ITEM_STATE.CURRENT;
	};
}
