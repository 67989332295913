import { Component, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { PREFERRED_COLORS } from "src/app/global/constants/enums/preferred_colors";
@Component({
	selector: "app-arrow-color-bar",
	templateUrl: "app-arrow-color-bar.component.html",
	styleUrls: ["app-arrow-color-bar.css"]
})
export class ArrowColorBarComponent implements OnInit {
	@Input() selectedColor: string = ARROW_DEFAULT_COLORS.BLACK;

	@Output() colorChange = new Subject<string>();

	public preferredColorsArray: string[] = Object.values(ARROW_DEFAULT_COLORS);

	public showColorPicker: boolean = false;

	ngOnInit(): void {
		this.colorChange.next(this.selectedColor);
	}

	public changeColor(color: string): void {
		this.selectedColor = color;
		this.colorChange.next(color);
	}

	public openCloseColorPicker(): void {
		this.showColorPicker = !this.showColorPicker;
	}
}

export enum ARROW_DEFAULT_COLORS {
	RED = "#CC3333",
	YELLOW = "#FCD021",
	GREEN = "#2FC555",
	BLUE = "#2584E9",
	PURPLE = "#7244DE",
	BLACK = "#131313"
}
