<div class="node-wrapper">
	<div class="collapse-wrapper" (click)="toggleSector()" data-add-action-name="csCollapseSector">
		<button class="collapse-button" [ngStyle]="setTitleBackground()">
			<img class="collapse-img" [ngClass]="collapsed ? 'down' : 'up'" />
		</button>
	</div>
	<div data-cy="csSector" class="node-container">
		<div data-cy="csSectorTitle" class="title" [ngStyle]="setTitleBackground()">
			<div *ngIf="object.id_area !== -1" class="sector-icon-container">
				<img class="sector-icon" [style.backgroundColor]="setFlagBackground()" />
			</div>

			<span *ngIf="!editingSector">{{
				this.object.name ? this.object.name : text().SECTOR
			}}</span>
			<app-simple-text-field
				class="name-input"
				*ngIf="editingSector"
				[type]="'text'"
				[data]="object.name"
				(dataChange)="setSectorName($event)"
				[placeholderText]="text().SECTOR"
				[maxLength]="20"
				[padding]="'4px'"
				[focus]="isFocused"
				[textAlign]="'center'"
				[isDisabled]="disable || isIncidentClosed()"
			>
			</app-simple-text-field>
			<app-blue-cross
				*ngIf="!mapMode && !disable"
				class="menu-button {{ isMenuOpen() ? 'open' : '' }}"
				(clickCb)="openMenu($event)"
				[open]="isMenuOpen"
				[isInCommandSector]="true"
				[shouldShow]="!isIncidentClosed()"
			>
			</app-blue-cross>
			<app-blue-cross
				class="menu-button"
				*ngIf="mapMode"
				[open]="true"
				(clickCb)="cancel.emit()"
				(touchend)="cancel.emit()"
				[isInCommandSector]="true"
			></app-blue-cross>
		</div>
		<div
			class="sector-body"
			[class.collapsed]="collapsed"
			data-cy="sector-body"
			[style.pointerEvents]="mapMode ? 'none' : 'all'"
		>
			<div class="sector-top" style="flex-direction: column">
				<div
					class="span-div"
					[attr.sectorId]="object.id"
					dropzone="sectorCommander"
					[class.dragging]="draggingPersonnel"
					[class.hide]="dragging"
					style="margin-bottom: 8px"
				>
					<span class="sc-label">S.C : </span>
					<span *ngIf="object.id_resource_comm === -1" class="drag-here-label"
						>({{ this.text().DRAG_HERE }})</span
					>
					<div
						*ngIf="object.id_resource_comm > -1"
						[style.pointerEvents]="disable ? 'none' : 'all'"
						class="agent-comm-label"
						appDroppable
						[disabled]="disable"
						[dragInfo]="object.id_resource_comm"
						(dragStart)="onResourceDragStart($event)"
						(dragCancel)="onResourceDragCancel($event)"
						(dragEnd)="onResourceDragEnd($event)"
					>
						{{ object.__sectorCommander?.name }}
					</div>
				</div>
				<div
					*ngIf="!collapsed"
					class="span-div"
					[attr.sectorId]="object.id"
					dropzone="safetyOfficer"
					[class.dragging]="draggingPersonnel"
					[class.hide]="dragging"
				>
					<span class="sc-label">S.O : </span>
					<span *ngIf="object.id_safety_officer === -1" class="drag-here-label"
						>({{ this.text().DRAG_HERE }})</span
					>
					<div
						*ngIf="object.id_safety_officer > -1"
						[style.pointerEvents]="disable ? 'none' : 'all'"
						class="agent-comm-label"
						appDroppable
						[disabled]="disable"
						[dragInfo]="object.id_safety_officer"
						(dragStart)="onResourceDragStart($event)"
						(dragCancel)="onResourceDragCancel($event)"
						(dragEnd)="onResourceDragEnd($event)"
					>
						{{ object.__safetyOfficer?.name }}
					</div>
				</div>
			</div>

			<div *ngIf="!collapsed" class="sector-tasks" data-cy="sector-tasks">
				<div
					*ngIf="!isInFocus"
					(click)="expandNotes()"
					[ngClass]="{
						'notes collapsed-textarea': true,
						notes: true,
						disabled: shouldDisable() || disable,
						'gray-200': !object.tasks,
						drag: dragging || draggingPersonnel
					}"
				>
					{{ object.tasks || text().NOTES }}
				</div>
				<textarea
					*ngIf="isInFocus"
					#notesTextarea
					appAutoResize
					class="notes expanded-textarea"
					[(ngModel)]="object.tasks"
					[class.drag]="dragging || draggingPersonnel"
					rows="1"
					(input)="onNotesInput($event)"
					(blur)="onNotesBlur()"
					[disabled]="shouldDisable() || disable"
				></textarea>
			</div>

			<div
				*ngIf="!collapsed"
				class="sector-resources"
				[style.pointerEvents]="disable ? 'none' : 'all'"
			>
				<span>{{ text().RESOURCES.toUpperCase() }} : </span>
				<div
					class="resource-list dropazone"
					[attr.sectorId]="object.id"
					dropzone="sectorResources"
					[class.dragging]="dragging"
				>
					<div
						*ngIf="!object.resources || object.resources.length === 0"
						class="drag-here-label"
					>
						({{ this.text().DRAG_HERE }})
					</div>
					<div
						*ngFor="let resource of object.resources; let j = index"
						class="resource-item"
						(touchstart)="onTouchStart($event)"
						(touchmove)="onTouchMove($event)"
						(touchend)="onTouchEnd($event)"
					>
						<div *ngIf="resource.__object && !resource.__object!.is_appliance">
							<div
								class="resource-name-container"
								appDroppable
								[disabled]="disable"
								[dragInfo]="resource.id_resource"
								(dragStart)="onResourceDragStart($event)"
								(dragCancel)="onResourceDragCancel($event)"
								(dragEnd)="onResourceDragEnd($event)"
							>
								<div
									class="status-display circle"
									appSetStyle
									[ngStyle]="setStatusBackground(resource)"
								></div>
								<div class="personnel-name">{{ resource.__name }}</div>
							</div>

							<app-skills-display
								*ngIf="resource.__object.skills"
								[resource]="resource.__object"
								[size]="20"
								[disabled]="isIncidentClosed()"
								[shouldShowTooltip]="false"
								[shownSkillIndexes]="shownSkillIndexes"
								(toggleSkillsOverflowEvent)="toggleSkills(j)"
							>
							</app-skills-display>
						</div>
						<app-cs-appliance
							*ngIf="resource.__object?.is_appliance"
							[resource]="resource.__object"
							[expanded]="expandedStates[resource.id]"
							[draggingPersonnel]="draggingPersonnel"
							[draggingResource]="dragging"
							[incidentClosed]="isIncidentClosed()"
							(dragStart)="onResourceDragStart($event)"
							(dragCancel)="onResourceDragCancel($event)"
							(dragEnd)="onResourceDragEnd($event)"
						></app-cs-appliance>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="object.id_area !== -1 && object.__area && !collapsed"
			data-add-action-name="csGoTo"
			class="sector-footer footer-button"
			(click)="goToArea()"
		>
			<img src="resources/img/location-focus.svg" class="focus-icon" />
			<span *ngIf="!mapMode" class="footer-button-text">
				{{ text().GO_TO }}
			</span>
		</div>
		<div
			*ngIf="shouldShowFooterButton()"
			data-add-action-name="cs-assign-area"
			class="sector-footer footer-button"
			[class.disabled]="isIncidentClosed()"
			(click)="onMainUnassignedClick(); $event.stopPropagation()"
		>
			<span *ngIf="!mapMode" class="footer-button-text">
				{{ text().ASSIGN_AREA }}
			</span>
		</div>
	</div>

	<!-- EXT. MENU -->
	<div data-cy="csExtendedMenu" class="extended-menu" *ngIf="isMenuOpen()">
		<div class="title" appSetStyle [ngStyle]="setTitleBackground()"></div>
		<div data-add-action-name="csMenuEdit" class="option" (click)="toggleEditable(object)">
			{{ text().EDIT }}
		</div>
		<div
			data-add-action-name="csMenuReassign"
			class="option"
			*ngIf="object.id_area > -1"
			(click)="$event.stopPropagation(); onMainUnassignedClick()"
		>
			{{ text().REASSIGN }}
		</div>
		<div data-add-action-name="csMenuDelete" class="option" (click)="removeSector.emit()">
			{{ text().DELETE }}
		</div>
	</div>
</div>
