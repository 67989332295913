import { GMapMarker } from "../../map/gmap-marker";
import { MapItem } from "../map-item";
import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { UploadedFile } from "../../net/uploaded-file";
import { EsriMarker } from "src/app/incident/map/components/map/esri/components/graphics/esri-marker/esri-marker.interface";
import { WGSPoint } from "../../map/location";
import { EsriShapeComponent } from "src/app/incident/map/components/map/esri/components/graphics/esri-shape/esri-shape";
import { ShapeSet } from "../../map/shape-set";
import { ITEM_STATE, Stateful } from "../status";
import { DTO } from "../../net/dto";
import { Attachable } from "../attachable";

export class Arrow extends MapItem implements DTO, Stateful, Attachable {
	geometry: Array<WGSPoint> = [];
	color: string = "#000000";

	status: ITEM_STATE = ITEM_STATE.CURRENT;

	deleted: boolean;

	__shape: ShapeSet | EsriShapeComponent | undefined;
	__marker: GMapMarker | EsriMarker | undefined;

	attachmentList: Array<UploadedFile> = [];

	timestamp = Date.now();

	// we void default names to hide them on the interface
	trueName?: string;

	public constructor(
		id_mission: number,
		id: number,
		vertices: Array<WGSPoint>,
		color: string,
		status?: number,
		name?: string,
		description?: string,
		deleted?: boolean,
		attachment_id?: number,
		attachment_filename?: string,
		timestamp?: number
	) {
		super(
			id,
			name !== undefined ? name : "unsaved arrow",
			id_mission,
			description,
			MAP_ITEM_TYPE.AREA,
			attachment_id,
			attachment_filename
		);
		this.id_incident = id_mission;
		this.deleted = deleted ? deleted : false;
		this.geometry = vertices;
		this.color = color;
		this.status = status ?? ITEM_STATE.CURRENT;
		this.trueName = this.name;
		if (this.name.includes("Arrow-") || this.name.includes("MapItem-")) this.name = ""; //filter default name out
	}

	// this fromJson implementation should only be called on malformed strings without shapeType property, otherwise defer to the fromJson implementations from children of this class
	public static fromJson: (json: string) => Arrow = (json) => {
		let jc = JSON.parse(json);
		let finalGeom = [];
		try {
			let fixedGeom = jc.geometry.replaceAll("\\", "");
			let firstTrim = fixedGeom.substring(fixedGeom.indexOf("["), fixedGeom.length);
			let secondTrim = firstTrim.substring(0, firstTrim.indexOf("]") + 1);
			finalGeom = JSON.parse(secondTrim);
		} catch (e) {
			console.error("Error parsing geometry string " + jc.geometry);
		}
		return new Arrow(
			jc.id_incident,
			jc.id_arrow,
			finalGeom,
			jc.color,
			jc.status,
			jc.name,
			jc.description,
			jc.deleted,
			jc.attachment_id,
			jc.attachment_filename,
			jc.timestamp
		);
	};

	public getJson(): string {
		let output = this as any;
		output.id_arrow = this.id;
		output.name = this.name && this.name.length ? this.name : this.trueName;
		output.geometry =
			typeof this.geometry === "string" ? this.geometry : JSON.stringify(this.geometry);
		return JSON.stringify(output, [
			"id_arrow",
			"id_incident",
			"geometry",
			"color",
			"name",
			"description",
			"deleted",
			"status",
			"timestamp"
		]);
	}
}
