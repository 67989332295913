import { ResourceTooltipComponent } from "./labels/map/resource/resource-tooltip/resource-tooltip.component";
import { StatesMenuComponent } from "./popups/states-menu/states-menu.component";
import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ResourceLogListComponent } from "src/app/widgets/ui/lists/resources-log-list/resources-log-list.component";
import { DirectiveModule } from "../directive/directive.module";
import { AreaCardComponent } from "./cards/area-type/area-card.component";
import { CameraCardComponent } from "./cards/camera/camera-card.component";
import { IncidentCardComponent } from "./cards/incident-type/incident-card.component";
import { FsgSetJesipCardComponent } from "./cards/jesip/fsg/set/fsg-set-jesip-card.component";
import { FsgVoidJesipCardComponent } from "./cards/jesip/fsg/void/fsg-void-jesip-card.component";
import { SetJesipCardComponent } from "./cards/jesip/last-update/set-jesip-card.component";
import { VoidJesipCardComponent } from "./cards/jesip/void/void-jesip-card.component";
import { KmlItemComponent } from "./cards/kml-item/kml-item.component";
import { PoiCardComponent } from "./cards/poi-type/poi-card.component";
import { ApplianceCardComponent } from "./cards/resource/appliance-card.component";
import { PersonnelCardComponent } from "./cards/resource/personnel-card.component";
import { BlueDropdownComponent } from "./dropdown/blue-dropdown/blue-dropdown.component";
import { BorderDropdownComponent } from "./dropdown/border-dropdown/border-dropdown.component";
import { LanguageDropdownComponent } from "./dropdown/language-dropdown/language-dropdown.component";
import { TransparentDropdownComponent } from "./dropdown/transparent-dropdown/transparent-dropdown.component";
import { BlueCheckboxComponent } from "./elements/blue-checkbox/blue-checkbox.component";
import { BlueCrossButtonComponent } from "./elements/blue-gradient-cross/blue-gradient-cross.component";
import { ColorPaletteComponent } from "./elements/color-picker/color-palette/color-palette.component";
import { ColorPickerComponent } from "./elements/color-picker/color-picker.component";
import { ColorSliderComponent } from "./elements/color-picker/color-slider/color-slider.component";
import { PadLockOpenComponent } from "./elements/pad-lock-open/pad-lock-open.component";
import { PadLockComponent } from "./elements/pad-lock/pad-lock.component";
import { RoundCheckboxComponent } from "./elements/round-checkbox/round-checkbox.component";
import { SimplePaginationComponent } from "./elements/simple-pagination/simple-pagination.component";
import { SimpleTextBoxComponent } from "./elements/simple-text-box/simple-text-box.component";
import { SimpleTextFieldComponent } from "./elements/simple-text-field/simple-text-field.component";
import { ToggleSwitchComponent } from "./elements/toggle-switch/toggle-switch.component";
import { WhiteCrossAddButtonComponent } from "./elements/white-cross-add/white-cross-add.component";
import { WhiteCrossButtonComponent } from "./elements/white-cross/white-cross.component";
import { BlueButtonSearchComponent } from "./filters/blue-button-search/blue-button-search.component";
import { CalendarFilterComponent } from "./filters/calendar-filter/calendar-filter.component";
import { incidentTypeFilterComponent } from "./filters/incident-type-filter/incident-type-filter.component";
import { PlainInputSearchComponent } from "./filters/plain-input-search/plain-input-search.component";
import { CommandChartGraphicComponent } from "./graphics/command-structure/command-chart.component";
import { CSCommanderNodeComponent } from "./graphics/command-structure/components/commander/commander.component";
import { CSControlRoomNodeComponent } from "./graphics/command-structure/components/control-room/control-room.component";
import { CSSectorNodeComponent } from "./graphics/command-structure/components/sector/sector.component";
import { CSSupportNodeComponent } from "./graphics/command-structure/components/support/support.component";
import { SvgPreviewComponent } from "./helpers/svg-preview/svg-preview.component";
import { UploadDragZoneComponent } from "./helpers/upload-drag-zone/upload-drag-zone.component";
import { AreaRadiusLabelComponent } from "./labels/map/area-radius/area-radius.component";
import { AreaLabelComponent } from "./labels/map/area/area-label.component";
import { FsgLabelComponent } from "./labels/map/fsg/fsg-label.component";
import { PoiLabelComponent } from "./labels/map/poi/poi-label.component";
import { ResourceLabelComponent } from "./labels/map/resource/resource-label.component";
import { SchematicLabelComponent } from "./labels/map/schematic/schematic.component";
import { TrackPointLabelComponent } from "./labels/map/track-point/track-point-label.component";
import { NotificationComponent } from "./labels/notification/notification.component";
import { BlueListComponent } from "./lists/blue-list/blue-list.component";
import { DebriefListComponent } from "./lists/debrief-list/debrief-list.component";
import { DecisionListComponent } from "./lists/decision-list/decision-list.component";
import { JesipListComponent } from "./lists/jesip-list/jesip-list.component";
import { TeamListComponent } from "./lists/team-list/team-list.component";
import { BlackNavTabComponent } from "./navigation/black-nav-tab/black-nav-tab.component";
import { BlueBarNavTabComponent } from "./navigation/blue-bar-nav-tab/blue-bar-nav-tab.component";
import { NavTabComponent } from "./navigation/nav-tab.component";
import { AddImageDialogComponent } from "./popups/add-image-dialog/add-image.component";
import { AddOverlayDialogComponent } from "./popups/add-overlay-dialog/add-overlay.component";
import { AraSeverityRatingTooltipComponent } from "./popups/ara/ara-severity-rating-tooltip/ara-severity-rating-tooltip.component";
import { RiskInformationsModalComponent } from "./popups/ara/risk-informations/risk-informations-modal.component";
import { AssignCameraStreamingComponent } from "./popups/assign-camera-streaming-modal/assign-camera-streaming.component";
import { AssignHardwareModalComponent } from "./popups/assign-hardware-modal/assign-hardware.component";
import { AssignResourceModalComponent } from "./popups/assign-resource-model/assign-resource.component";
import { AssignSkillModalComponent } from "./popups/assign-skill-modal/assign-skill.component";
import { ChangePasswordModalComponent } from "./popups/change-password-modal/change-password.component";
import { ConfirmationDialogComponent } from "./popups/confirmation-dialog/confirmation-dialog.component";
import { DownloadDeletePopupComponent } from "./popups/download-delete-popup/download-delete-popup.component";
import { ErrorDialogComponent } from "./popups/error-dialog/app-error-dialog.component";
import { FsgSectorInfoTooltipComponent } from "./popups/fsg-editor-popups/sector-info-tooltip/fsg-sector-info.component";
import { FsgSectorSelectPopupComponent } from "./popups/fsg-editor-popups/sector-select-popup/sector-select.component";
import { SelectAddressPopupComponent } from "./popups/fsg-editor-popups/select-address-popup/select-address.component";
import { FsgSelectAdvicePopupComponent } from "./popups/fsg-editor-popups/select-advice/fsg-select-advice.component";
import { FsgSelectConditionPopupComponent } from "./popups/fsg-editor-popups/select-condition/fsg-select-condition.component";
import { FsgSelectPriorityPopupComponent } from "./popups/fsg-editor-popups/select-priority/fsg-select-priority.component";
import { FsgSelectTypePopupComponent } from "./popups/fsg-editor-popups/select-type/fsg-select-type.component";
import { FsgSelectSoiPopupComponent } from "./popups/fsg-editor-popups/select-soi/fsg-select-soi.component";
import { ImageViewerComponent } from "./popups/image-viewer/image-viewer.component";
import { JesipHistoryComponent } from "./popups/jesip-history/jesip-history.component";
import { JesipNotesComponent } from "./popups/jesip-notes/jesip-notes.component";
import { LoadingDialogComponent } from "./popups/loading-dialog/loading-dialog.component";
import { AreaEditModalComponent } from "./popups/map-item-modals/area/area-edit.component";
import { PoiEditModalComponent } from "./popups/map-item-modals/poi/poi-edit.component";
import { ResourceEditModalComponent } from "./popups/map-item-modals/resource/resource-edit.component";
import { SchematicEditModalComponent } from "./popups/map-item-modals/schematic/schematic-edit.component";
import { NewFsgModalComponent } from "./popups/new-fsg-modal/new-fsg-modal.component";
import { NewResourceTypeModalComponent } from "./popups/new-resource-type-modal/new-resource-type.component";
import { TwoOptionDialogComponent } from "./popups/two-option-dialog/two-option-dialog.component";
import { BlueSignatureCanvasComponent } from "./signature/blue-canvas/blue-canvas.component";
import { SignatureCanvasComponent } from "./signature/simple-canvas/canvas-component.component";
import { OverlayViewerComponent } from "./popups/plane-viewer/overlay-viewer.component";
import { PersonnelCardSsoComponent } from "./cards/resource/personnel-card-sso/personnel-card-sso.component";
import { ResourceIconComponent } from "./cards/resource/resource-icon/resource-icon.component";
import { AddSkillComponent } from "./cards/resource/add-skill/add-skill.component";
import { AddCameraComponent } from "./cards/resource/add-camera/add-camera.component";
import { TaskCompletedDialogComponent } from "./popups/task-completed/task-completed.component";
import { ClosedIncidentInfoDialogComponent } from "./popups/closed-incident-info-dialog/closed-incident-info-dialog.component";
import { AuthenticationDialogComponent } from "./popups/authentication-dialog/authentication-dialog.component";
import { TextFilterComponent } from "./filters/text-filter/text-filter.component";
import { InfiniteScrollComponent } from "./helpers/infinite-scroll/infinite-scroll.component";
import { MapItemDialogComponent } from "./popups/app-map-item-dialog/app-map-item-dialog.component";
import { SearchBarComponent } from "./popups/app-map-item-dialog/components/search-bar/app-search-bar.component";
import { BodyPoiComponent } from "./popups/app-map-item-dialog/components/body-poi/app-body-poi.component";
import { BodyAreaComponent } from "./popups/app-map-item-dialog/components/body-area/app-body-area";
import { NoResultsComponent } from "./popups/app-map-item-dialog/components/no-results/app-no-results.component";
import { IrisHeaderComponent } from "./header/app-iris-header.component";
import { BaseHrefPipe } from "../pipes/base-href.pipe";
import { SupportDialogComponent } from "./popups/support-dialog-container/support-dialog/support-dialog.component";
import { KebabMenuComponent } from "./navigation/kebab-menu/kebab-menu.component";
import { SupportDialogContainerComponent } from "./popups/support-dialog-container/support-dialog-container.component";
import { SignatureButtonComponent } from "./signature/signature-button/signature-button.component";
import { InformationPopupComponent } from "./popups/fsg-editor-popups/inormation-popup/information-popup.component";
import { FilterFlatsDialogComponent } from "./popups/fsg-editor-popups/filter-flats-dialog/filter-flats-dialog.component";
import { PriorityChipComponent } from "./elements/chips/priority-chip/priority-chip.component";
import { NamingConventionModalComponent } from "./popups/fsg-editor-popups/naming-convention-modal/naming-convention-modal.component";
import { NamingConventionComponent } from "./popups/fsg-editor-popups/naming-convention-modal/naming-convention/naming-convention.component";
import { ErrorBannerComponent } from "./popups/error-banner/error-banner.component";
import { CompulsoryFieldsComponent } from "./popups/compulsory-fields/compulsory-fields.component";
import { RiskRatingModalComponent } from "./popups/ara/ara-risk-rating-modal/risk-rating-modal.component";
import { ChipsComponent } from "./elements/chips/chips/chips.component";
import { SLMatrixComponent } from "./elements/sl-matrix/sl-matrix.component";
import { InfoTooltipWrapperComponent } from "./labels/info-tooltip/info-tooltip-wrapper/info-tooltip-wrapper.component";
import { InfoTooltipComponent } from "./labels/info-tooltip/info-tooltip-wrapper/info-tooltip/info-tooltip.component";
import { GlassBackgroundComponent } from "./elements/glass-background/glass-background.component";
import { OnlineOfflineBannerComponent } from "./header/online-offline-banner/online-offline-banner.component";
import { UserPermissionModalComponent } from "./popups/user-permission-modal-component/user-permission-modal.component";
import { UrlSanitizerPipe } from "../pipes/url-sanitizer.pipe";
import { SettingsModalComponent } from "./popups/settings-modal-component/settings-modal.component";
import { ArraySanitizerPipe } from "../pipes/arraySanitizer.pipe";
import { SkillsDisplayComponent } from "./cards/skills/skills-display.component";
import { PopupChipComponent } from "./elements/popup-chip/popup-chip.component";
import { HeaderChipComponent } from "./elements/chips/header-chip/header-chip.component";
import { HighlightPipe } from "../pipes/highlight-search.pipe";

const elements = [
	TransparentDropdownComponent,
	SignatureCanvasComponent,
	BlueSignatureCanvasComponent,
	AraSeverityRatingTooltipComponent,
	BorderDropdownComponent,
	BlueDropdownComponent,
	ErrorDialogComponent,
	ConfirmationDialogComponent,
	WhiteCrossButtonComponent,
	NavTabComponent,
	BlackNavTabComponent,
	BlueCrossButtonComponent,
	ToggleSwitchComponent,
	PoiCardComponent,
	SvgPreviewComponent,
	AreaCardComponent,
	IncidentCardComponent,
	KmlItemComponent,
	ApplianceCardComponent,
	PersonnelCardComponent,
	BlueButtonSearchComponent,
	NewResourceTypeModalComponent,
	SimpleTextFieldComponent,
	SimpleTextBoxComponent,
	AssignHardwareModalComponent,
	CameraCardComponent,
	ChangePasswordModalComponent,
	PlainInputSearchComponent,
	CalendarFilterComponent,
	BlueListComponent,
	ResourceLogListComponent,
	DecisionListComponent,
	SimplePaginationComponent,
	AssignResourceModalComponent,
	BlueCheckboxComponent,
	PoiLabelComponent,
	AreaLabelComponent,
	FsgLabelComponent,
	ResourceLabelComponent,
	incidentTypeFilterComponent,
	FilterFlatsDialogComponent,
	AddOverlayDialogComponent,
	UploadDragZoneComponent,
	RoundCheckboxComponent,
	AssignCameraStreamingComponent,
	PoiEditModalComponent,
	AreaEditModalComponent,
	ResourceEditModalComponent,
	ImageViewerComponent,
	SchematicEditModalComponent,
	BlueBarNavTabComponent,
	CSCommanderNodeComponent,
	CSControlRoomNodeComponent,
	CSSupportNodeComponent,
	CSSectorNodeComponent,
	CommandChartGraphicComponent,
	AddImageDialogComponent,
	FsgSectorInfoTooltipComponent,
	RiskInformationsModalComponent,
	FsgSelectAdvicePopupComponent,
	NewFsgModalComponent,
	RiskInformationsModalComponent,
	FsgSelectSoiPopupComponent,
	FsgSelectPriorityPopupComponent,
	FsgSelectConditionPopupComponent,
	FsgSelectTypePopupComponent,
	FsgSectorSelectPopupComponent,
	SelectAddressPopupComponent,
	VoidJesipCardComponent,
	SetJesipCardComponent,
	FsgVoidJesipCardComponent,
	FsgSetJesipCardComponent,
	AssignSkillModalComponent,
	PadLockComponent,
	DebriefListComponent,
	TeamListComponent,
	PadLockOpenComponent,
	TwoOptionDialogComponent,
	ColorPickerComponent,
	ColorSliderComponent,
	ColorPaletteComponent,
	JesipListComponent,
	JesipHistoryComponent,
	JesipNotesComponent,
	TwoOptionDialogComponent,
	DownloadDeletePopupComponent,
	LoadingDialogComponent,
	LanguageDropdownComponent,
	WhiteCrossAddButtonComponent,
	TrackPointLabelComponent,
	AreaRadiusLabelComponent,
	SchematicLabelComponent,
	NotificationComponent,
	OverlayViewerComponent,
	TaskCompletedDialogComponent,
	ClosedIncidentInfoDialogComponent,
	AuthenticationDialogComponent,
	PersonnelCardSsoComponent,
	ResourceIconComponent,
	AddSkillComponent,
	AddCameraComponent,
	TextFilterComponent,
	InfiniteScrollComponent,
	MapItemDialogComponent,
	SearchBarComponent,
	BodyPoiComponent,
	BodyAreaComponent,
	NoResultsComponent,
	IrisHeaderComponent,
	BaseHrefPipe,
	SupportDialogComponent,
	KebabMenuComponent,
	SupportDialogContainerComponent,
	SignatureButtonComponent,
	InformationPopupComponent,
	PriorityChipComponent,
	NamingConventionModalComponent,
	NamingConventionComponent,
	StatesMenuComponent,
	ResourceTooltipComponent,
	ErrorBannerComponent,
	CompulsoryFieldsComponent,
	RiskRatingModalComponent,
	ChipsComponent,
	SLMatrixComponent,
	InfoTooltipWrapperComponent,
	InfoTooltipComponent,
	GlassBackgroundComponent,
	OnlineOfflineBannerComponent,
	UserPermissionModalComponent,
	UrlSanitizerPipe,
	SettingsModalComponent,
	ArraySanitizerPipe,
	SkillsDisplayComponent,
	PopupChipComponent,
	HeaderChipComponent,
	HighlightPipe
];

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: elements,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, DirectiveModule],
	exports: elements
})
export class UIModule {}
