import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConstantsService } from "src/app/global/constants/constants.service";
import { RESPONSE_STATUS, WSResponse } from "src/app/dto/net/wsresponse";
import { WSRequest } from "src/app/dto/net/wsrequest";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { DBError } from "../dto/net/dberror";
import { Subject } from "rxjs";
import { URLMap } from "../global/constants/enums/url-map";
import { Incident } from "../dto/items/incident";

@Injectable({
	providedIn: "root"
})
export class WebRequestsService {
	public credentials: any;
	public authHeader: string = "";

	public errorHandler$ = new Subject<{ type: MESSAGE_TYPE; item: any; url: string }>();
	public successHandler$ = new Subject<{ item: any; url: string }>();
	private readonly http: HttpClient;
	private readonly cnst: ConstantsService;
	private requestsPending: number = 0;

	constructor(http: HttpClient, cnst: ConstantsService) {
		this.http = http;
		this.cnst = cnst;
		this.credentials = {
			username: "",
			token: ""
		};
	}

	public readonly setCredentials: Function = (username: string, token: string) => {
		this.credentials.username = username;
		this.credentials.token = token;
		this.authHeader = "Bearer " + token;
	};

	public readonly getWSJSONlogin: Function = (url: string, username: string, password: string, cresult: string) => {
		this.requestSent(url);
		return this.http
			.get(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: "Basic " + window.btoa(username + ":" + password) + (cresult ? "," + cresult : "")
				},
				observe: "response"
			})
			.toPromise()
			.then(this.verboseManageWSResponse, this.verboseManageWSResponse)
			.catch(this.cnst.errorHandler);
	};

	public readonly uploadFile: Function = (url: string, name: string, data: any) => {
		var fd = new FormData();
		fd.append("file", data, name);
		return this.http
			.post(url, fd, {
				headers: {
					Authorization: this.authHeader
				},
				observe: "response"
			})
			.toPromise()
			.then(this.manageWSResponse as any, this.manageWSResponse)
			.catch(this.cnst.errorHandler);
	};

	public readonly getWSJSON: Function = (url: string, queryParams: any, silent: boolean) => {
		if (!silent) this.requestSent(url);
		return this.http
			.get(url + this.makeQueryParamsString(queryParams), {
				headers: {
					"Content-Type": "application/json",
					Authorization: this.authHeader
				},
				observe: "response"
			})
			.toPromise()
			.then(silent ? this.manageWSResponse : (this.verboseManageWSResponse as any), this.cnst.errorHandler);
	};

	public readonly postWSJSON: Function = (url: string, request: WSRequest, silent: boolean) => {
		if (!silent) this.requestSent(url);
		return this.http
			.post(url, request ? request.getJson() : null, {
				headers: {
					"Content-Type": "application/json",
					Authorization: this.authHeader
				},
				observe: "response"
			})
			.toPromise()
			.then(this.manageWSResponse as any, this.manageWSResponse)
			.catch(this.cnst.errorHandler);
	};

	public readonly postDLJSON: Function = (url: string, data: any) => {
		this.requestSent(url);
		const request = data ? JSON.parse(data) : null;
		return this.http
			.post(url, request, {
				headers: {
					"Content-Type": "application/json",
					Authorization: this.authHeader
				},
				observe: "response"
			})
			.toPromise();
	};

	public readonly getFile: Function = (destName: string, url: string) => {
		return this.http
			.get(url, {
				headers: {
					Authorization: this.authHeader
				},
				responseType: "blob",
				observe: "response"
			})
			.toPromise()
			.then(function (response: any) {
				if (response.status === 200) {
					return response.body;
				}
			}, this.manageWSResponse)
			.catch(this.cnst.errorHandler);
	};

	public readonly getIncidentAttachments: Function = (mission: Incident) => {
		const request = mission.getJson();
		return this.http
			.post(URLMap.WSURL() + URLMap.GET.MISSION_DATA.ATTACHMENTS_ZIP, request, {
				headers: {
					Authorization: this.authHeader,
					"Content-Type": "application/json"
				},
				responseType: "blob",
				observe: "response"
			})
			.toPromise()
			.then(function (response: any) {
				if (response.status === 200) {
					return response.body;
				}
			}, (res) => {console.log(res.message)})
			.catch(this.cnst.errorHandler);
	};

	public readonly ping: (destName: string) => Promise<boolean> = (destName: string) => {
		return this.http
			.post(destName + URLMap.GET.LAST_CHANGE, JSON.stringify({ elementId: null, missionId: null, DAO: { id: -1, last_update: 0, datatype: "unspecified", id_mission: -1 }, timestamp: 0 }), {
				headers: {
					Authorization: this.authHeader,
					"Content-Type": "application/json"
				},
				responseType: "blob",
				observe: "response"
			})
			.toPromise()
			.then(function (response: any) {
				if (response && response.status) {
					return true;
				}
				return false;
			})
			.catch((response: any) => {
				if (response && response.status) {
					return true;
				}
				return false;
			});
	};

	private readonly manageWSResponse = (response: { body: { singleItem: any; arrayItems: any[]; status: string }; status: number; singleItem: any; message: string; error?: string; url: string }): void => {
		const wsres = new WSResponse(response);
		switch (wsres.status) {
			case RESPONSE_STATUS.SUCCESS:
				this.successHandler$.next({ item: wsres.data, url: wsres.url });
				return wsres.data ? wsres.data : true;
			case RESPONSE_STATUS.ERROR:
				return this.errorHandler$.next({ type: MESSAGE_TYPE.ERROR_DATABASE, item: DBError.fromJson(wsres.data), url: wsres.url });
			case RESPONSE_STATUS.BAD:
				return this.errorHandler$.next({ type: MESSAGE_TYPE.ERROR_WEB_REQUEST, item: wsres.data, url: wsres.url });
			case RESPONSE_STATUS.LOGIN:
				return this.errorHandler$.next({ type: MESSAGE_TYPE.ERROR_LOGIN, item: response, url: wsres.url });
			case RESPONSE_STATUS.NOT_FOUND:
				return this.errorHandler$.next({ type: MESSAGE_TYPE.ERROR_NOT_FOUND, item: wsres.data, url: wsres.url });
			case RESPONSE_STATUS.FILE_TO_LARGE:
				return this.errorHandler$.next({ type: MESSAGE_TYPE.ERROR_FILE_TOO_LARGE, item: wsres.data, url: wsres.url });
			case RESPONSE_STATUS.UNKNOWN:
				return this.errorHandler$.next({ type: MESSAGE_TYPE.ERROR_UNKNOW, item: wsres.data, url: wsres.url });
			case RESPONSE_STATUS.CONN:
				if (!response.error?.match || !response.error?.match(/cannot access the file/))
					// ignore if log access error
					return this.errorHandler$.next({ type: MESSAGE_TYPE.ERROR_CONNECTION, item: wsres.data, url: wsres.url });
				else {
					console.warn("Backend threw error accessing the file " + response.error.substring(response.error.match(/file/)!.index! + 6, response.error.match(/because/)!.index! - 2));
					if (!response.error.match(/file/)?.index || !response.error.match(/because/)?.index || !response.error.match(/because/)?.index < !response.error.match(/file/)?.index) return;
				}
				return;
		}
	};

	private readonly requestSent: Function = () => {
		this.requestsPending++;
	};

	private readonly makeQueryParamsString: Function = (queryParams: any) => {
		if (typeof queryParams !== "object" || !queryParams) return "";
		var queryString = "?";
		for (var key in queryParams) {
			if (queryParams.hasOwnProperty(key)) {
				queryString += key + "=" + queryParams[key] + "&";
			}
		}
		return queryString.substring(0, queryString.length - 1);
	};

	private readonly responseReceived: Function = () => {
		this.requestsPending--;
		var elem = document.getElementById("loading_spinner");
		if (elem) {
			if (this.requestsPending > 0) elem.setAttribute("style", "display:block;");
			else elem.setAttribute("style", "display:none;");
		}
	};

	private readonly verboseManageWSResponse = (response: any): void => {
		this.responseReceived(response);
		return this.manageWSResponse(response);
	};
}
