<div class="map-label-container area-label-container">
	<div class="map-label">
		<div *ngIf="shouldShowName()" class="name">
			{{ arrow.name }}
		</div>
		<div [ngClass]="{'shadowed': shouldShowName(), 'name': !shouldShowName()}">
			{{ getStatusText() }}
		</div>
	</div>
</div>
