import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import MapView from "@arcgis/core/views/MapView";
import { WGSPoint } from "src/app/dto/map/location";
import Graphic from "@arcgis/core/Graphic";
import Circle from "@arcgis/core/geometry/Circle";
import Polyline from "@arcgis/core/geometry/Polyline";
import { MapItem } from "src/app/dto/items/map-item";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

@Component({
	selector: "app-esri-shape",
	templateUrl: "esri-shape.html"
})
export class EsriShapeComponent implements OnInit, OnDestroy {
	@Input() view!: MapView;
	@Input() shape!: EsriShape;
	@Input() mapItem: MapItem | undefined;
	@Input() attributes: any;
	@Input() layer: GraphicsLayer | undefined;

	private graphic: Graphic | undefined;

	ngOnInit(): void {
		if (!this.shape) return;
		if (this.shape.type === "circle") {
			if (!this.shape.center) {
				throw "EsriShapeComponent called with a circle without radius or center";
			}
		}
		if (this.shape.type === "polygon" || this.shape.type === "arrow") {
			if (!this.shape.vertices) {
				throw "EsriShapeComponent called with a polygon or arrow without valid vertices array";
			}
		}
		const graphic = this.getGraphicFromShape(this.shape);
		this.graphic = graphic;
		if (this.mapItem) this.mapItem.__shape = this;
		if (this.graphic) {
			//	this.view.graphics.add(this.graphic);
			this.layer
				? this.layer.graphics.splice(0, 0, this.graphic)
				: this.view.graphics.splice(0, 0, this.graphic); // set zindex of shapes to always be below markers, might want to add an input for this
		}
	}

	ngOnDestroy(): void {
		if (this.graphic)
			this.layer ? this.layer.remove(this.graphic) : this.view.graphics.remove(this.graphic);
	}

	public setGraphic(shape?: EsriShape | Graphic | undefined): void {
		if (!this.graphic) return;

		if (shape instanceof Graphic) this.graphic.symbol = shape.symbol;
		else if (shape) {
			this.graphic.symbol = shape
				? this.getSymbolFromShape(shape)
				: this.getSymbolFromShape(this.shape);
			const points = (shape as EsriShape).vertices!.map((vertice) => [
				vertice.longitude,
				vertice.latitude
			]);
			if (shape.closed)
				points.push([shape.vertices![0].longitude, shape.vertices![0].latitude]);
			const poly = new Polyline({
				paths: [points]
			});
			this.graphic.geometry = poly;
		}
		this.layer
			? this.layer.graphics.remove(this.graphic)
			: this.view.graphics.remove(this.graphic);
		this.layer
			? this.layer.graphics.splice(0, 0, this.graphic)
			: this.view.graphics.splice(0, 0, this.graphic);
	}
	public getGraphic(): Graphic | undefined {
		return this.graphic;
	}

	private readonly getGraphicFromShape: (shape: EsriShape) => any = (shape) => {
		let ans;
		if (shape.type === "circle") {
			const circle = new Circle({
				spatialReference: this.view.spatialReference,
				center: { latitude: shape.center!.latitude, longitude: shape.center!.longitude },
				radius: shape.radius,
				geodesic: true
			});
			const symbolProps = {
				type: "simple-fill",
				style: shape.fillStyle ? shape.fillStyle : "none",
				color: shape.color,
				outline: {
					width: shape.width ? shape.width : "1px",
					color: shape.color
				}
			} as __esri.SymbolProperties;
			ans = new Graphic({
				geometry: circle,
				symbol: symbolProps,
				attributes: this.attributes
			});
		}
		if (shape.type === "polygon") {
			const vertices = shape.vertices || [];
			const points = vertices.map((vertice) => [vertice.longitude, vertice.latitude]);
			if (shape.closed)
				points.push([shape.vertices![0].longitude, shape.vertices![0].latitude]);
			const poly = new Polyline({
				paths: [points]
			});
			const symbolProps = {
				type: "simple-fill",
				style: shape.fillStyle ? shape.fillStyle : "none",
				color: shape.color,
				outline: {
					width: shape.width ? shape.width : "1px",
					color: shape.color
				}
			};
			ans = new Graphic({
				geometry: poly,
				symbol: symbolProps,
				attributes: this.attributes
			});
		}
		if (shape.type === "arrow") {
			const vertices = shape.vertices || [];
			const points = vertices.map((vertice) => [vertice.longitude, vertice.latitude]);
			if (shape.closed)
				points.push([shape.vertices![0].longitude, shape.vertices![0].latitude]);
			const poly = new Polyline({
				paths: [points]
			});
			const symbolProps = {
				type: "simple-line",
				style: shape.style ? shape.style : "solid",
				color: shape.color,
				width: shape.width ? shape.width : "1px",
				outline: {
					width: shape.width ? shape.width : "1px",
					color: shape.color
				}
			};
			ans = new Graphic({
				geometry: poly,
				symbol: symbolProps,
				attributes: this.attributes
			});
		}
		return ans;
	};

	private readonly getSymbolFromShape: (shape: EsriShape) => any = (shape) => {
		return {
			type:
				shape.type == "circle" || shape.type === "polygon" ? "simple-fill" : "simple-line",
			style:
				shape.type == "circle" || shape.type === "polygon"
					? shape.fillStyle ?? "none"
					: shape.style ?? "solid",
			color: shape.color,
			width: shape.width ? shape.width : "1px",
			outline: {
				width: shape.width ? shape.width : "1px",
				color: shape.color
			}
		};
	};
}

export interface EsriShape {
	type: "circle" | "polygon" | "arrow";
	vertices: Array<WGSPoint> | undefined;
	center: WGSPoint | undefined;
	radius: number | undefined;
	closed: boolean | undefined;
	color: string | [number, number, number, number];
	opacity?: number;
	style?: "dash" | "solid" | "dot" | "long-dash";
	width: string | undefined;
	fillStyle?: ESRI_SHAPE_STYLES | ESRI_LINE_STYLES;
}

export enum ESRI_SHAPE_STYLES {
	BACKWARD_DIAGONAL = "backward-diagonal",
	CROSS = "cross",
	DIAGONAL_CROSS = "diagonal-cross",
	FORWARD_DIAGONAL = "forward-diagonal",
	HORIZONTAL = "horizontal",
	NONE = "none",
	SOLID = "solid",
	VERTICAL = "vertical"
}

export enum ESRI_LINE_STYLES {
	DASH = "dash",
	SOLID = "solid",
	DOTTED = "dotted",
	NONE = "none"
}
