import { Injectable } from "@angular/core";
import { Configuration } from "src/app/dto/user/configuration";
import { Level } from "src/app/dto/user/level";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { ResourceType } from "src/app/dto/items/types/resource-type";
import { AreaType } from "src/app/dto/items/types/area-type";
import { DTOArray } from "src/app/dto/net/dto-array";
import { IncidentType } from "src/app/dto/items/types/event-type";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { LANGUAGE } from "src/app/global/constants/enums/languages";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class ConfigurationService {
	public readonly areaPatterns = ["none", "line", "mesh"];

	public readonly resourceTypeCreated$ = new Subject<ResourceType>();
	public readonly resourceTypeDeleted$ = new Subject<ResourceType>();

	public readonly poiTypeCreated$ = new Subject<PoiType>();
	public readonly poiTypeDeleted$ = new Subject<PoiType>();

	public readonly configurationLoaded$ = new Subject<Configuration>();
	public configuration: Configuration;

	private readonly mssg: MessagingService;
	private readonly wreq: WebRequestFactory;
	private currentUserLevel: Level = Level.nullLevel();

	/**
	 * Configuration object. Contains POI and Area types, webservice endpoints' URLs, translated texts, available languages,etc.
	 * @property configuration
	 * @type Object
	 */

	constructor(mssg: MessagingService, wreq: WebRequestFactory) {
		this.mssg = mssg;
		this.wreq = wreq;
		this.configuration = Configuration.voidConfiguration();
	}

	public readonly setUserLevel: Function = (userLevel: Level) => {
		this.currentUserLevel = userLevel;
		return this.currentUserLevel;
	};

	/**
	 * Loads configuration when user is successfully logged in.
	 * Requests configuration file from server, and then sets the configuration object. Once finished,
	 * fires event "configurationLoaded" through the {{#crossLink "Main.messagingService"}}{{/crossLink}}
	 * to all listeners.
	 *
	 * @method loadConfiguration
	 */
	public readonly load: Function = async () => {
		const confJson = await this.wreq.getConfiguration();
		if (!confJson) return false;
		this.configuration = Configuration.fromJson(confJson);
		this.mssg.fire(MESSAGE_TYPE.LOAD_CONFIGURATION);
		this.configurationLoaded$.next(this.configuration);
		return true;
	};

	public readonly unload: Function = () => {
		this.configuration?.voidConfiguration();
	};
	/**
	 * Returns milliseconds for standart time in UTC time
	 *
	 * @method getTime
	 * @return {Int} Milliseconds
	 */
	public readonly getTime: Function = () => {
		return Date.now();
	};
	/**
	 * Returns milliseconds for standard time in UTC
	 *
	 * @method getTime
	 * @param {String} timeStr Standard time string to construct from, UTC time YYYY-mm-ddTH:i:s.ms[Z]
	 * @return {Int} Milliseconds
	 */
	public readonly getTimeFromString: Function = (timeStr: string) => {
		return new Date(timeStr.replace("Z", "") + "Z").getTime();
	};
	/**
	 * Returns ISO string in UTC time
	 *
	 * @method getTime
	 * @param {Int} ms Milliseconds since 1 January 1970 00:00:00 UTC.
	 * @return {String} ISO string representation
	 */
	public readonly getIsoDateFromMilliseconds: Function = (ms: number) => {
		return new Date(ms).toISOString();
	};
	public readonly getLocalDate: Function = (ms: number) => {
		return new Date(ms).toLocaleString();
	};
	/**
	 * Sends an AreaType object to the server for saving.
	 *
	 * @method saveAreaType
	 * @param {AreaType} areaType
	 * @return {Boolean} True if it was properly saved
	 */
	public readonly saveAreaType: Function = async (areaType: AreaType) => {
		const savedType: AreaType = await this.wreq.saveAreaType(areaType);
		if (!savedType) return false;
		DTOArray.AddFromJson(this.configuration.areaTypes, savedType, AreaType, null);
		return true;
	};
	/**
	 * Sends an ResourceType object to the server for saving.
	 *
	 * @method saveResourceType
	 * @return {Boolean} True if it was properly saved
	 */
	public readonly saveResourceType: Function = async (agentType: ResourceType) => {
		const savedType: string = await this.wreq.saveResourceType(agentType);
		if (!savedType) return false;
		DTOArray.AddFromJson(this.configuration.resourceTypes, savedType, ResourceType, null);
		const typ = ResourceType.fromJson(savedType);
		CloneFactory.cloneProperties(agentType, typ);
		this.resourceTypeCreated$.next(typ);
		return typ;
	};
	/**
	 * Sends an PoiType object to the server for saving.
	 *
	 * @method savePoiType
	 * @return {Boolean} True if it was properly saved
	 */
	public readonly savePoiType: Function = async (poiType: PoiType) => {
		const savedType: string = await this.wreq.savePoiType(poiType);
		if (!savedType) return false;
		DTOArray.AddFromJson(this.configuration.poiTypes, savedType, PoiType, null);
		const pt = PoiType.fromJson(savedType);
		this.poiTypeCreated$.next(pt);
		return pt;
	};

	/**
	 * Sends an EventType object to the server for saving.
	 *
	 * @method saveEventType
	 * @return {Boolean} True if it was properly saved
	 */
	public readonly saveEventType: Function = async (eventType: IncidentType) => {
		const savedType: IncidentType = await this.wreq.saveEventType(eventType);
		if (!savedType) return false;
		DTOArray.AddFromJson(this.configuration.incidentTypes, savedType, IncidentType, null);
		return true;
	};
	/**
	 * Sends a request to delete an Event Type
	 *
	 * @method deleteEventType
	 * @return {Boolean} True if it was properly deleted
	 */
	public readonly deleteEventType: Function = async (eventType: IncidentType) => {
		return await this.wreq.deleteEventType(eventType.id);
	};
	/**
	 * Sends a request to delete a Area Type
	 *
	 * @method deleteAreaType
	 * @return {Boolean} True if it was properly deleted
	 */
	public readonly deleteAreaType: Function = async (areaType: AreaType) => {
		return await this.wreq.deleteAreaType(areaType.id);
	};
	/**
	 * Sends a request to delete a Agent Type
	 *
	 * @method deleteResourceType
	 * @return {Boolean} True if it was properly deleted
	 */
	public readonly deleteResourceType: Function = async (agentType: ResourceType) => {
		const success: any = await this.wreq.deleteResourceType(agentType.id);
		if (success) {
			DTOArray.DeleteById(this.configuration.resourceTypes, agentType.id);
			this.resourceTypeDeleted$.next(agentType);
			return true;
		}
		return false;
	};
	/**
	 * Sends a request to delete a Poi Type
	 *
	 * @method deletePoiType
	 * @return {Boolean} True if it was properly deleted
	 */
	public readonly deletePoiType: Function = async (poiType: PoiType) => {
		this.poiTypeDeleted$.next(poiType);
		return await this.wreq.deletePoiType(poiType.id);
	};

	public readonly setCurrentmissionId: Function = (id_mission: number) => {
		if (!id_mission) id_mission = -1;
		if (this.configuration.id_current_incident != id_mission) {
			this.configuration.id_current_incident = id_mission;
			return this.saveConfiguration();
		} else {
			return false;
		}
	};
	public readonly setCustomHomepage: Function = (homepage: "/home" | "/mission/dashboard4") => {
		this.configuration.custom_homepage = homepage === "/home" ? SCREEN.HOME : SCREEN.INCIDENT;
		return this.saveConfiguration();
	};
	public readonly setLanguage: Function = (language: LANGUAGE) => {
		this.configuration.language = language;
		return this.saveConfiguration();
	};
	public readonly saveConfiguration: Function = () => {
		return this.wreq.saveConfiguration(this.configuration); //TODO: manage configuration return
	};

	// hash algorithm taken from Java native String.hashCode : https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
	public readonly hash: Function = (object: Object, attrExceptions: Array<string>) => {
		var string = JSON.stringify(object, (key, val) => {
			if (!attrExceptions || !attrExceptions.length) return val;
			for (var i = 0; i < attrExceptions.length; i++) {
				if (key === attrExceptions[i]) return undefined;
			}
			return val;
		});
		var hash = 0,
			i,
			chr;
		if (string.length === 0) return hash;
		for (i = 0; i < string.length; i++) {
			chr = string.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	};
}
