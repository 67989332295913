import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { WGSPoint } from "src/app/dto/map/location";
import { Change } from "src/app/dto/net/change";
import { BufferItem } from "src/app/dto/replay/buffer-item";
import { ReplayBuffer } from "src/app/dto/replay/replay-buffer";
import { Resource } from "src/app/dto/resources/resource";
import { MAP_ITEM_TYPE } from "../../constants/enums/map-item-type";

@Injectable({
	providedIn: "root"
})
export class BufferProvider {
	public changes = new Array<Change>();
	public buffer = new ReplayBuffer(-1);
	public $filled = new Subject<void>();

	public timestamp: number = 0;

	public filled = false;
	public buffered: boolean = true;

	
	public insertResourcePosition(resource : Resource): void {
		this.buffer.agents.push(new BufferItem(resource.getJson(), MAP_ITEM_TYPE.RESOURCE, undefined, resource));
	}
}
