<div class="document-frame">
	<div #logTemplates>
		<app-decision-log-template
			*ngIf="isDocumentBeingGenerated(documentTypes.DECISION_LOG)"
			[generationTime]="generationTime"
			[event]="currentEvent"
			[decisions]="getDocumentData(documentTypes.DECISION_LOG)[0].decisions"
			(docReady)="docReady(documentTypes.DECISION_LOG, $event)"
		>
		</app-decision-log-template>
	</div>

	<div #araTemplates>
		<div *ngIf="isDocumentBeingGenerated(documentTypes.ARA)">
			<app-ara-template
				*ngFor="let data of getDocumentData(documentTypes.ARA); let i = index"
				[event]="currentEvent"
				[sector]="data.sectorWithARAs!"
				(docReady)="docReady(documentTypes.ARA, $event, i)"
			>
			</app-ara-template>
		</div>
	</div>

	<div #fsgTemplates>
		<app-fsg-blank-template
			*ngIf="isDocumentBeingGenerated(documentTypes.FSG_BLANK)"
			[event]="currentEvent"
			[fsg]="getDocumentData(documentTypes.FSG_BLANK)[0].fsg!"
			(docReady)="docReady(documentTypes.FSG_BLANK, $event)"
		>
		</app-fsg-blank-template>

		<div *ngIf="isDocumentBeingGenerated(documentTypes.FSG_HISTORY)">
			<app-fsg-history-template
				*ngFor="let data of getDocumentData(documentTypes.FSG_HISTORY); let i = index"
				[event]="currentEvent"
				[fsg]="data.fsg!"
				(docReady)="docReady(documentTypes.FSG_HISTORY, $event, i)"
			>
			</app-fsg-history-template>
		</div>
	</div>

	<div #jesipTemplates>
		<div #methaneTemplates>
			<app-methane-blank-template
				*ngIf="isDocumentBeingGenerated(documentTypes.METHANE_BLANK)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				(docReady)="docReady(documentTypes.METHANE_BLANK, $event)"
			>
			</app-methane-blank-template>

			<app-methane-current-template
				*ngIf="isDocumentBeingGenerated(documentTypes.METHANE_CURRENT)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				[methane]="getDocumentData(documentTypes.METHANE_CURRENT)[0].jesipMethane!"
				(docReady)="docReady(documentTypes.METHANE_CURRENT, $event)"
			>
			</app-methane-current-template>

			<div *ngIf="isDocumentBeingGenerated(documentTypes.METHANE_HISTORY)">
				<app-methane-current-template
					*ngFor="
						let data of getDocumentData(documentTypes.METHANE_HISTORY);
						let i = index
					"
					[generationTime]="generationTime"
					[event]="currentEvent"
					[methane]="data.jesipMethane!"
					(docReady)="docReady(documentTypes.METHANE_HISTORY, $event, i)"
				>
				</app-methane-current-template>
			</div>
		</div>

		<div #iimarchTemplates>
			<app-iimarch-blank-template
				*ngIf="isDocumentBeingGenerated(documentTypes.IIMARCH_BLANK)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				(docReady)="docReady(documentTypes.IIMARCH_BLANK, $event)"
			></app-iimarch-blank-template>

			<app-iimarch-current-template
				*ngIf="isDocumentBeingGenerated(documentTypes.IIMARCH_CURRENT)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				[iimarch]="getDocumentData(documentTypes.IIMARCH_CURRENT)[0].jesipIimarch!"
				(docReady)="docReady(documentTypes.IIMARCH_CURRENT, $event)"
			></app-iimarch-current-template>

			<div *ngIf="isDocumentBeingGenerated(documentTypes.IIMARCH_HISTORY)">
				<app-iimarch-current-template
					*ngFor="
						let data of getDocumentData(documentTypes.IIMARCH_HISTORY);
						let i = index
					"
					[generationTime]="generationTime"
					[event]="currentEvent"
					[iimarch]="data.jesipIimarch!"
					(docReady)="docReady(documentTypes.IIMARCH_HISTORY, $event, i)"
				>
				</app-iimarch-current-template>
			</div>
		</div>

		<div #jdmTemplates>
			<app-jdm-blank-template
				*ngIf="isDocumentBeingGenerated(documentTypes.JDM_BLANK)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				(docReady)="docReady(documentTypes.JDM_BLANK, $event)"
			></app-jdm-blank-template>

			<app-jdm-current-template
				*ngIf="isDocumentBeingGenerated(documentTypes.JDM_CURRENT)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				[jdm]="getDocumentData(documentTypes.JDM_CURRENT)[0].jesipJdm!"
				(docReady)="docReady(documentTypes.JDM_CURRENT, $event)"
			></app-jdm-current-template>

			<div *ngIf="isDocumentBeingGenerated(documentTypes.JDM_HISTORY)">
				<app-jdm-current-template
					*ngFor="let data of getDocumentData(documentTypes.JDM_HISTORY); let i = index"
					[generationTime]="generationTime"
					[event]="currentEvent"
					[jdm]="data.jesipJdm!"
					(docReady)="docReady(documentTypes.JDM_HISTORY, $event, i)"
				>
				</app-jdm-current-template>
			</div>
		</div>

		<div #debriefTemplates>
			<app-debrief-blank-template
				*ngIf="isDocumentBeingGenerated(documentTypes.DEBRIEF_BLANK)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				(docReady)="docReady(documentTypes.DEBRIEF_BLANK, $event)"
			>
			</app-debrief-blank-template>

			<app-debrief-current-template
				*ngIf="isDocumentBeingGenerated(documentTypes.DEBRIEF_LAST)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				(docReady)="docReady(documentTypes.DEBRIEF_LAST, $event)"
			>
			</app-debrief-current-template>
		</div>

		<div #checklistTemplates>
			<app-checklist-blank-template
				*ngIf="isDocumentBeingGenerated(documentTypes.CHECKLIST_BLANK)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				(docReady)="docReady(documentTypes.CHECKLIST_BLANK, $event)"
			>
			</app-checklist-blank-template>

			<app-checklist-current-template
				*ngIf="isDocumentBeingGenerated(documentTypes.CHECKLIST_LAST)"
				[generationTime]="generationTime"
				[event]="currentEvent"
				[history]="getDocumentData(documentTypes.CHECKLIST_LAST)[0].checklistHistory!"
				(docReady)="docReady(documentTypes.CHECKLIST_LAST, $event)"
			>
			</app-checklist-current-template>
		</div>

		<div #commandStructureTemplate>
			<app-cs-template
				*ngIf="isDocumentBeingGenerated(documentTypes.COMMAND_STRUCTURE)"
				[cs]="getDocumentData(documentTypes.COMMAND_STRUCTURE)[0].commandStructure!"
				[generationTime]="generationTime"
				[event]="currentEvent"
				(docReady)="docReady(documentTypes.COMMAND_STRUCTURE, $event)"
			>
			</app-cs-template>
		</div>
	</div>
</div>
<app-loading-dialog
	*ngIf="dialogs.loading"
	[headerText]="text().DOWNLOADING_FILES"
	[bodyText]="dialogs.loading"
	(closeCallback)="closeModalCallback($event)"
>
</app-loading-dialog>
<app-task-completed-dialog
	*ngIf="dialogs.taskCompleted"
	[headerText]="text().DOWNLOADED_FILES"
	[bodyText]="text().COMPLETED"
	(closeCallback)="dialogs.taskCompleted = false"
></app-task-completed-dialog>
<app-error-dialog
	*ngIf="dialogs.unavailable"
	[header]="ErrorDialogComponentParams.header"
	[body]="ErrorDialogComponentParams.body"
	(closeCallback)="ErrorDialogComponentParams.closeCallBack()"
></app-error-dialog>
