export enum PREFERRED_COLORS {
	RED = "#CC3333",
	YELLOW = "#FCD021",
	GREEN = "#2FC555",
	BLUE = "#2584E9",
	PURPLE = "#7244DE",
	WHITE = "#FFFFFF",
	GREY = "#878787",
	BLACK = "#000000"
}
