// Search webservice with keyword DB_CONNECT_ERROR to find more error codes or strings
export enum DB_ERRORS {
	agent_not_found,
	appliance_not_found,
	agenttype_fk_undeletable,
	ara_exists,
	areatype_fk_undeletable,
	camera_in_use,
	cant_delete_user,
	fk_undeletable,
	id_not_found,
	ims_name_in_use,
	incident_closed,
	mission_already_exists,
	mission_not_found,
	name_in_use,
	name_not_found,
	permission_denied,
	poitype_fk_undeletable,
	query_error,
	user_is_personnel,
	insecure_password
}
