<div
	class="container"
	appDetectClickOut
	[onlyOnce]="true"
	(callback)="close()"
	(click)="$event.stopPropagation()"
>
	<div class="preferred-colors-container" *ngIf="showPreferred">
		<div
			data-cy="preferred-color"
			*ngFor="let preferredColor of preferredColours"
			[ngStyle]="{ 'background-color': preferredColor }"
			[ngClass]="
				preferredColor === rgba2hex(color).toUpperCase()
					? 'preferred-color-selected'
					: 'preferred-color'
			"
			(click)="selectPreferredColor(preferredColor)"
		>
			<div
				*ngIf="preferredColor === rgba2hex(color).toUpperCase()"
				class="preferred-color-selected-tick"
			>
				<img src="resources/img/check.svg" />
			</div>
		</div>
	</div>
	<div class="separator" *ngIf="showPreferred"></div>
	<div class="color-picker-container">
		<div class="custom-color" (click)="showColorPicker = !showColorPicker">
			<div>{{ text().CUSTOM_COLOR }}</div>

			<div class="arrow-button" *ngIf="showPreferred">
				<img src="resources/img/Chevron_{{ getArrowDirection() }}.svg" />
			</div>
		</div>

		<div class="color-picker" *ngIf="showColorPicker">
			<div class="color-slider-container">
				<app-color-palette
					[updateCb]="update"
					[color]="color"
					[hue]="hue"
					[changeOrigin]="changeOrigin"
					(selectedColor)="changeColor($event)"
					(changeOriginOut)="changeOriginFunction($event)"
				></app-color-palette>

				<app-color-slider
					[hue]="hue"
					(selectedHue)="hue = $event"
					(changeOriginOut)="changeOriginFunction($event)"
				></app-color-slider>
			</div>
		</div>
	</div>
</div>
