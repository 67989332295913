import axios from "axios";
import settings from "../../../iris.settings.json"
import { Subject } from "rxjs";

export abstract class SettingsParser {

    public static default = settings


    public static settings: any;
    public static template: any;

    
	public static settingsLoaded$ = new Subject<void>();

     // set to true for tests, so no request is triggered as settings files will be unaccessible but will still be demanded for HTTP requests
    public static TESTING = true;



    static async loadSettings(): Promise<void> {
        try{
            const settings = await axios.get("./iris.settings.json");
            if(settings.data) this.settings = settings.data;
            this.settingsLoaded$.next();
        }
        catch(e){
            console.error("Error when loading settings file.");
            console.error(e);
        }
    }
    
    static async loadTemplate(): Promise<void> {
        try{
            const template = await axios.get("./iris.settings.template.json");
            if(template.data) this.template = template.data; 
        }
        catch(e){
            console.error("Error when loading template file.");
            console.error(e);
        }
    }

    static getSettings(): any {
        return !this.TESTING && this.settings ? this.settings : this.default;
    }

    static getTemplate(): any {
        return !this.TESTING && this.template ? this.template : this.default;
    }
}