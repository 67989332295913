import { Component, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { Poi } from "src/app/dto/items/poi";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { MapItemsService } from "src/app/incident/map/map-items.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MainService } from "src/app/global/main.service";
import { EsriService } from "src/app/incident/map/components/map/esri/esri.service";
import { DownloadDialogs } from "src/app/dto/Documents/document";
import { Attachable } from "src/app/dto/items/attachable";
import { MapItem, Named } from "src/app/dto/items/map-item";
import { Subject } from "rxjs";
import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { Arrow } from "src/app/dto/items/arrow/arrow";

@Component({
	selector: "app-attachable-edit-modal",
	templateUrl: "attachable-edit.component.html",
	styleUrls: ["../map-item-edit.css"]
})
export class AttachableItemEditModalComponent implements OnInit {
	@Input() item!: MapItem & Attachable & Named;

	@Output() saveCb = new Subject<Attachable & Named>();
	@Output() cancelCb = new EventEmitter<void>();

	public itemType: MAP_ITEM_TYPE = MAP_ITEM_TYPE.POI;

	public readonly text: () => LocaleMap;
	public header!: string;
	public loading: string = "";
	public dialogs: DownloadDialogs = {
		loading: "",
		taskCompleted: false,
		unavailable: false
	};

	public justUploaded = new Map<number, boolean>();

	private editedName = "";
	private editedComments = "";
	constructor(
		textProv: TextProvider,
		private readonly wreq: WebRequestFactory,
		private readonly mis: MapItemsService,
		private readonly mssg: MessagingService,
		private readonly main: MainService,
		private readonly esriServ: EsriService,
		private readonly zone: NgZone
	) {
		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		if (!this.item) throw 'AttachableEditModal initialized without "item" input';
		this.header = this.item.name;
		if (this.item instanceof Poi) {
			this.esriServ.selectedPoi = this.item;

			this.mssg.registerListener(MESSAGE_TYPE.UPDATE_POI, (poi: Poi) => {
				if ((this.item as Poi).id === poi.id) {
					let finalName =
						this.editedName && this.editedName.length > 0 ? this.editedName : poi.name;
					let finalComments =
						this.editedComments && this.editedComments.length > 0
							? this.editedComments
							: poi.description;
					this.item = poi;
					this.item.name = finalName;
					this.item.description = finalComments;
					this.esriServ.selectedPoi = this.item as Poi;
				}
			});
		}

		if (this.item instanceof Poi) {
			this.itemType = MAP_ITEM_TYPE.POI;
		}
		if (this.item instanceof Arrow) {
			this.itemType = MAP_ITEM_TYPE.ARROW;
		}
	}

	public readonly onFileUploaded = (evt: UploadedFile[]): void => {
		if (evt && evt.length) {
			for (let i = 0; i < evt.length; i++) {
				this.item.attachmentList.splice(0, 0, evt[i]);
				this.justUploaded.set(evt[i].id, true);
			}
		}
		this.loading = "";
	};

	public readonly shortenName: (name: string) => string = (name) => {
		return name.length > 20 ? name.substr(0, 12) + "..." + name.substr(name.length - 7) : name;
	};

	public readonly refreshName: (value: string) => void = (value) => {
		this.editedName = this.item.name = value;
	};

	public readonly refreshComments: (value: string) => void = (value) => {
		this.editedComments = this.item.description = value;
	};

	public readonly deattach: (id_file: number, evt?: Event) => Promise<void> = async (
		id_file,
		evt
	) => {
		if (evt) evt.stopPropagation();
		if (this.item instanceof Poi) {
			const ans: boolean = await this.mis.deattachPoi(this.item.id, id_file);
			if (ans) {
				let idx = this.item.attachmentList.findIndex((e) => e.id === id_file);
				if (idx > -1) this.item.attachmentList.splice(idx, 1);
				this.justUploaded.set(id_file, false);
			}
		}
		if (this.item instanceof Arrow) {
			const ans: boolean = await this.mis.deattachArrow(this.item.id, id_file);
			if (ans) {
				let idx = this.item.attachmentList.findIndex((e) => e.id === id_file);
				if (idx > -1) this.item.attachmentList.splice(idx, 1);
				this.justUploaded.set(id_file, false);
			}
		}
	};

	public readonly openFile: (file: UploadedFile) => void = async (file) => {
		this.justUploaded.set(file.id, false);
		file.__unseen = false;
		const blob = await this.wreq.getFile(file.id);
		if (!blob) return;

		const urlCreator = window.URL || window.webkitURL;
		if (blob.type === "video/quicktime") {
			this.showPlaybackInformationMessage();
		}
		window.open(urlCreator.createObjectURL(blob));
	};

	public readonly save: (emit: boolean) => void = async (emit) => {
		this.saveCb.next(this.item);
	};

	public readonly cancel: Function = () => {
		this.cancelCb.emit();
	};

	public readonly fileType = (file: string): "image" | "video" | "pdf" | "" => {
		if (file.match(/.png|.jpg|.jpeg/i)) return "image";
		if (file.match(/.pdf/i)) return "pdf";
		if (file.match(/.mov|.mp4/i)) return "video";
		return "";
	};

	public readonly isloading = (filename: string): string => {
		return (this.loading = filename);
	};

	private showPlaybackInformationMessage(): void {
		this.dialogs.loading = this.text().DOWNLOAD_PROCESS_TEXT;
		this.zone.run(() => {
			setTimeout(() => {
				this.dialogs.loading = "";
				this.dialogs.taskCompleted = true;
			}, 1000);
		});
	}
}
