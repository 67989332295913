import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { WebRequestsService } from "src/app/http/web-request.service";
import { UserService } from "src/app/settings/user/user.service";
import { Decision } from "src/app/dto/decision/decision";
import { DecisionFile } from "src/app/dto/decision/decision-file";
import { User } from "src/app/dto/user/user";
import {
	STATE_OPTION,
	TACTICAL_OPTION
} from "src/app/incident/incident-tools/forms/decision-log/components/new/new-decision.component";
import { URLMap } from "src/app/global/constants/enums/url-map";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { IncidentService } from "src/app/incident/incident.service";
import { MenuService } from "../../../../navigation/components/left-nav-menu/menu.service";
import { FunctionalityService } from "src/app/global/functionality.service";
import { FUNCTIONALITY } from "src/app/global/functionality.service";
import { DownloadDialogs } from "src/app/dto/Documents/document";

@Component({
	selector: "app-decision-list",
	templateUrl: "decision-list.component.html",
	styleUrls: ["decision-list.css"]
})
export class DecisionListComponent implements OnInit, OnChanges {
	@Input() items: Array<Decision> = [];
	@Input() searchText: string = "";

	@ViewChild("bar") playerBar!: ElementRef;
	@ViewChild("ball") playerBall!: ElementRef;

	public readonly text: () => LocaleMap;

	public filters: Array<string> = ["Event", "Decision", "Message", "Automatic"];
	public selectedFilters: Array<string> = [];
	public showFilter = false;
	public expand: Array<boolean> = [];
	public shortContent: Record<string, boolean> = {};
	public dlSignature: boolean = true;
	public dlType: boolean = true;
	public dlOperational: boolean = true;
	public isFiltered: boolean = false;

	public inspectedImage = -1;
	public imageArrays: Array<Array<{ image?: string; filename?: string; idfile?: number }>> = [];

	public currentOrder: SORT_ORDER = SORT_ORDER.DESCENDENT;
	public currentSort: SORT_OPTION = SORT_OPTION.DATE;

	public readonly operationalOptions = TACTICAL_OPTION;
	public readonly stateOptions = STATE_OPTION;
	public readonly audioTypes = AUDIO_TYPE;

	public readonly sortOrder = SORT_ORDER;
	public readonly sortOption = SORT_OPTION;
	public filteredDecisions: Decision[] = [];

	public audioPlaying!: HTMLAudioElement;
	public audioPlayingData: DecisionAudioData | undefined;
	public playerUIRefreshInterval: any;
	public dialogs: DownloadDialogs = {
		loading: "",
		taskCompleted: false,
		unavailable: false
	};
	public expandedContent: { [key: number]: boolean } = {};
	public expandedRationale: { [key: number]: boolean } = {};

	constructor(
		tp: TextProvider,
		private readonly userService: UserService,
		private readonly wres: WebRequestsService,
		private readonly wreq: WebRequestFactory,
		private readonly ems: IncidentService,
		private readonly menuService: MenuService,
		private readonly funcService: FunctionalityService
	) {
		this.text = tp.getStringMap;
	}

	public readonly updateFilterDecisions = (filterValues: Map<string, boolean>): void => {
		this.selectedFilters = [];
		filterValues.forEach((value, key) => {
			if (value) {
				this.selectedFilters.push(key);
			}
		});
		this.isFiltered = this.selectedFilters.length > 0;
		this.filteredDecisions = this.items.filter((decision) => this.filterDecisions(decision));
	};

	public readonly filterDecisions = (item: Decision): boolean => {
		const searchTextFilter = () => {
			if (this.searchText && this.searchText.length !== 0) {
				const searchTextValue = this.searchText.toLowerCase();
				return (
					item.description.toLowerCase().includes(searchTextValue) ||
					item.result.toLowerCase().includes(searchTextValue) ||
					item.type.toLowerCase().includes(searchTextValue) ||
					item.name.toLowerCase().includes(searchTextValue) ||
					item.tactical?.toLowerCase().includes(searchTextValue) ||
					item.state?.toLowerCase().includes(searchTextValue)
				);
			}
			return true;
		};

		return searchTextFilter() && this.filterByType(item);
	};

	ngOnInit(): void {
		this.orderList(this.currentOrder, this.currentSort);
		for (let i = 0; i < this.items.length; i++) {
			this.expand[i] = false;
		}
		this.dlSignature = this.funcService.isFunctionalityAvailable.get(
			FUNCTIONALITY.DL_SIGNATURE
		)!;
		this.dlType = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.DL_TYPE)!;
		this.dlOperational = this.funcService.isFunctionalityAvailable.get(
			FUNCTIONALITY.DL_OPERATIONAL
		)!;
		this.initializeExpandStates();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.items) {
			const dlItems = changes.items.currentValue;
			if (dlItems.length === 1) {
				this.filteredDecisions = dlItems;
			}
		}

		if (changes.searchText) {
			const searchTextValue = changes.searchText.currentValue.toLowerCase();
			this.filteredDecisions = this.items.filter((decision) => {
				return (
					decision.description.toLowerCase().includes(searchTextValue) ||
					decision.result.toLowerCase().includes(searchTextValue) ||
					decision.type.toLowerCase().includes(searchTextValue) ||
					decision.name.toLowerCase().includes(searchTextValue) ||
					decision.tactical.toLowerCase().includes(searchTextValue) ||
					decision.state.toLowerCase().includes(searchTextValue)
				);
			});
		}
	}

	public getGridTemplateColumns(): string {
		let template = "";
		if (this.dlType) {
			template += "1fr "; // Add width for type column
		}
		template += "1fr 3fr 3fr 1fr 1fr"; // Add widths for remaining columns
		if (this.dlOperational) {
			template += " 1fr 1fr"; // Add widths for operational and state columns
		}
		if (this.dlSignature) {
			template += " 1fr"; // Add width for signed column
		}
		return template.trim();
	}

	public toggleContent(index: number): void {
		this.expandedContent[index] = !this.expandedContent[index];
	}

	public toggleRationale(index: number): void {
		this.expandedRationale[index] = !this.expandedRationale[index];
	}

	public isContentExpanded(index: number): boolean {
		return this.expandedContent[index] || false;
	}

	public isRationaleExpanded(index: number): boolean {
		return this.expandedRationale[index] || false;
	}

	public getDecisionFiles(i: number): DecisionFile[] | undefined {
		if (!this.items) return;
		const decisions = this.items;
		return decisions[i].files;
	}

	public readonly getDecisionDate: (decision: Decision) => string = (decision) => {
		const date = new Date(decision.timestamp_ms);
		return (
			(date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
			":" +
			(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
			" " +
			(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
			"/" +
			(date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
			"/" +
			(date.getFullYear() % 100)
		);
	};

	public readonly getLocalizedType = (type: string): string => {
		const types = type.trim().split(" ");
		const translateType = (typeToTranslate: string): string => {
			switch (typeToTranslate.toUpperCase()) {
				case "EVENT":
					return this.text().EVENT_2;
				case "DECISION":
					return this.text().DECISION;
				case "MESSAGE":
					return this.text().MESSAGE;
				case "AUTOMATED":
					return this.text().AUTOMATIC;
				default:
					return "-";
			}
		};
		return types.map(translateType).join(", ");
	};

	public shouldChangeBackground(type: string): boolean {
		const decisionType = this.getLocalizedType(type);
		const automatic = this.text().AUTOMATIC;

		if (decisionType.toUpperCase() === automatic.toUpperCase()) return true;
		return false;
	}

	public readonly getLocalizedTactics: (decision: Decision) => string = (decision) => {
		switch (decision.tactical.toUpperCase()) {
			case TACTICAL_OPTION.DEFENSIVE:
				return this.text()[this.operationalOptions.DEFENSIVE];
			case TACTICAL_OPTION.OFFENSIVE:
				return this.text()[this.operationalOptions.OFFENSIVE];
			case TACTICAL_OPTION.NONE:
				return this.text()[this.operationalOptions.NONE];
			default:
				return "-";
		}
	};

	public readonly getLocalizedState: (decision: Decision) => string = (decision) => {
		switch (decision.state.toUpperCase()) {
			case STATE_OPTION.CONTROLLED:
				return this.text()[STATE_OPTION.CONTROLLED];
			case STATE_OPTION.DEVELOPING:
				return this.text()[STATE_OPTION.DEVELOPING];
			case STATE_OPTION.ESCALATING:
				return this.text()[STATE_OPTION.ESCALATING];
			case STATE_OPTION.SCALE_DOWN:
				return this.text()[STATE_OPTION.SCALE_DOWN];
			case "SCALE DOWN":
				return this.text()[STATE_OPTION.SCALE_DOWN];
			case STATE_OPTION.INITIAL:
				return this.text()[STATE_OPTION.INITIAL];
			default:
				return "-";
		}
	};

	public readonly getSigningUser: (decision: Decision) => User | undefined = (decision) => {
		return this.userService.Users.find((e) => e.id === decision.id_fill_signature);
	};

	public openMoreMedia(decision: Decision, index: number): void {
		const imageFiles: any[] = [];
		const otherFiles: any[] = [];
		const additionalFiles = decision.files.slice(2);

		additionalFiles.forEach((file) => {
			if (file.isImage()) {
				imageFiles.push({ idfile: file.id_file });
			} else {
				otherFiles.push(file);
			}
		});

		if (imageFiles.length > 0) {
			this.imageArrays[index] = imageFiles;
			this.inspectedImage = index;
		}

		otherFiles.forEach(async (file) => {
			await this.openFile(file);
		});
	}

	public openImageInspector(file: DecisionFile, index: number): void {
		if (file.isImage()) {
			this.imageArrays[index] = [{ idfile: file.id_file }];
			this.inspectedImage = index;
		} else {
			this.openFile(file);
		}
		this.menuService.sideMenuShortZIndex.next("unset");
	}

	public readonly closeImageInspector: Function = () => {
		this.inspectedImage = -1;
		this.menuService.sideMenuShortZIndex.next("9");
	};

	public isIncidentClosed(): boolean {
		const currentIncident = this.ems.getCurrentIncident();
		return currentIncident?.closed ?? true;
	}

	public readonly orderList: (order: SORT_ORDER, option: SORT_OPTION) => void = (
		order,
		option
	) => {
		if (this.currentSort !== option) order = SORT_ORDER.ASCENDENT;
		this.currentOrder = order;
		this.currentSort = option;
		this.items = this.items.sort((a: Decision, b: Decision) => {
			if (typeof a[option] !== "number" && typeof b[option] !== "number") {
				let aOption = a[option] as string;
				let bOption = b[option] as string;
				if (!aOption) aOption = "";
				if (!bOption) bOption = "";
				if (aOption.toLowerCase() > bOption.toLowerCase()!)
					return order === SORT_ORDER.ASCENDENT ? 1 : -1;
				else return order === SORT_ORDER.ASCENDENT ? -1 : 1;
			} else {
				if (a[option]! > b[option]!) return order === SORT_ORDER.ASCENDENT ? 1 : -1;
				else return order === SORT_ORDER.ASCENDENT ? -1 : 1;
			}
		});
	};

	public async playAudio(decision: Decision, type: AUDIO_TYPE): Promise<void> {
		if (this.audioPlaying) {
			this.audioPlaying.pause();
			if (this.audioPlaying.onended) {
				this.audioPlaying.onended(new Event("click"));
			}
		}

		let url: string | null = null;
		switch (type) {
			case AUDIO_TYPE.CONTENT:
				url = URLMap.GET.FILE + "/" + decision.content_filename;
				break;
			case AUDIO_TYPE.RESULT:
				url = URLMap.GET.FILE + "/" + decision.result_filename;
				break;
		}

		if (url) {
			try {
				const blob: Blob = await this.wres.getFile(null, URLMap.WSURL() + url, {
					responseType: "blob"
				});

				if (!blob || blob.size === 0) return;
				const urlCreator = window.URL || window.webkitURL;
				const objectURL = urlCreator.createObjectURL(blob);

				this.audioPlaying = new Audio(objectURL);
				this.audioPlayingData = new DecisionAudioData(decision.id, type);

				this.playerUIRefreshInterval = setInterval(() => {
					if (this.playerBall && this.playerBall.nativeElement) {
						this.playerBall.nativeElement.style.width =
							this.getAudioPercent() * this.playerBar.nativeElement.offsetWidth +
							"px";
					}
				}, 50);

				await this.audioPlaying.play();

				this.audioPlaying.onerror = (event) => {
					console.error("Error during playback:", event);
					urlCreator.revokeObjectURL(objectURL);
				};

				this.audioPlaying.onended = () => {
					this.audioEnd();
					clearInterval(this.playerUIRefreshInterval);
					urlCreator.revokeObjectURL(objectURL);
					this.audioPlaying.onerror = null;
				};
			} catch (e) {
				console.error("Error loading or playing audio:", e);
			}
		}
	}

	public readonly checkAudioPlaying: (decision: Decision, type: AUDIO_TYPE) => boolean = (
		decision,
		type
	) => {
		return (
			this.audioPlayingData !== undefined &&
			this.audioPlayingData.id == decision.id &&
			this.audioPlayingData.type === type
		);
	};

	public readonly getAudioPercent: () => number = () => {
		return this.audioPlaying.currentTime / this.audioPlaying.duration;
	};

	public readonly openFile = async (file: DecisionFile): Promise<void> => {
		const blob = await this.wreq.getFile(file.id_file);
		if (!blob) return;

		const urlCreator = window.URL || window.webkitURL;
		if (blob.type === "video/quicktime") {
			this.showPlaybackInformationMessage();
		}
		window.open(urlCreator.createObjectURL(blob));
	};

	public closeTypeFilter(): void {
		this.showFilter = false;
	}

	public toggleFilter(): void {
		this.showFilter = !this.showFilter;
	}

	public readonly isFilterApplied = (): string => {
		if (this.items.length === 0) {
			return "";
		} else {
			if (this.showFilter === true) {
				return "open";
			} else {
				if (this.selectedFilters.length !== 0) {
					return "open";
				} else {
					return "default";
				}
			}
		}
	};

	public isAudioOrVideo(file: DecisionFile): string {
		if (file.isVideo()) {
			return "resources/img/video-play.svg";
		} else if (file.isAudio()) {
			return "resources/img/sound-wave.svg";
		} else {
			return "resources/img/documents_generic_icon.svg";
		}
	}

	public hasAudio(decision: Decision): boolean {
		return (
			decision.content_audio !== -1 &&
			!this.checkAudioPlaying(decision, this.audioTypes.CONTENT)
		);
	}

	private filterByType(item: Decision): boolean {
		if (!this.selectedFilters || this.selectedFilters.length === 0) return true;

		return this.selectedFilters.some((filter) => {
			switch (filter) {
				case "Decision":
					return item.is_decision;
				case "Event":
					return item.is_event;
				case "Message":
					return item.is_message;
				case "Automatic":
					return !item.is_decision && !item.is_event && !item.is_message;
				default:
					return false;
			}
		});
	}

	private initializeExpandStates(): void {
		this.items.forEach((_, index) => {
			this.expandedContent[index] = false;
			this.expandedRationale[index] = false;
		});
	}
	private readonly audioEnd: Function = () => {
		this.audioPlayingData = undefined;
		clearInterval(this.playerUIRefreshInterval);
	};

	private showPlaybackInformationMessage(): void {
		this.dialogs.loading = this.text().DOWNLOAD_PROCESS_TEXT;
		setTimeout(() => {
			this.dialogs.loading = "";
			this.dialogs.taskCompleted = true;
		}, 1000);
	}
}

enum SORT_ORDER {
	ASCENDENT,
	DESCENDENT
}
enum SORT_OPTION {
	DATE = "timestamp_ms",
	TYPE = "type",
	CONTENT = "description",
	RATIONALE = "result",
	OPERATION = "tactical",
	STATE = "state",
	SIGNED = "__signName",
	LOGGED = "name"
}
enum AUDIO_TYPE {
	CONTENT = "content",
	RESULT = "result"
}
class DecisionAudioData {
	// eslint-disable-next-line max-len
	constructor(readonly id: number, readonly type: AUDIO_TYPE) {}
}
