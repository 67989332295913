import { Resource } from "./resource";
import { DTO } from "../net/dto";
import { Personnel } from "./personnel";

export class ApplianceRelation implements DTO {
	id: number;
	id_appliance: number;
	id_personnel: number;
	name_personnel: string;
	name_appliance: string;
	oic: boolean;

	id_mission?: number; // only for closed ones

	timestamp = Date.now();
	constructor(
		id: number,
		id_a: number,
		id_p: number,
		name_p?: string,
		name_a?: string,
		oic?: boolean,
		id_mission?: number
	) {
		this.id = id;
		this.id_appliance = id_a;
		this.id_personnel = id_p;
		this.name_personnel = name_p ? name_p : "";
		this.name_appliance = name_a ? name_a : "";
		this.oic = oic ? oic : false;
		this.id_mission = id_mission;
	}

	public static readonly fromJson: (json: string) => ApplianceRelation = (json: string) => {
		const jc = JSON.parse(json);
		return new ApplianceRelation(
			jc.id,
			jc.id_appliance,
			jc.id_personnel,
			jc.name_personnel,
			jc.name_appliance,
			jc.oic,
			jc.id_mission
		);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "id_appliance", "id_personnel", "oic"]);
	};
}

export interface ClosedPersonnelRelation {
	object: Personnel; // take in mind that this object will be a Resource reference with the id_mission set to the current incident of the resource, not to be confused with the actual id of the incident in which this relation makes sense,
	id_incident: number; // which is stored in this id_incident variable
}
