<div
	#blueDrop
	class="blue-dropdown {{ openMenu ? 'open' : '' }} {{
		withBorderRadius ? 'rounded-corners' : ''
	}} {{ disabled ? 'pointer-none' : '' }}"
	appDetectClickOut
	(callback)="onClickOut()"
	[style.height.px]="height"
>
	<div
		class="blue-dropdown-text {{ withBorderRadius ? 'rounded-corners' : '' }} {{
			smallFont ? 'small' : ''
		}}"
		(click)="onOpenMenu(); $event.stopPropagation()"
	>
		<input
			data-cy="dropdown-input"
			*ngIf="getObjectText(object) && !isFilterActive()"
			class="filter name-box {{ smallFont ? 'small' : '' }} {{ disabled ? 'gray-text' : '' }}"
			value="{{ getObjectText(object) }}"
		/>
		<span class="placeholder" *ngIf="!getObjectText(object) && !isFilterActive()">{{
			placeholderText
		}}</span>
		<input
			*ngIf="isFilterActive()"
			placeholder="{{ getInputPlaceholderText() }}"
			class="filter {{ smallFont ? 'small' : '' }} {{ disabled ? 'gray-text' : '' }}"
			[(ngModel)]="filterQuery"
			appInstantFocus
			(click)="$event.stopPropagation()"
		/>

		<div
			data-cy="blue-dropdown-button"
			class="dropdown-arrow-mask {{ withBorderRadius ? 'with-rounded-corners' : '' }} {{
				smallFont ? 'with-small-font' : ''
			}}"
		>
			<div
				class="dropdown-triangle {{ openMenu ? 'up' : 'down' }} {{
					disabled ? 'gray' : ''
				}}"
			></div>
		</div>
	</div>
	<div
		#blueMenu
		*ngIf="openMenu"
		class="menu {{ openMenu ? 'dopen' : '' }}"
		inherit-width
		appCheckOrientation
		[additionalPadding]="18"
	>
		<div class="scroll" appAutomaticScroll [percentage]="scrollInitialPercentage">
			<div class="item active new-item" *ngIf="makingNewItem">
				<input
					data-add-action-name="new-item-input"
					class="new-item-input new-scenario"
					(keydown)="newItemKeydownController($event, 'SCENARIO')"
					spawn-focus=""
					autofocus
					(click)="$event.stopPropagation()"
					[(ngModel)]="newItem"
				/>
				<app-white-cross
					class="cross-button"
					(clickCb)="closeNewItem(); $event.stopPropagation()"
				></app-white-cross>
			</div>
			<div
				data-add-action-name="blue-dropdown-item"
				[attr.data-cy]="'blue-dropdown-item-' + getOptionText(option)"
				class="item {{ compareSelect(object, option) ? 'active' : '' }} {{
					isOptionDisabled(option) ? 'inactive' : ''
				}}"
				*ngFor="let option of options | filter : filterFunc"
				(click)="onItemClick($event, option)"
			>
				<div *ngIf="getOptionColor(option)" class="color-container"></div>
				<app-blue-checkbox
					*ngIf="isMultiselect"
					[check]="getCheckFunction(option)"
					[disabled]="isOptionDisabled(option)"
				></app-blue-checkbox>
				<div class="text-container">{{ getOptionText(option) }}</div>
			</div>
			<div *ngIf="!hasSearchResults()" class="item no-result-filter">
				( No results found )
			</div>
			<div
				*ngIf="separateLastOption"
				class="special-option item {{
					compareSelect(object, separateLastOption) ? 'active' : ''
				}}"
				(click)="itemClickCallback.emit(separateLastOption); openMenu = false"
			>
				<div *ngIf="getOptionColor(separateLastOption)" class="color-container"></div>
				<div class="text-container">{{ getOptionText(separateLastOption) }}</div>
			</div>
		</div>
		<div
			*ngIf="addString"
			[ngClass]="{
				item: true,
				'new-item-dropdown-option': true,
				'adjust-background': withBorderRadius
			}"
			(click)="$event.stopPropagation(); openNewItem($event)"
		>
			<div data-cy="dropdown-add-new-item" class="plus-button"></div>
			<div class="new-item-dropdown-add-text">
				<span>{{ addString }}</span>
			</div>
		</div>
	</div>
</div>
