<div class="container" (click)="$event.stopPropagation()">
	<div
		*ngFor="let color of preferredColorsArray"
		class="icon-container"
		(click)="changeColor(color)"
	>
		<div
			class="color-icon"
			[ngStyle]="{ 'background-color': color }"
			[ngClass]="{ selected: selectedColor === color }"
		>
			<img src="resources/img/thick-check.svg" *ngIf="selectedColor === color" />
		</div>
	</div>
	<div class="icon-container" (click)="openCloseColorPicker()">
		<div [ngClass]="{ selected: showColorPicker }" class="color-icon picker"></div>
	</div>
</div>

<app-color-picker
	*ngIf="showColorPicker"
	(selectedColor)="changeColor($event)"
	[showPreferred]="true"
	[inputColor]="selectedColor"
	[closeCb]="openCloseColorPicker"
></app-color-picker>
