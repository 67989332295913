<div class="container">
	<div
		class="header"
		[ngStyle]="{ 'grid-template-columns': getGridTemplateColumns() }"
		[class.padding-r-8]="isFiltered"
	>
		<div class="date-col header-item">
			<span class="title">{{ text().DATE }}</span>
			<div
				class="sort-arrow
            {{
					currentSort === sortOption.DATE
						? currentOrder === sortOrder.DESCENDENT
							? 'desc'
							: 'asc'
						: ''
				}}"
				(click)="
					orderList(
						currentOrder === sortOrder.DESCENDENT
							? sortOrder.ASCENDENT
							: sortOrder.DESCENDENT,
						sortOption.DATE
					)
				"
			></div>
		</div>
		<div
			*ngIf="dlType"
			data-add-action-name="toggle-type-filter"
			class="type-col header-item"
			(click)="toggleFilter(); $event.stopPropagation()"
		>
			<span class="title" [ngClass]="isFilterApplied()">{{ text().TYPE }}</span>
			<div #funnel class="funnel" [ngClass]="'funnel ' + isFilterApplied()"></div>
		</div>

		<div class="content-col header-item">
			<span class="title">{{ text().CONTENT }}</span>
		</div>
		<div class="rationale-col header-item">
			<span class="title">{{ text().RATIONALE }}</span>
		</div>
		<div *ngIf="dlOperational" class="ops-col header-item">
			<span class="title">{{ text().TACTICAL }}</span>
		</div>
		<div *ngIf="dlOperational" class="state-col header-item">
			<span class="title">{{ text().STATUS }}</span>
		</div>
		<div *ngIf="dlSignature" class="signed-col header-item">
			<span class="title">{{ text().SIGNED }}</span>
		</div>
		<div class="logged-col header-item">
			<span class="title">{{ text().LOGGED }}</span>
		</div>
		<div class="media-col header-item"><span class="title">Media</span></div>
	</div>

	<div [ngClass]="{ 'table-border': true, active: isFiltered }">
		<div [ngClass]="{ 'scroll-content': isFiltered }">
			<div data-cy="decision-log-list" *ngIf="filteredDecisions.length > 0" class="body">
				<div
					*ngFor="let decision of items | filter : filterDecisions; let i = index"
					[id]="'decision_' + i"
					class="item item-bottom"
					[ngStyle]="{ 'grid-template-columns': getGridTemplateColumns() }"
					[ngClass]="{ expanded: expand[i], dark: shouldChangeBackground(decision.type) }"
					data-cy="decision-log-item"
				>
					<div class="date-col item-border">
						{{ getDecisionDate(decision) }}
					</div>
					<div *ngIf="dlType" class="type-col item-border">
						<div
							[innerHTML]="getLocalizedType(decision.type) | highlight : searchText"
						></div>
					</div>
					<div class="content-col item-border content-col-separator">
						<div
							[id]="'contentTextarea_' + i"
							class="content-textarea"
							[ngClass]="{ expanded: isContentExpanded(i) }"
						>
							<div
								[innerHTML]="
									!decision.description
										? '-'
										: ((isContentExpanded(i)
												? decision.description
												: (decision.description | slice : 0 : 100)
										  ) | highlight : searchText)
								"
							></div>
						</div>
						<div class="bottom-buttons-container">
							<div class="audio-toggle-container">
								<div
									*ngIf="checkAudioPlaying(decision, audioTypes.CONTENT)"
									class="audio-player"
								>
									<div
										class="icon-container"
										(click)="
											audioPlaying.paused
												? audioPlaying.play()
												: audioPlaying.pause()
										"
									>
										<div *ngIf="!audioPlaying?.paused" class="pause-icon">
											<img src="resources/img/new-pause.svg" />
										</div>
										<div *ngIf="audioPlaying?.paused" class="play-icon">
											<img src="resources/img/new-play.svg" />
										</div>
									</div>
									<div class="bar-container">
										<span #bar class="bar"></span>
										<div #ball class="ball"></div>
									</div>
								</div>
								<button
									*ngIf="hasAudio(decision)"
									class="log-audio-button"
									(click)="playAudio(decision, audioTypes.CONTENT)"
								>
									<img src="resources/img/message.svg" />
								</button>
							</div>
							<button
								*ngIf="decision.description.length > 80"
								(click)="toggleContent(i)"
								class="toggle-button content-toggle-button"
							>
								{{ isContentExpanded(i) ? text().HIDE : text().SEE_ALL }}
							</button>
						</div>
					</div>
					<div class="rationale-col item-border">
						<div
							[id]="'rationaleTextarea_' + i"
							class="content-textarea"
							[class.expanded]="isRationaleExpanded(i)"
						>
							<div
								[innerHTML]="
									!decision.result
										? '-'
										: ((isRationaleExpanded(i)
												? decision.result
												: (decision.result | slice : 0 : 100)
										  ) | highlight : searchText)
								"
							></div>
						</div>
						<button
							*ngIf="decision.result.length > 80"
							(click)="toggleRationale(i)"
							class="toggle-button"
						>
							{{ isRationaleExpanded(i) ? text().HIDE : text().SEE_ALL }}
						</button>
					</div>
					<div *ngIf="dlOperational" class="ops-col item-border">
						<div
							[innerHTML]="getLocalizedTactics(decision) | highlight : searchText"
						></div>
					</div>
					<div *ngIf="dlOperational" class="state-col item-border">
						<div
							[innerHTML]="getLocalizedState(decision) | highlight : searchText"
						></div>
					</div>
					<div *ngIf="dlSignature" class="signed-col item-border">
						<div
							[innerHTML]="
								getSigningUser(decision)
									? (getSigningUser(decision)!.name | highlight : searchText)
									: '-'
							"
						></div>
					</div>
					<div class="logged-col item-border">
						<div [innerHTML]="decision.name | highlight : searchText"></div>
					</div>
					<div class="media-col item-border">
						<div class="thumbnail-wrapper" data-cy="csThumbnailWrapper">
							<div
								*ngFor="
									let file of getDecisionFiles(i) | slice : 0 : 2;
									let i = index
								"
								[attr.data-cy]="'csMediaItem-' + i"
								class="media-item media-{{ i + 1 }}"
								[class.cropped]="i >= 1"
							>
								<div
									data-add-action-name="cs-open-file"
									*ngIf="!file.isImage()"
									(click)="openFile(file)"
								>
									<div class="thumbnail">
										<img
											src="{{ isAudioOrVideo(file) }}"
											width="12"
											height="12"
										/>
									</div>
								</div>
								<div *ngIf="file.isImage()">
									<img
										data-add-action-name="cs-open-image-inspector"
										appIrisImage
										[id]="file.id_file"
										width="{{ i === 0 ? '32' : '27' }}"
										height="32"
										(click)="openImageInspector(file, i)"
										class="image"
									/>
								</div>
							</div>
							<button
								data-add-action-name="cs-more-button"
								*ngIf="getDecisionFiles(i)!.length > 2"
								(click)="openMoreMedia(decision, i)"
								class="more-button"
							>
								<span>...</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-no-results
		*ngIf="
			(items.length > 0 && filteredDecisions.length === 0 && isFiltered) ||
			(items.length > 0 && filteredDecisions.length === 0)
		"
	>
	</app-no-results>
</div>

<app-image-viewer
	*ngIf="inspectedImage !== -1 && imageArrays[inspectedImage].length > 0"
	[images]="imageArrays[inspectedImage]"
	(cancelCb)="closeImageInspector()"
></app-image-viewer>
<app-loading-dialog
	*ngIf="dialogs.loading"
	[downloadingVideo]="true"
	[headerText]="text().DOWNLOADING_VIDEO"
	[bodyText]="text().PLAYBACK_ISSUE"
	[infoText]="dialogs.loading"
>
</app-loading-dialog>
<app-task-completed-dialog
	*ngIf="dialogs.taskCompleted"
	[headerText]="text().DOWNLOADED_VIDEO"
	[bodyText]="text().COMPLETED"
	[infoText]="text().PLAYBACK_INFO"
	(closeCallback)="dialogs.taskCompleted = false"
></app-task-completed-dialog>

<app-text-filter
	appDetectClickOut
	(callback)="closeTypeFilter()"
	[hidden]="!showFilter"
	[filters]="filters"
	(applyFilterEmit)="updateFilterDecisions($event)"
>
</app-text-filter>
