import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { CommandStructure } from "src/app/dto/command-structure/command-structure";
import { CommandStructureCollection } from "src/app/dto/command-structure/command-structure-collection";
import { CSCommander } from "src/app/dto/command-structure/cs-commander";
import { CSSectorResourceAssignation } from "src/app/dto/command-structure/cs-resource";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { CSSupport } from "src/app/dto/command-structure/cs-support";
import { DTOArray } from "src/app/dto/net/dto-array";
import { Area } from "src/app/dto/items/area";
import { CircleArea } from "src/app/dto/items/circle-area";
import { PolygonArea } from "src/app/dto/items/polygon-area";
import { Appliance } from "src/app/dto/resources/appliance";
import { ApplianceRelation } from "src/app/dto/resources/appliance-relation";
import { Personnel } from "src/app/dto/resources/personnel";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceState } from "src/app/dto/resources/state";
import { MainService } from "src/app/global/main.service";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { MapItemsService } from "../../map/map-items.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { Subject } from "rxjs";
import { share } from "rxjs/operators";
import { IncidentService } from "../../incident.service";

@Injectable({
	providedIn: "root"
})
export class CommandStructureService {
	commandStructures: CommandStructureCollection = new CommandStructureCollection();
	resources: Array<Resource> = new Array();
	states: Array<ResourceState> = new Array();

	public readonly commandStructureLoaded$ = new Subject<CommandStructure>();
	public readonly setCommander = new Subject<CSCommander>();
	public readonly commander$ = this.setCommander.pipe(
		share() // Runs once for multiple subscriptions
	);

	public readonly chartTemplates: any = {
		COMMANDER_UNSPECIFIED: "app/templates/ics/command-structure/commander-unspecified.html",
		COMMANDER: "app/templates/ics/command-structure/incident-commander.html",
		SUPPORT: "app/templates/ics/command-structure/support.html",
		SECTOR_UNSPECIFIED: "app/templates/ics/command-structure/sector-unspecified.html",
		SECTOR: "app/templates/ics/command-structure/sector.html",
		CONTROL_ROOM: "app/templates/ics/command-structure/control-room.html",
		ADD_BUTTON: "app/templates/ics/command-structure/add-btn.html"
	};

	private readonly resource: ResourceService;
	private readonly mssg: MessagingService;
	private readonly main: MainService;
	private readonly wreq: WebRequestFactory;
	private readonly mis: MapItemsService;
	private readonly ems: IncidentService;

	constructor(resource: ResourceService, mssg: MessagingService, main: MainService, wreq: WebRequestFactory, mis: MapItemsService, ems: IncidentService) {
		this.resource = resource;
		this.mssg = mssg;
		this.main = main;
		this.wreq = wreq;
		this.mis = mis;
		this.ems = ems;

		this.resources = resource.Resources;
		this.states = resource.States;

		mssg.registerListener(MESSAGE_TYPE.NEW_APPL_REL, this.newApplRelUpdate, false, false);
		mssg.registerListener(MESSAGE_TYPE.DELETE_APPL_REL, this.deleteApplRelUpdate, false, false);
		mssg.registerListener(MESSAGE_TYPE.LOAD_AGENTS, this.setAgentReferences, true, false);
		this.mis.updateSectorName$.subscribe((area) => this.setSectorNameToArea(area));
	}

	public readonly getStructureByCommanderId: Function = (commander: CSCommander | number) => {
		return this.commandStructures.getStructureByCommanderId(commander instanceof CSCommander ? commander.id : commander);
	};

	/**
	 * A resource has been updated, so the CSResource items that contain it must be updated
	 */
	public readonly insertResource: Function = (res: Resource) => {
		this.resources = this.resource.Resources.filter((res) => !res.deleted);
		if (!res || !this.resources) return null;
		let old = this.resources.find((e) => e.id == resource.id);
		if (!old) return null;

		let missionChanged = old.id_incident != res.id_incident;
		CloneFactory.cloneProperties(old, res);

		const resource = this.commandStructures.getResourceByAgentId(res.id) as CSSectorResourceAssignation;
		if (!resource) return false;
		if (!missionChanged) {
			resource.addAgentObject(old);
		}
		return true;
	};

	public readonly unload: Function = () => {
		this.commandStructures.unload();
	};

	public readonly getCommandStructures: Function = async (id_mission?: number) => {
		const commanders = await this.getCommanders(id_mission);
		const supports = await this.getSupports();
		const sectors = await this.getSectors();
		const resources = await this.getResources();
		resources.forEach((resource: CSSectorResourceAssignation) => {
			resource.addAgentObject(this.resource.Resources.find((e) => e.id === resource.id_resource));
		});
		sectors.forEach((sector: CSSector) => {
			sector.resources = resources.filter((e: CSSectorResourceAssignation) => e.id_sector === sector.id);
		});
		commanders.forEach((comm: CSCommander) => {
			let cs = new CommandStructure(comm);
			cs.supports = supports.filter((e: CSSupport) => e.id_commander === comm.id);
			cs.sectors = sectors.filter((e: CSSector) => e.id_commander === comm.id);
			this.commandStructures.insertStructure(cs);
			this.commandStructureLoaded$.next(cs);
		});
		return this.commandStructures;
	};

	public readonly getStructureFromMission: (id_mission: number) => Promise<CommandStructure | undefined> = async (id_mission) => {
		if (this.commandStructures.getStructureByMissionId(id_mission)) return this.commandStructures.getStructureByMissionId(id_mission);
		let comm = await this.getCommanders(id_mission);
		if (!comm || !comm.length || !comm[0]) return undefined;
		else comm = comm[0];
		const supports = await this.getSupports(comm.id_commander);
		const sectors = await this.getSectors(comm.id_commander);
		let cs = new CommandStructure(comm);
		cs.supports = supports;
		cs.sectors = sectors;
		this.commandStructures.insertStructure(cs);
		await this.getResources(comm.id_commander);
		this.commandStructureLoaded$.next(cs);
		return cs;
	};

	public readonly loadCommand: Function = async (id_m: number) => {
		const id_mission = id_m ? id_m : this.main.getCurrentIncident() ? this.main.getCurrentIncident()!.id : -1;

		this.resources = this.resource.Resources.filter((agent) => !agent.deleted);
		CloneFactory.cloneProperties(this.states, this.resource.States);

		if (id_mission === -1) return await this.getCommandStructures();
		else return await this.getStructureFromMission(id_mission);
	};

	public readonly getMissionCommander: (id_mission: number) => Promise<CSCommander | undefined> = async (id) => {
		const struct = this.commandStructures.getStructureByMissionId(id);
		if (struct) return Promise.resolve(struct.commander);
		else {
			const comms = await this.wreq.getAllCommanders(id);
			let ans: Array<CSCommander> = [];
			DTOArray.UpdateFromJsonArray(ans, comms, CSCommander);
			return ans[0];
		}
	};

	public readonly getCommanders: Function = async (id_mission?: number) => {
		const json = await this.wreq.getAllCommanders(id_mission);
		let ans: Array<CSCommander> = [];
		DTOArray.UpdateFromJsonArray(ans, json, CSCommander);
		this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_COMMANDERS);
		return ans;
	};

	public readonly updateMissionCommander: Function = async () => {
		const reply = await this.wreq.getAllCommanders(this.main.getCurrentIncident()!.id);
		const comm = CSCommander.fromJson(reply[0]);
		let oldComm = this.commandStructures.getCommander(comm.id);
		this.commandStructures.insertCommander(comm);
		if (oldComm) {
			this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_COMMANDER, oldComm);
			this.setCommander.next(oldComm);
		} else {
			this.mssg.fire(MESSAGE_TYPE.SET_CS_COMMANDER, comm);
			this.setCommander.next(comm);
		}
		return true;
	};

	public readonly saveCommander: (comm: CSCommander) => Promise<CSCommander | undefined> = async (commander: CSCommander) => {
		const success = await this.wreq.saveCommander(commander);

		if (success) {
			const replyComm = CSCommander.fromJson(success);
			this.commandStructures.insertCommander(replyComm);
			this.setCommander.next(replyComm);

			return replyComm;
		}
		return;
	};

	public readonly saveSupport: (support: CSSupport) => Promise<CSSupport | undefined> = async (support: CSSupport) => {
		const success = await this.wreq.saveSupport(support);
		if (success) {
			const supp = CSSupport.fromJson(success);
			this.commandStructures.insertSupport(supp);
			return supp;
		} else return;
	};

	public readonly deleteSupport: Function = async (support: CSSupport) => {
		const success = await this.wreq.deleteSupport(support);
		if (success) {
			this.commandStructures.removeSupport(support.id);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_SUPPORT, support);
		}
		return success;
	};

	public readonly removeResourceFromSectors: Function = (resource: CSSectorResourceAssignation) => {
		this.commandStructures.removeResource(resource);
	};

	public readonly saveSector = async (sector: CSSector, saveArea = false): Promise<CSSector | undefined> => {
		const success: string = await this.wreq.saveSector(sector);
		if (success) {
			let sectorAns = CSSector.fromJson(success);
			if (sectorAns.id_resource_comm !== -1) sectorAns.__sectorCommander = this.resource.Resources.find((e) => e.id === sectorAns.id_resource_comm);
			if (sectorAns.id_safety_officer !== -1) sectorAns.__safetyOfficer = this.resource.Resources.find((e) => e.id === sectorAns.id_safety_officer);
			if (sectorAns.id_area !== -1 && saveArea) {
				await this.mis.saveArea(sector.__area!);
				sectorAns.__area = this.mis.Areas.find((e) => e.id === sectorAns.id_area);
			}
			this.setupSector(sectorAns);
			const res = sector.resources;
			CloneFactory.cloneProperties(sector, sectorAns);
			sector.resources = res;
			this.commandStructures.insertSector(sector);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_SECTOR, sector);
			return sector;
		}
		return;
	};

	public readonly deleteSector: Function = async (sector: CSSector) => {
		const success = await this.wreq.deleteSector(sector);
		if (success) {
			this.commandStructures.removeSector(sector.id);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_SECTOR, sector);
			return true;
		}
		return false;
	};

	public readonly setSectorNameToArea: Function = (area: Area) => {
		const sector = this.commandStructures.getSectorByAreaId(area.id);
		if (sector) {
			sector.name = area.name;
			const ans = this.wreq.saveSector(sector);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_SECTOR, sector);
			return ans;
		}
	};

	public readonly assignAgentToResource: Function = (res: CSSectorResourceAssignation) => {
		this.setResourceParams(res);
		if (res.__object instanceof Appliance) {
			res.__object.personnel.forEach((person) => {
				if (!person.deleted) {
					let newRes = new CSSectorResourceAssignation(-1, -1, person.id, false);
					this.setResourceParams(newRes);
				}
			});
			res.__object.closed_personnel.forEach((person) => {
				if (!person.object.deleted) {
					let newRes = new CSSectorResourceAssignation(-1, -1, person.object.id, false);
					this.setResourceParams(newRes);
				}
			});
		}
	};

	public getResourceByAgentId: (id: number) => CSSectorResourceAssignation | undefined = (id) => {
		return this.commandStructures.getResourceByAgentId(id);
	};

	public readonly getResources: Function = async (id_comm?: number | undefined) => {
		const id = id_comm ? id_comm : -1;
		const reply = await this.wreq.getAllCSResources(id);
		if (reply) {
			let newResources: Array<CSSectorResourceAssignation> = [];
			DTOArray.UpdateFromJsonArray(newResources, reply, CSSectorResourceAssignation);
			newResources.forEach((e) => {
				this.assignAgentToResource(e);
				this.commandStructures.insertResource(e);
			});
			return newResources;
		}
		return false;
	};

	public readonly saveResource: Function = async (resource: CSSectorResourceAssignation) => {
		const success = await this.wreq.saveCSResource(resource);
		if (success) {
			const res = CSSectorResourceAssignation.fromJson(success);
			CloneFactory.cloneProperties(resource, res);
			resource.addAgentObject(this.resource.Resources.find((e) => e.id === resource.id_resource));
			this.commandStructures.insertResource(resource);
			return resource;
		}
		return false;
	};

	public readonly updateCSResource: Function = async (id: number) => {
		const reply = await this.wreq.getCSResource(id);
		if (reply && reply.length > 0) {
			const newRes = CSSectorResourceAssignation.fromJson(reply[0] == "{" ? reply : reply[0]);
			this.assignAgentToResource(newRes);
			this.commandStructures.insertResource(newRes);
			return newRes;
		}
		return false;
	};

	public readonly deleteCSResourceUpdate: Function = (id: number) => {
		this.commandStructures.removeResource(id);
		return true;
	};

	public readonly deleteResource: Function = async (resource: CSSectorResourceAssignation | number) => {
		let id = resource instanceof CSSectorResourceAssignation ? resource.id_assignation : resource;
		const success = await this.wreq.deleteResource(id);
		if (success) {
			this.deleteCSResourceUpdate(id);
			return true;
		}
		return false;
	};

	public readonly updateSector: Function = async (id: number) => {
		if (id === -1) return;
		const success = await this.wreq.getSectorById(id);
		if (success) {
			const new_sector = CSSector.fromJson(success);
			if (new_sector.id === -1) {
				return this.deleteSectorUpdate(id);
			}
			const struct = this.commandStructures.getStructureByCommanderId(new_sector.id_commander);
			if (!struct) return false;
			if (new_sector.id_resource_comm !== -1) {
				new_sector.__sectorCommander = this.resource.Resources.find((e) => e.id === new_sector.id_resource_comm);
				this.commandStructures.removeResourceOnIncidentByAgentId(new_sector.id_resource_comm, struct.id_mission);
			}
			if (new_sector.id_safety_officer !== -1) {
				new_sector.__safetyOfficer = this.resource.Resources.find((e) => e.id === new_sector.id_safety_officer);
				this.commandStructures.removeResourceOnIncidentByAgentId(new_sector.id_safety_officer, struct.id_mission);
			}
			if (new_sector.id_area !== -1) new_sector.__area = this.mis.Areas.find((e) => e.id === new_sector.id_area);
			const resources = this.commandStructures.getSectorResources(new_sector.id);
			if (resources) new_sector.resources = resources;
			this.setupSector(new_sector);
			this.commandStructures.insertSector(new_sector);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_SECTOR, new_sector);
			return new_sector;
		}
		return false;
	};

	public readonly deleteSectorUpdate: Function = (i: number | CSSector) => {
		let id = typeof i === "number" ? i : i.id;
		this.commandStructures.removeSector(id);
		this.mssg.fire(MESSAGE_TYPE.DELETE_CS_SECTOR, id);
		return true;
	};

	public readonly deleteSupportUpdate: Function = (i: number | CSSupport) => {
		let id = typeof i === "number" ? i : i.id;
		this.commandStructures.removeSupport(id);
		this.mssg.fire(MESSAGE_TYPE.DELETE_CS_SUPPORT, id);
		return true;
	};

	public readonly updateSupport: Function = async (id: number) => {
		const success = await this.wreq.getSupportById(id);
		if (success) {
			var newSupport = CSSupport.fromJson(success);
			if (newSupport.id === -1) {
				return this.deleteSupportUpdate(id);
			}
			this.commandStructures.insertSupport(newSupport);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_CS_SUPPORT, newSupport);
			return newSupport;
		}
		return undefined;
	};

	public readonly formatDate: Function = (date: Date) => {
		if (date) {
			return (
				(date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
				"/" +
				(date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (1 + date.getMonth())) +
				"/" +
				date.getFullYear() +
				" " +
				(date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
				":" +
				(date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()) +
				":" +
				(date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds())
			);
		} else return null;
	};

	public readonly newApplRelUpdate: Function = (rel: ApplianceRelation) => {
		const res: CSSectorResourceAssignation = this.commandStructures.getResourceByAgentId(rel.id_appliance);
		const appl = this.resource.Resources.find((e) => e.id === rel.id_appliance);
		if (!res) return false;
		res.addAgentObject(appl);
		return true;
	};

	public readonly setupSector: (sector: CSSector) => void = (sector) => {
		// sets area coordinatesm agent commander of sector and resource refs
		if (sector.id_resource_comm && sector.id_resource_comm > -1) {
			const agentComm = this.resource.Resources.find((age) => age.id == sector.id_resource_comm);
			sector.__sectorCommander = agentComm;
		}
		if (sector.id_safety_officer && sector.id_safety_officer > -1) {
			const safetyOfficer = this.resource.Resources.find((agent) => agent.id == sector.id_safety_officer);
			sector.__safetyOfficer = safetyOfficer;
		}
		const area: Area = this.mis.Areas.find((ar: Area) => ar.id === sector.id_area)!;
		if (sector.id_area !== -1 && area) {
			if (!(area as PolygonArea).vertices) {
				sector.latitude = (area as CircleArea).center.latitude;
				sector.longitude = (area as CircleArea).center.longitude;
			} else {
				sector.latitude = (area as PolygonArea).vertices[0].latitude; // TODO: maybe calculate actual polygon center
				sector.longitude = (area as PolygonArea).vertices[0].longitude;
			}
			sector.__area = area;
		}
		sector.resources.forEach((res) => {
			this.setResourceParams(res);
		});
	};

	public isIncidentClosed(): boolean {
		const currentIncident = this.ems.getCurrentIncident();
		return currentIncident?.closed ?? false;
	}

	private readonly getSupports: Function = async (id_comm?: number) => {
		var id = id_comm && id_comm > -1 ? id_comm : -1;
		const reply = await this.wreq.getAllSupports(id);
		if (reply && reply.length > 0) {
			const supports = new Array<CSSupport>();
			DTOArray.UpdateFromJsonArray(supports, reply, CSSupport);
			if (!id_comm) this.commandStructures.updateAllSupports(supports);
			else this.commandStructures.updateSupports(id_comm, supports);
			return supports;
		}
		return [];
	};

	private readonly getSectors: Function = async (id_comm: number) => {
		const reply = await this.wreq.getAllSectors(id_comm);
		if (reply && reply.length > 0) {
			let sectors = new Array<CSSector>();
			DTOArray.UpdateFromJsonArray(sectors, reply, CSSector);
			sectors.forEach(this.setupSector as (value: CSSector, index: number, array: CSSector[]) => void);
			this.commandStructures.updateSectors(sectors);
			return sectors;
		}
		return [];
	};

	private readonly setResourceParams: Function = (res: CSSectorResourceAssignation) => {
		const agent = this.resource.Resources.find((e) => e.id === res.id_resource);
		if (!agent) return false;
		res.addAgentObject(agent);
		return true;
	};

	private readonly deleteApplRelUpdate: Function = (rel: ApplianceRelation) => {
		const res = this.commandStructures.getResourceByAgentId(rel.id_appliance);
		if (!res) return false;
		const idx = res.__personnel.findIndex((e: Personnel) => e.id === rel.id_personnel);
		if (idx > -1) {
			res.__personnel.splice(idx, 1);
			return true;
		} else return false;
	};

	private readonly setAgentReferences: Function = () => {
		this.commandStructures.getAllSectors().forEach(this.setupSector);
	};
}
