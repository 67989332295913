import { DTO } from "../../net/dto";

export class ArrowFile implements DTO {
	id: number;
	id_arrow: number;
	id_file: number;
	filename: string;

	timestamp = Date.now();

	public constructor(id: number, id_arrow: number, id_file: number, filename: string) {
		this.id = id;
		this.id_arrow = id_arrow;
		this.id_file = id_file;
		this.filename = filename;
	}

	// this fromJson implementation should only be called on malformed strings without shapeType property, otherwise defer to the fromJson implementations from children of this class
	public static fromJson: (json: string) => ArrowFile = (json) => {
		const jc = JSON.parse(json);
		return new ArrowFile(jc.id, jc.id_arrow, jc.id_file, jc.filename);
	};

	public getJson(): string {
		return JSON.stringify(this, ["id", "id_arrow", "id_file", "filename"]);
	}
}
