<div class="main-black-mask" (click)="onCancel()"></div>
<div class="alert-dialog">
	<div class="dialog-header">
		{{ text().NEW_PLANE }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="onCancel()"></app-white-cross>
	<app-upload-drag-zone
		[itemType]="itemTypes.OVERLAY"
		(fileUploaded)="onFileUploaded($event)"
	></app-upload-drag-zone>
</div>
