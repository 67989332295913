import { Injectable } from "@angular/core";
import Portal from "@arcgis/core/portal/Portal";
import PortalItem from "@arcgis/core/portal/PortalItem";
import { Subject } from "rxjs";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { Area } from "src/app/dto/items/area";
import { MapItem } from "src/app/dto/items/map-item";
import { Poi } from "src/app/dto/items/poi";
import { Resource } from "src/app/dto/resources/resource";
import { ESRI_BASEMAPS_FREE } from "./esri.enums";
import MapView from "@arcgis/core/views/MapView";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import Layer from "@arcgis/core/layers/Layer";
import WMSLayer from "@arcgis/core/layers/WMSLayer";
import WMTSLayer from "@arcgis/core/layers/WMTSLayer";
import WFSLayer from "@arcgis/core/layers/WFSLayer";
import Point from "@arcgis/core/geometry/Point";
import { WGSPoint } from "src/app/dto/map/location";
import KMLLayer from "@arcgis/core/layers/KMLLayer";
import { Arrow } from "src/app/dto/items/arrow/arrow";
import { CircleArea } from "src/app/dto/items/circle-area";
import { PolygonArea } from "src/app/dto/items/polygon-area";
import { MapAuxiliar } from "../auxiliar/auxiliar";
import {
	ESRI_LINE_STYLES,
	ESRI_SHAPE_STYLES,
	EsriShape
} from "./components/graphics/esri-shape/esri-shape";
import { PATTERN } from "src/app/dto/items/types/area-type";
import { ITEM_STATE } from "src/app/dto/items/status";

@Injectable({
	providedIn: "root"
})
export class EsriService {
	public selectedPoi: Poi | undefined;
	public poiDeleted: boolean = false;

	public $poiSelect = new Subject<Poi>();

	public selectedArea: Area | undefined;
	public $areaSelect = new Subject<Area>();

	public selectedArrow: Arrow | undefined;
	public $arrowSelect = new Subject<Arrow>();

	public selectedResource: Resource | undefined;
	public $resourceSelect = new Subject<Resource>();

	public $clearSelect = new Subject<void>();
	public $clearState = new Subject<void>();

	public radialMenuItem: MapItem | undefined;

	public $showRadialMenu = new Subject<MapItem>();
	public $clearRadialMenus = new Subject<void>();

	public $dragPoi = new Subject<void>();
	public $dragFsg = new Subject<void>();

	public currentBasemap: string = ESRI_BASEMAPS_FREE.HYBRID;
	public $changeBasemap = new Subject<string>();

	public portal: Portal | undefined;
	public portalItems: Array<PortalItem> = [];
	public WMTSLayers: Array<WMTSLayer> = [];
	public WMSLayers: Array<WMSLayer> = [];
	public WFSLayers: Array<WFSLayer> = [];
	public activeLayersMap = new Map<string, Layer | undefined>(); // esri id to layer map
	public IRISBottomLayerId = 0;

	public areaEsriPatternDic = new Map<PATTERN, ESRI_SHAPE_STYLES>();

	public $changeMap = new Subject<MapView>();
	public esriCreds:
		| {
				token: string;
				expires: number;
				ssl: boolean;
		  }
		| undefined;

	private tokenRefreshInterval: number | undefined;

	private readonly wreq: WebRequestFactory;

	constructor(wreq: WebRequestFactory) {
		this.wreq = wreq;

		this.areaEsriPatternDic.set(PATTERN.NONE, ESRI_SHAPE_STYLES.NONE);
		this.areaEsriPatternDic.set(PATTERN.MESH, ESRI_SHAPE_STYLES.DIAGONAL_CROSS);
		this.areaEsriPatternDic.set(PATTERN.LINE, ESRI_SHAPE_STYLES.FORWARD_DIAGONAL);
	}

	public readonly logToArcgis = async (): Promise<boolean> => {
		const ans = await this.wreq.getEsriToken();
		if (ans) {
			const esriCreds = JSON.parse(ans);
			if (!esriCreds) return false;
			this.esriCreds = esriCreds;

			IdentityManager.registerToken({
				server: "https://www.arcgis.com/sharing/rest",
				token: esriCreds.token,
				expires: esriCreds.expires,
				ssl: esriCreds.ssl
			});
			const expirePeriod_ms = esriCreds.expires - Date.now();
			if (!this.tokenRefreshInterval)
				this.tokenRefreshInterval = window.setInterval(
					this.logToArcgis,
					expirePeriod_ms - expirePeriod_ms * 0.1
				);
			return true;
		}
		return false;
	};

	public readonly logToPortal = (portal: string): void => {
		this.portal = new Portal({
			url: portal
		});
		this.portal.authMode = "immediate";
		this.portal.load().then(() => {
			console.log("ARCGis Portal loaded");
			let queryParams = {
				query: "owner:" + this.portal!.user.username,
				sortField: "numViews",
				sortOrder: "desc",
				num: 20
			};

			this.portal!.queryItems(queryParams as any).then((ans) => {
				this.portalItems = ans.results;
			});
		});
	};

	public readonly selectPoi = (poi: Poi): void => {
		this.selectedPoi = poi;
		this.$poiSelect.next(poi);
	};

	public readonly selectArea = (area: Area): void => {
		this.selectedArea = area;
		this.$areaSelect.next(area);
	};

	public selectArrow(arrow: Arrow): void {
		this.selectedArrow = arrow;
		this.$arrowSelect.next(arrow);
	}

	public readonly selectResource = (res: Resource): void => {
		this.selectedResource = res;
		this.$resourceSelect.next(res);
	};

	public readonly clearSelection = (): void => {
		this.selectedPoi =
			this.selectedArea =
			this.selectedResource =
			this.selectedArrow =
				undefined;
		this.closeRadialMenus();
		this.$clearSelect.next();
	};

	public readonly clearState = (): void => {
		this.$clearState.next();
	};

	public readonly openRadialMenu = (item: MapItem): void => {
		item.__showRadialMenu = true;
		this.radialMenuItem = item;
		this.$showRadialMenu.next(item);
	};

	public readonly closeRadialMenus = (): void => {
		this.radialMenuItem = undefined;
		this.$clearRadialMenus.next();
	};

	public readonly dragPoi = (poi: Poi): void => {
		this.$dragPoi.next();
	};

	public readonly dragFsg = (fsg: FsgBuilding): void => {
		this.$dragFsg.next();
	};

	public readonly changeBasemap = (basemap: string): void => {
		this.currentBasemap = basemap;
		this.$changeBasemap.next(basemap);
	};

	public readonly changeMap = (mapv: MapView): void => {
		this.$changeMap.next(mapv);
	};

	public readonly locationToScreenCoords = (
		mapv: MapView,
		lat: number,
		lng: number
	): { x: number; y: number } => {
		const xy = mapv.toScreen(
			new Point({ x: lng, y: lat, spatialReference: { wkid: WGSPoint.WKID } })
		);
		const mapref = document.getElementById("esriContainer");
		if (mapref && xy) {
			let rect = mapref.getBoundingClientRect();
			return { x: xy.x + rect.x, y: xy.y + rect.y };
		}
		return { x: 0, y: 0 };
	};

	public getAreaShape(area: Area | Arrow): EsriShape {
		if (area instanceof CircleArea) {
			return {
				type: "circle",
				center: area.center,
				radius: area.radius,
				vertices: undefined,
				closed: undefined,
				color: MapAuxiliar.getFullColourHexCode(area.__typeObj!.color),
				width: this.selectedArea === area ? "3px" : "1.5px",
				fillStyle: area.__isFilled
					? this.areaEsriPatternDic.get(area.__typeObj!.pattern)
					: undefined
			};
		}
		if (area instanceof PolygonArea) {
			return {
				type: "polygon",
				center: undefined,
				radius: undefined,
				vertices: area.vertices,
				closed: area.closed,
				color: MapAuxiliar.getFullColourHexCode(area.__typeObj!.color),
				width: this.selectedArea === area ? "3px" : "1.5px",
				fillStyle: area.__isFilled
					? this.areaEsriPatternDic.get(area.__typeObj!.pattern)
					: undefined
			};
		}
		if (area instanceof Arrow) {
			return {
				type: "arrow",
				center: undefined,
				radius: undefined,
				vertices: area.geometry,
				closed: false,
				style:
					area.status === ITEM_STATE.PREDICT
						? ESRI_LINE_STYLES.DASH
						: ESRI_LINE_STYLES.SOLID,
				color: MapAuxiliar.HexColourToRGBA(
					area.color,
					area.status === ITEM_STATE.OVER ? 0.5 : 1
				),
				width: this.selectedArrow === area ? "3.5px" : "3px"
			};
		}
		return {
			type: "circle",
			center: undefined,
			radius: undefined,
			vertices: undefined,
			closed: undefined,
			color: MapAuxiliar.getFullColourHexCode(area.__typeObj!.color),
			width: this.selectedArea === area ? "3px" : "1px",
			fillStyle: area.__isFilled
				? this.areaEsriPatternDic.get(area.__typeObj!.pattern)
				: undefined
		};
	}
}
