import {
	Component,
	EventEmitter,
	Output,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges,
	OnDestroy
} from "@angular/core";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-body-poi",
	templateUrl: "app-body-poi.component.html",
	styleUrls: ["app-body-poi.css"]
})
export class BodyPoiComponent implements OnInit, OnChanges, OnDestroy {
	@Input() searchQuery: string | null = "";
	@Output() drawPoi = new EventEmitter<PoiType>();

	public selectedTool: PoiType | undefined;
	public poiTypes: Array<PoiType> = [];
	public preplannedPoiTypes: Array<PoiType> = [];

	private destroy$ = new Subject<void>();

	constructor(
		private readonly conf: ConfigurationService,
		private readonly mssg: MessagingService
	) {}

	ngOnInit(): void {
		this.resetAllTypes();
		this.conf.configurationLoaded$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.resetAllTypes());
		this.mssg.settingsUpdate$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.resetAllTypes());
		this.conf.poiTypeCreated$.pipe(takeUntil(this.destroy$)).subscribe(this.addNewType);
		this.conf.poiTypeDeleted$.pipe(takeUntil(this.destroy$)).subscribe(this.removeType);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.searchQuery) {
			this.searchArrays();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public selectPoiType(type: PoiType, event: Event): any {
		event.stopPropagation();
		this.drawPoi.emit(type);
	}

	public toggleSelectedTool(hoveredElement?: PoiType): void {
		this.selectedTool = hoveredElement || undefined;
	}

	public getPoiTypeViewBox(type: PoiType): string {
		return `0 0 ${type.icon_size.x} ${type.icon_size.y}`;
	}

	public searchArrays(): void {
		if (!this.searchQuery) this.resetAllTypes();

		if (this.searchQuery) {
			const searchValue = this.searchQuery.toLocaleUpperCase();
			const filterFunction = (type: any) =>
				type.name.toLocaleUpperCase().includes(searchValue);

			this.poiTypes = this.poiTypes.filter(filterFunction);
			this.preplannedPoiTypes = this.preplannedPoiTypes.filter(filterFunction);
		}
	}

	private resetAllTypes(): void {
		this.poiTypes = this.conf.configuration.poiTypes.filter(
			(e) => !e.is_global && !e.specialType
		);
		this.preplannedPoiTypes = this.conf.configuration.poiTypes.filter(
			(e) => e.is_global && !e.specialType
		);
	}

	private addNewType(pt: PoiType): void {
		if (this.searchQuery) {
			const searchValue = this.searchQuery.toLocaleUpperCase();
			const filterFunction = (type: any) =>
				type.name.toLocaleUpperCase().includes(searchValue);
			if (!filterFunction(pt)) return;
		}
		if (pt.is_global) this.preplannedPoiTypes.push(pt);
		else this.poiTypes.push(pt);
	}

	private removeType(pt: PoiType): void {
		const arr = pt.is_global ? this.preplannedPoiTypes : this.poiTypes;
		let idx = arr.findIndex((e) => e.id === pt.id);
		if (idx > -1) this.preplannedPoiTypes.splice(idx, 1);
	}
}
