<div class="main-black-mask" (click)="closeEvent.emit()"></div>
<div class="wrapper" [class.transform]="araDetails" [class.dl]="isDl">
	<div class="text-cross-container">
		<p class="sign-text">{{ text().SIGN_BELLOW }}</p>
		<app-white-cross (clickCb)="closeEvent.emit()"></app-white-cross>
	</div>
	<div
		data-cy="canvas-container"
		class="container  {{ drawing ? 'focus' : '' }} {{ disabled ? 'disabled' : '' }}"
	>
		<canvas
			class="inner-container"
			#canvasRef
			(mouseleave)="canvasBlur($event)"
			(mousedown)="canvasMousedown($event)"
			(mousemove)="canvasMousemove($event)"
			(mouseup)="canvasMouseup($event)"
			(touchstart)="canvasTouchdown($event)"
			(touchmove)="canvasTouchmove($event)"
			(touchend)="canvasTouchup($event)"
			width="275px"
			height="88px"
		></canvas>

		<img
			(click)="eraseAllDraw()"
			class="canvas-reset-icon"
			[class.disabled]="!drawn"
			src="resources/img/icons-ara/Undo.svg"
		/>
	</div>
	<!-- //if in ara -->
	<span *ngIf="!isDl" class="signed-by">
		{{ text().SIGNED_BY }}: {{ userName ? userName : "-" }}
	</span>

	<div *ngIf="!isDl" class="bottom-buttons-container">
		<button class="main-blue-gradient-border-button" (click)="closeEvent.emit()">
			{{ text().CANCEL }}
		</button>
		<button
			data-add-action-name="sign"
			class="main-blue-gradient-button"
			(click)="mainSave()"
			[class.disabled]="!drawn || !newDrawInput"
		>
			{{ text().SIGN }}
		</button>
	</div>
	<div *ngIf="isDl" class="bottom-actions">
		<div class="signed-by-wrapper">
			<span>{{ text().SIGNED_BY }}:</span>
			<app-blue-dropdown
				[object]="selectedUser ? selectedUser : currentUser"
				[options]="users"
				[placeholderText]="text().USER"
				[getObjectText]="userDropdownGetMainText"
				[compareSelect]="userDropdownCompareSelect"
				(itemClickCallback)="userDropdownChangeCallback($event)"
				[scrollInitialPercentage]="100"
				[separateLastOption]="currentUser"
				[canSearch]="true"
				[height]="40"
			></app-blue-dropdown>
		</div>
		<button
			data-add-action-name="sign"
			class="main-blue-gradient-button sign-button"
			(click)="mainSave()"
			[class.disabled]="!drawn || !newDrawInput"
		>
			{{ text().SIGN }}
		</button>
	</div>
</div>
