import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/dto/user/user";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { UserService } from "src/app/settings/user/user.service";

export enum SIGNATURE_STATE {
	ARA_DEFAULT = "DEFAULT",
	PENDING = "PENDING",
	DL_SIGNED = "SIGNED"
}
@Component({
	selector: "app-signature-button",
	templateUrl: "signature-button.component.html",
	styleUrls: ["signature-button.css"]
})
export class SignatureButtonComponent implements OnInit, OnDestroy {
	@Input() disabled: boolean = false;
	@Input() pendingToSign: boolean = false;
	@Input() ready: boolean = false;
	@Input() name: string = "";

	@Input() isInDecisionLog: boolean = false;
	@Input() araDetails: boolean = false;
	@Input() araDetailsStyles: boolean = false;

	@Output() openEvent = new EventEmitter<Event>();
	@Output() confirm = new EventEmitter<File>();
	@Output() userSelectEvent = new EventEmitter<User>();
	@Output() signatureCanceled = new EventEmitter<void>();

	@Input() signature: File | undefined;
	@Input() selectedUser: User | undefined;
	public text: () => LocaleMap;
	public showCanvasModal: boolean = false;
	public somethingDrawn: boolean = false;
	public signatureState: SIGNATURE_STATE = SIGNATURE_STATE.ARA_DEFAULT;
	public SIGNATURE_STATE = SIGNATURE_STATE;
	private destroy$ = new Subject<void>();

	// eslint-disable-next-line max-len
	constructor(textProv: TextProvider, private readonly userService: UserService) {
		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		this.userService.selectedUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.selectedUser = user;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onUserSelected(evt: User): void {
		this.selectedUser = evt;
		this.userSelectEvent.emit(this.selectedUser);
		this.userService.setSelectedUser(this.selectedUser);
	}

	public openSignatureCanvas(event: Event): void {
		this.openEvent.emit(event);
	}

	public addSignature(signature: File): void {
		this.signature = signature;
		this.confirm.emit(signature);
		this.showCanvasModal = false;
	}

	public toggleSignatureCanvas(): boolean | void {
		this.showCanvasModal = !this.showCanvasModal;
	}

	public readonly isSomethingDrawn: (evt: boolean) => void = (evt) => {
		this.somethingDrawn = evt;
	};

	public handleCancel(): void {
		this.signature = undefined;
		this.somethingDrawn = false;
		this.selectedUser = undefined;
		this.ready = false;
		this.selectedUser = undefined;
		if (this.isInDecisionLog) this.pendingToSign = true;
		this.signatureCanceled.emit();
	}

	public getSignatureText(): string {
		return this.getSignatureProperties().signatureText;
	}

	public getSignatureClass(): string {
		return this.getSignatureProperties().signatureClass;
	}

	private getSignatureProperties(): { signatureText: string; signatureClass: string } {
		const state = this.getSignatureState();
		switch (state) {
			case SIGNATURE_STATE.PENDING:
				return {
					signatureText: this.text().PENDING_SIGNATURE,
					signatureClass: "sign-text"
				};
			case SIGNATURE_STATE.DL_SIGNED:
				return {
					signatureText: `${this.text().SIGNED}: `,
					signatureClass: "sign-text decision"
				};
			case SIGNATURE_STATE.ARA_DEFAULT:
			default:
				return {
					signatureText: this.text().SIGNATURE,
					signatureClass: "sign-text"
				};
		}
	}

	private getSignatureState(): SIGNATURE_STATE {
		if (this.pendingToSign) {
			return SIGNATURE_STATE.PENDING;
		} else if (this.isInDecisionLog && this.selectedUser) {
			return SIGNATURE_STATE.DL_SIGNED;
		} else {
			return SIGNATURE_STATE.ARA_DEFAULT;
		}
	}
}
