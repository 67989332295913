import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { WGSPoint } from "../map/location";
import { DTO } from "../net/dto";
import { MapItem } from "./map-item";
import { PoiType } from "./types/poi-type";
import { Stateful, ITEM_STATE } from "./status";
import { Attachable } from "./attachable";
import { UploadedFile } from "../net/uploaded-file";

export class Poi extends MapItem implements DTO, Stateful, Attachable {
	type: number;
	position: WGSPoint;
	deleted: boolean;
	is_global: boolean;
	timestamp: number;
	custom_colour: string;
	status: ITEM_STATE;

	attachmentList: UploadedFile[] = [];

	__typeObj: PoiType | undefined;

	constructor(
		id_mission: number,
		id: number,
		type: number,
		latitude: number,
		longitude: number,
		name?: string,
		description?: string,
		deleted?: boolean,
		attachment_id?: number,
		attachment_filename?: string,
		is_global?: boolean,
		custom_color?: string,
		status?: ITEM_STATE,
		timestamp?: number
	) {
		super(
			id,
			name ? name : "saving poi...",
			id_mission,
			description,
			MAP_ITEM_TYPE.POI,
			attachment_id,
			attachment_filename
		);
		this.id_incident = id_mission;
		this.position = new WGSPoint(latitude, longitude, null);
		this.type = type;
		this.is_global = is_global ? true : false;
		this.deleted = deleted ? deleted : false;
		this.attachment_id = attachment_id ? attachment_id : -1;
		this.attachment_filename = attachment_filename ? attachment_filename : "";
		this.timestamp = timestamp ? timestamp : Date.now();
		this.custom_colour = custom_color ? custom_color : "";
		this.status = status ? status : ITEM_STATE.CURRENT;
	}

	public static readonly fromJson: (json: string) => Poi = (json) => {
		var jp = JSON.parse(json);
		return new Poi(
			jp.id_mission ? jp.id_mission : jp.mission_id,
			jp.id,
			jp.type,
			jp.position.lat,
			jp.position.lng,
			jp.name,
			jp.description,
			jp.deleted,
			jp.attachment_id,
			jp.attachment_filename,
			jp.is_global,
			jp.custom_colour,
			jp.status,
			jp.timestamp
		);
	};

	public readonly getJson: Function = () => {
		// @ts-ignore
		this.attachment_id = null;
		// @ts-ignore
		this.mission_id = this.id_incident;

		// @ts-ignore
		this.position.alt = 0;

		this.position.lat = this.position.latitude;
		this.position.lng = this.position.longitude;
		return JSON.stringify(this, [
			"mission_id",
			"custom_colour",
			"status",
			"id",
			"type",
			"name",
			"description",
			"position",
			"lat",
			"lng",
			"alt",
			"deleted",
			"attachment_id",
			"attachment_filename",
			"is_global",
			"timestamp"
		]); //angular.toJson( this );
	};
}
