import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { URLMap } from "../constants/enums/url-map";

@Injectable({
	providedIn: "root"
})
export class WebSocketService {
	public socket!: WebSocket;
	public messages: Subject<string>;

	private token = "";

	// eslint-disable-next-line max-len
	constructor() {
		this.messages = new Subject<string>();
	}

	public send(message: string): void {
		if (this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(message);
		} else {
			console.error("WebSocket is not open, cannot to send message:", message);
		}
	}

	public close(): void {
		if (this.socket) {
			this.socket.close();
		}
	}

	public connectWebSocketWithToken(token: string): void {
		if (this.token === token) {
			console.warn("WebSocket already open. Reusing the existing connection.");
			return;
		} else if (this.token !== "" && this.token !== token) {
			this.close();
			this.token = token;
		}

		URLMap.parseSettingsURL();
		const url = URLMap.main_url;

		if (!url) {
			return;
		}

		const wsBaseUrl = url.replace(/^http:/, "ws:").replace(/^https:/, "wss:");
		const wsUrl = `${wsBaseUrl}/ws?token=${token}`;

		if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
			console.warn("WebSocket already open. Reusing the existing connection.");
			return;
		}
		this.socket = new WebSocket(wsUrl);

		this.socket.onmessage = (event) => {
			this.messages.next(event.data);
		};

		this.socket.onerror = (error) => {
			console.error("WebSocket error:", error);
		};

		this.socket.onclose = () => {
			setTimeout(() => this.connectWebSocketWithToken(this.token), 5000);
		};
	}
}
