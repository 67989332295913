<div class="main-container" data-cy="textFilter">
	<div class="mssg-types-box" id="filter-menu">
		<div
			class="mssg-labels"
			*ngFor="let option of filters"
			(click)="toggleFilters(option); $event.stopPropagation()"
			[attr.data-cy]="'filter-' + option"
		>
			<app-blue-checkbox
				[ngClass]="'check-box'"
				[check]="getCheckboxCheck(option)"
				[actionName]="'toggle-' + option"
			></app-blue-checkbox>
			<span [attr.data-cy]="'label-' + option">{{ textMap[option] }}</span>
		</div>
	</div>

	<div
		*ngIf="generalCheckboxCheck() === true"
		class="row-lower"
		(click)="clearAll(); $event.stopPropagation()"
		data-add-action-name="clear-button"
	>
		<span class="button-label">{{ text().CLEAR }}</span>
	</div>
</div>
