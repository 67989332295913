import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	forwardRef
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-simple-text-field",
	templateUrl: "simple-text-field.component.html",
	styleUrls: ["simple-text-field.css"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SimpleTextFieldComponent),
			multi: true
		}
	]
})
export class SimpleTextFieldComponent implements OnInit {
	@Input() data: string | number = "";
	@Input() maxLength: number | undefined;
	@Input() minValue: number | undefined;
	@Input() maxValue: number | undefined;
	@Input() decimal: number | undefined;
	@Input() isDisabled: boolean = false;
	@Input() withBorders: boolean = true;
	@Input() withError: boolean = false;
	@Input() padding?: string;
	@Input() fontSize?: string;
	@Input() lineHeight?: string;
	@Input() required: boolean = false;
	@Input() placeholderText: string | undefined;
	@Input() label: string | undefined;
	@Input() labelBackground: string | undefined;
	@Input() type: "text" | "number" | "password" | "date" = "text";
	@Input() margin: number = 8;
	@Input() textAlign?: string = "left";

	@Input() errorText?: string = "";

	@Output() dataChange = new EventEmitter<string>();
	@Output() blurred = new EventEmitter<void>();

	@ViewChild("input") inputElement!: ElementRef;

	public isFocused: boolean = false;
	public isPassword: boolean = false;

	private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
	private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home"];

	onTouched: () => void = () => {};

	ngOnInit(): void {
		this.isPassword = this.type === "password";
	}

	public onKeyDown(event: KeyboardEvent): void {
		if (this.isNumberData()) {
			let currentValue: string = this.inputElement.nativeElement.value;
			if (this.specialKeys.indexOf(event.key) !== -1) return;
			let newValue: string | number = currentValue.concat(event.key);
			if (newValue && !String(newValue).match(this.regex)) event.preventDefault();
		}
	}

	public onChange(newValue: string): void {
		let data: string | number = newValue;
		if (this.isNumberData()) {
			if (this.minValue && Number(newValue) < this.minValue) data = this.minValue;
			if (this.maxValue && Number(newValue) > this.maxValue) data = this.maxValue;
			if (this.maxLength && newValue.length > this.maxLength)
				data = newValue.substring(0, this.maxLength);
			if (
				this.decimal &&
				newValue.includes(".") &&
				newValue.split(".")[1].length > this.decimal
			)
				data = newValue.substring(0, newValue.split(".")[0].length + this.decimal + 1);
		}
		this.data = data;
		this.inputElement.nativeElement.value = data;
		this.dataChange.emit(data as string);
		this.onTouched();
	}

	blur = (): void => {
		this.blurred.emit();
		this.isFocused = false;
	};

	writeValue(value: string | number): void {
		this.data = value;
		if (this.inputElement) {
			this.inputElement.nativeElement.value = value;
		}
	}

	registerOnChange(fn: (value: string | number) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public readonly isFilled: () => boolean = () => {
		return this.data
			? (typeof this.data === "string" && this.data.length > 0) ||
					typeof this.data === "number"
			: false;
	};

	public readonly isWithError: () => boolean = () => {
		return this.withError;
	};

	public readonly isNumberData = (): boolean => {
		return this.type === "number";
	};

	public readonly isPasswordData = (): boolean => {
		return this.type === "password";
	};

	public readonly changeType = (type: "text" | "number" | "password" | "date"): void => {
		this.type = type;
	};

	public readonly setWarning = (value: boolean): void => {
		this.withError = value;
	};
}
