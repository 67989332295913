<div class="node-wrapper">
	<div data-cy="csSector" class="node-container">
		<div data-cy="csSectorTitle" class="title" [ngStyle]="setTitleBackground()">
			<div *ngIf="this.object.id_area !== -1" class="sector-icon-container">
				<img class="sector-icon" [style.backgroundColor]="setFlagBackground()" />
			</div>

			<span *ngIf="!editingSector">{{
				this.object.name ? this.object.name : text().SECTOR
			}}</span>
			<app-simple-text-field
				class="name-input"
				*ngIf="editingSector"
				[type]="'text'"
				[data]="object.name"
				(dataChange)="setSectorName($event)"
				[placeholderText]="text().TITLE"
				[maxLength]="20"
				[padding]="'4px'"
				[focus]="isFocused"
				[textAlign]="'center'"
				[isDisabled]="disable || isIncidentClosed()"
			>
			</app-simple-text-field>
			<app-blue-cross
				*ngIf="!mapMode && !disable"
				class="menu-button {{ isMenuOpen() ? 'open' : '' }}"
				(clickCb)="openMenu($event)"
				[open]="isMenuOpen"
				[isInCommandSector]="true"
				[shouldShow]="!isIncidentClosed()"
			>
			</app-blue-cross>
			<app-blue-cross
				class="menu-button"
				*ngIf="mapMode"
				[open]="true"
				(clickCb)="cancel.emit()"
				(touchend)="cancel.emit()"
				[isInCommandSector]="true"
			></app-blue-cross>
		</div>
		<div class="sector-body" [style.pointerEvents]="mapMode ? 'none' : 'all'">
			<div class="sector-top" style="flex-direction: column">
				<div
					class="span-div"
					[attr.sectorId]="object.id"
					dropzone="sectorCommander"
					[class.dragging]="draggingPersonnel"
					[class.hide]="dragging"
					style="margin-bottom: 8px"
				>
					<span class="sc-label">S.C : </span>
					<span *ngIf="object.id_resource_comm === -1" class="drag-here-label"
						>({{ this.text().DRAG_HERE }})</span
					>
					<div
						*ngIf="object.id_resource_comm > -1"
						[style.pointerEvents]="disable ? 'none' : 'all'"
						class="agent-comm-label"
						appDroppable
						[disabled]="disable"
						[dragInfo]="object.id_resource_comm"
						(dragStart)="onResourceDragStart($event)"
						(dragCancel)="onResourceDragCancel($event)"
						(dragEnd)="onResourceDragEnd($event)"
					>
						{{ object.__sectorCommander?.name }}
					</div>
				</div>
				<div
					class="span-div"
					[attr.sectorId]="object.id"
					dropzone="safetyOfficer"
					[class.dragging]="draggingPersonnel"
					[class.hide]="dragging"
				>
					<span class="sc-label">S.O : </span>
					<span *ngIf="object.id_safety_officer === -1" class="drag-here-label"
						>({{ this.text().DRAG_HERE }})</span
					>
					<div
						*ngIf="object.id_safety_officer > -1"
						[style.pointerEvents]="disable ? 'none' : 'all'"
						class="agent-comm-label"
						appDroppable
						[disabled]="disable"
						[dragInfo]="object.id_safety_officer"
						(dragStart)="onResourceDragStart($event)"
						(dragCancel)="onResourceDragCancel($event)"
						(dragEnd)="onResourceDragEnd($event)"
					>
						{{ object.__safetyOfficer?.name }}
					</div>
				</div>
			</div>
			<div class="sector-tasks">
				<app-simple-text-box
					[isDisabled]="shouldDisable() || disable"
					[data]="object.tasks"
					[type]="'text'"
					(dataChange)="setSectorTasks($event)"
					[placeholderText]="text().NOTES"
					[label]="dragging || draggingPersonnel ? '' : text().NOTES"
					[labelBackground]="'#051c2a'"
					[maxHeight]="96"
					[padding]="16"
					[paddingRight]="6"
				>
				</app-simple-text-box>
			</div>
			<div class="sector-resources" [style.pointerEvents]="disable ? 'none' : 'all'">
				<span>{{ text().RESOURCES.toUpperCase() }} : </span>
				<div
					class="resource-list"
					[attr.sectorId]="object.id"
					dropzone="sectorResources"
					[class.dragging]="dragging"
				>
					<div
						*ngIf="!object.resources || object.resources.length === 0"
						class="drag-here-label"
					>
						({{ this.text().DRAG_HERE }})
					</div>
					<div
						*ngFor="let resource of object.resources; let j = index"
						class="resource-item"
						appDroppable
						[disabled]="disable"
						[dragInfo]="resource.id_resource"
						(dragStart)="onResourceDragStart($event)"
						(dragCancel)="onResourceDragCancel($event)"
						(dragEnd)="onResourceDragEnd($event)"
					>
						<div *ngIf="resource.__object && !resource.__object!.is_appliance">
							<div class="resource-name-container">
								<div
									class="status-display circle"
									appSetStyle
									[ngStyle]="setStatusBackground(resource)"
								></div>
								<div class="personnel-name">{{ resource.__name }}</div>
							</div>

							<app-skills-display
								*ngIf="resource.__object.skills"
								[resource]="resource.__object"
								[size]="20"
								[disabled]="isIncidentClosed()"
								[shouldShowTooltip]="false"
								[shownSkillIndexes]="shownSkillIndexes"
								(toggleSkillsOverflowEvent)="toggleSkills(j)"
							>
							</app-skills-display>
						</div>
						<div
							*ngIf="resource.__object && resource.__object!.is_appliance"
							class="appliance"
							[attr.applianceid]="resource.id_resource"
							dropzone="applianceResources"
						>
							<div class="resource-name-container" [class.drag]="draggingPersonnel">
								<div
									class="status-display"
									appSetStyle
									[ngStyle]="setStatusBackground(resource)"
								></div>
								<div>{{ resource.__name }}</div>
							</div>
							<div *ngIf="!isIncidentClosed()" class="personnel-list">
								<div
									class="resource-name-container"
									*ngFor="let person of resource.__personnel; let k = index"
									appDroppable
									[disabled]="disable"
									[dragInfo]="person.id"
									(dragStart)="onResourceDragStart($event)"
									(dragCancel)="onResourceDragCancel($event)"
									(dragEnd)="onResourceDragEnd($event)"
								>
									<div class="personnel-name-container">
										<div
											class="status-display circle"
											appSetStyle
											[ngStyle]="setStatusBackground(person)"
										></div>

										<span
											*ngIf="isOic(resource.__object, person)"
											class="oic-icon"
											>OIC</span
										>

										<div
											[ngClass]="{
												'oic-personnel': isOic(resource.__object, person),
												'personnel-name': !isOic(resource.__object, person)
											}"
										>
											{{ person.name }}
										</div>
									</div>

									<app-skills-display
										*ngIf="shouldDisplaySkills(person, resource.__object)"
										[resource]="person"
										[disabled]="isIncidentClosed()"
										[shouldShowTooltip]="false"
										[size]="20"
										[shownSkillIndexes]="shownSkillIndexes"
										(toggleSkillsOverflowEvent)="toggleSkills(k)"
									>
									</app-skills-display>
								</div>
							</div>

							<div
								*ngIf="isIncidentClosed() && resource.__object"
								class="personnel-list"
							>
								<div
									class="resource-name-container"
									*ngFor="
										let person of filterClosedPersonnelList(resource.__object);
										let k = index
									"
									appDroppable
									[disabled]="disable"
									[dragInfo]="person.id"
									(dragStart)="onResourceDragStart($event)"
									(dragCancel)="onResourceDragCancel($event)"
									(dragEnd)="onResourceDragEnd($event)"
								>
									<div class="personnel-name-container">
										<div
											class="status-display circle"
											appSetStyle
											[ngStyle]="setStatusBackground(person)"
										></div>

										<span
											*ngIf="isOic(resource.__object, person)"
											class="oic-icon"
											>OIC</span
										>

										<div
											[ngClass]="{
												'oic-personnel': isOic(resource.__object, person),
												'personnel-name': !isOic(resource.__object, person)
											}"
										>
											{{ person.name }}
										</div>
									</div>

									<app-skills-display
										*ngIf="shouldDisplaySkills(person, resource.__object)"
										[resource]="person"
										[size]="20"
										[disabled]="isIncidentClosed()"
										[shouldShowTooltip]="false"
										[shownSkillIndexes]="shownSkillIndexes"
										(toggleSkillsOverflowEvent)="toggleSkills(k)"
									>
									</app-skills-display>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="object.id_area !== -1 && object.__area"
			data-add-action-name="csGoTo"
			class="sector-footer footer-button"
			(click)="goToArea()"
		>
			<img src="resources/img/location-focus.svg" class="focus-icon" />
			<span *ngIf="!mapMode" class="footer-button-text">
				{{ text().GO_TO }}
			</span>
		</div>
		<div
			*ngIf="object.id_area === -1 && !isIncidentClosed() && !disable"
			data-add-action-name="cs-assign-area"
			class="sector-footer footer-button"
			[class.disabled]="isIncidentClosed()"
			(click)="onMainUnassignedClick(); $event.stopPropagation()"
		>
			<span *ngIf="!mapMode" class="footer-button-text">
				{{ text().ASSIGN_AREA }}
			</span>
		</div>
	</div>

	<!-- EXT. MENU -->
	<div data-cy="csExtendedMenu" class="extended-menu" *ngIf="isMenuOpen()">
		<div class="title" appSetStyle [ngStyle]="setTitleBackground()"></div>
		<div data-add-action-name="csMenuEdit" class="option" (click)="toggleEditable(object)">
			{{ text().EDIT }}
		</div>
		<div
			data-add-action-name="csMenuReassign"
			class="option"
			*ngIf="object.id_area > -1"
			(click)="$event.stopPropagation(); onMainUnassignedClick()"
		>
			{{ text().REASSIGN }}
		</div>
		<div data-add-action-name="csMenuDelete" class="option" (click)="removeSector.emit()">
			{{ text().DELETE }}
		</div>
	</div>
</div>
