<div
	data-cy="csCommander"
	*ngIf="object.id === -1"
	class="node-container {{ disable ? '' : 'clickable' }} "
	data-add-action-name="csAssignCommander"
	(click)="disable || onMainUnassignedClick(); $event.stopPropagation()"
>
	<div class="title">{{ text().INCIDENT_COMMANDER }}</div>
	<div class="body">
		<div class="unassigned">
			<div>
				{{ this.text().UNASSIGNED }}
			</div>
			<div *ngIf="!disable">
				{{ this.text().CLICK_TO_ASSIGN }}
			</div>
		</div>
	</div>
</div>

<div class="node-wrapper">
	<div data-cy="csCommander" *ngIf="object.id !== -1" class="node-container">
		<div data-cy="csCommanderTitle" class="title">
			<span *ngIf="!editable">{{
				object.title ? object.title : text().INCIDENT_COMMANDER
			}}</span>
			<app-simple-text-field
				class="name-input"
				*ngIf="editable"
				[data]="object.title"
				[type]="'text'"
				[padding]="'4px'"
				(dataChange)="setNewCommanderTitle($event)"
				[placeholderText]="text().INCIDENT_COMMANDER"
				[maxLength]="20"
				[textAlign]="'center'"
				[isDisabled]="disable"
			></app-simple-text-field>
			<app-blue-cross
				*ngIf="!disable"
				class="menu-button {{ isMenuOpen() ? 'open' : '' }}"
				(clickCb)="openMenu($event)"
				[open]="isMenuOpen"
				[isInCommandSector]="true"
				[shouldShow]="!isIncidentClosed()"
			>
			</app-blue-cross>
		</div>
		<div data-cy="csCommanderBody" class="body">
			<div class="assigned">
				<div
					*ngIf="shouldShowDropZone()"
					class="name-text"
					dropzone="incidentCommander"
					[class.dragging]="draggingPersonnel"
					[class.hide]="dragging"
				>
					<span class="drag-here-label"> ({{ this.text().DRAG_HERE }}) </span>
					<div
						*ngIf="object.id_resource !== -1"
						class="resource-item dropzone-adapted"
						appDroppable
						[dragInfo]="object.id_resource"
						(dragStart)="onResourceDragStart($event)"
						(dragCancel)="onResourceDragCancel($event)"
						(dragEnd)="onResourceDragEnd($event)"
					>
						<div class="resource-name-container">
							<div
								class="status-display circle"
								appSetStyle
								[ngStyle]="setStatusBackground(getResource())"
							></div>
							<div>{{ setNewCommanderName() }}</div>
						</div>

						<app-skills-display
							[resource]="getResource()"
							[size]="20"
							[shouldShowTooltip]="false"
							[overflowSkillsVisible]="areOverflowSkillsVisible"
							(toggleSkillsOverflowEvent)="toggleSkills()"
						>
						</app-skills-display>
					</div>
				</div>
				<div
					*ngIf="!shouldShowDropZone()"
					class="resource-item"
					appDroppable
					[dragInfo]="object.id_resource"
					[disabled]="disable"
					(dragStart)="onResourceDragStart($event)"
					(dragCancel)="onResourceDragCancel($event)"
					(dragEnd)="onDragEnd($event)"
				>
					<div class="resource-name-container">
						<div
							class="status-display circle"
							appSetStyle
							[ngStyle]="setStatusBackground(getResource())"
						></div>
						<div>{{ getResource()!.name }}</div>
					</div>

					<app-skills-display
						[resource]="getResource()"
						[overflowSkillsVisible]="areOverflowSkillsVisible"
						[shouldShowTooltip]="false"
						[size]="20"
						(toggleSkillsOverflowEvent)="toggleSkills()"
					>
					</app-skills-display>
				</div>
				<div
					data-add-action-name="csCommanderGoTo"
					class="sector-footer footer-button"
					(click)="onButtonClick()"
				>
					<img src="resources/img/location-focus.svg" class="focus-icon" />
					<span class="footer-button-text">
						{{ text().GO_TO }}
					</span>
				</div>
			</div>
		</div>
	</div>
	<div data-cy="csMenuEdit" class="extended-menu" *ngIf="isMenuOpen()">
		<div class="title"></div>
		<div data-add-action-name="csMenuEdit" class="option" (click)="toggleEditable()">
			{{ text().EDIT }}
		</div>
	</div>

	<button
		data-add-action-name="csAddSupport"
		*ngIf="object.id !== -1 && !disable"
		class="cross-button add-support"
		[class.disabled]="disabled"
		[disabled]="disabled"
		(click)="clickAndAddSupport()"
	>
		<img src="resources/img/plus-sign.png" width="20px" height="20px" />
	</button>

	<button
		data-add-action-name="csAddSector"
		*ngIf="object.id !== -1 && !disable"
		class="cross-button add-sector"
		[class.disabled]="disabled"
		[disabled]="disabled"
		(click)="clickAndAddSector()"
	>
		<img src="resources/img/plus-sign.png" width="20px" height="20px" />
	</button>
</div>
