import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { KmlComponent } from "src/app/settings/types/components/kml/kml.component";
import { MapItem } from "../items/map-item";
import { BufferItem } from "../replay/buffer-item";

export class Change {
	last_update: number;
	id: number;
	datatype: CHANGE_TYPE;
	id_data: number | undefined;
	id_mission: number;

	__time_percent: number | undefined;
	__object: BufferItem | undefined;
	constructor(id: number, last_update: number, datatype: CHANGE_TYPE, id_data?: number, id_mission?: number) {
		this.id = id;
		this.last_update = last_update;
		this.datatype = datatype;
		this.id_data = id_data;
		this.id_mission = id_mission ?? -1;
	}

	public static fromJson: Function = (json: string) => {
		var lc = JSON.parse(json);

		const ts = typeof lc.timestamp !== "number" ? new Date(lc.timestamp.replace("Z", "") + "Z").getTime() : lc.timestamp;
		return new Change(lc.id, ts, lc.datatype, (lc.element_id = lc.element_id ? lc.element_id : lc.data_id), lc.mission_id);
	};
}
export enum CHANGE_TYPE {
	CS_RESOURCE = "icsresource",
	APPL_RELATION = "appliancerelation",
	POI = "poi",
	AREA = "area",
	RESOURCE = "agent",
	UNSPECIFIED = "unspecified"
}
