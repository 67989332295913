import {
	Component,
	EventEmitter,
	Input,
	Output,
	OnInit,
	OnChanges,
	SimpleChanges
} from "@angular/core";
import { AreaType } from "src/app/dto/items/types/area-type";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { TOOLS } from "src/app/incident/map/components/map-tools/map-tools.component";
@Component({
	selector: "app-map-item-dialog",
	templateUrl: "app-map-item-dialog.component.html",
	styleUrls: ["app-map-item-dialog.css"]
})
export class MapItemDialogComponent implements OnInit, OnChanges {
	@Input() toolType!: TOOLS.AREA | TOOLS.POI;
	@Input() open: boolean = false;

	@Output() drawPoi = new EventEmitter<PoiType>();
	@Output() drawPolygon = new EventEmitter<AreaType>();
	@Output() drawCircle = new EventEmitter<AreaType>();

	public readonly text: () => LocaleMap;
	public toolTypes = TOOLS;

	public searchQuery: string = "";

	public visible: boolean = true;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		if (!this.toolType) {
			return console.warn("MapItemDialogComponent initialized without valid tool");
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.open && !changes.open.currentValue) {
			setTimeout(() => (this.visible = false), 510);
		} else {
			this.visible = true;
		}
	}

	public emitDrawPoi(type: PoiType): void {
		return this.drawPoi.emit(type);
	}

	public emitDrawCircle(event: AreaType): void {
		return this.drawCircle.emit(event);
	}

	public emitDrawPolygon(event: AreaType): void {
		return this.drawPolygon.emit(event);
	}

	public searchArrays(value: string): void {
		this.searchQuery = value;
	}
}
