import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { EsriMarker } from "src/app/incident/map/components/map/esri/components/graphics/esri-marker/esri-marker.interface";
import { GMapMarker } from "../map/gmap-marker";
import { UploadedFile } from "../net/uploaded-file";
import { AreaType } from "./types/area-type";
import { PoiType } from "./types/poi-type";
import { ResourceType } from "./types/resource-type";

/**  class to store common properties amongst pois/areas/overlays, some "static" like id_mission and name
 * and others usually initialized and modified on runtime shared amongst objects drawn on a map
 */
export class MapItem implements Named {
	id: number;

	name: string;
	description: string;
	id_incident: number;

	attachment_id: number;
	attachment_filename: string;

	itemType: MAP_ITEM_TYPE | null;
	__typeObj: PoiType | AreaType | ResourceType | undefined;
	__shape: any;
	__marker: GMapMarker | EsriMarker | undefined;
	__markerAttachment: any;
	__showRadialMenu: boolean | undefined;
	visible: boolean;
	deleted: boolean;

	constructor(
		id?: number,
		name?: string,
		id_mission?: number,
		description?: string,
		itemType?: MAP_ITEM_TYPE | null,
		attachment_id?: number,
		attachment_filename?: string,
		visible?: boolean,
		deleted?: boolean
	) {
		this.id = id ? id : -1;
		this.name = name ? name : "MapItem-" + (id && id !== -1 ? id : Math.floor(Math.random() * 1000));
		this.description = description ? description : "";
		this.id_incident = id_mission ? id_mission : -1;
		this.itemType = itemType ? itemType : null;
		this.attachment_id = attachment_id ? attachment_id : -1;
		this.attachment_filename = attachment_filename ? attachment_filename : "";
		this.visible = visible ? visible : true;
		this.deleted = deleted ? deleted : false;
	}
}

export interface Named {
	name: string;
	description: string;
}
