<div class="main-black-mask" (click)="closeDialog(); $event.stopPropagation()"></div>
<div class="main-alert-box risk-chips">
	<div class="header header-chips left-align">{{ text().RISK_INFORMATION }}</div>
	<div class="cancel-button">
		<app-white-cross (click)="closeDialog()"> </app-white-cross>
	</div>
	<div class="text text-chips left-align">{{ text().RISK_INFORMATION_CHIPS }}</div>
	<div
		class="ara-risk-information-chips-container"
		(click)="$event.stopPropagation(); clickOutsideChip()"
	>
		<div class="ara-risk-information-chips">
			<div *ngIf="riskInfoTypes.length > 0">
				<div
					data-add-action-name="ara-riskinfo-chip-all"
					(click)="selectAllRisks(); $event.stopPropagation()"
					class="ara-riskinfo-chip {{ areAllRisksSelected() ? 'selected' : '' }}"
				>
					<span
						><b>{{ text().SELECT_ALL }}</b></span
					>
				</div>
			</div>
			<div *ngFor="let chipInfo of riskInfoTypes">
				<div
					data-cy="ara-riskinfo-chip"
					class="ara-riskinfo-chip {{
						getRiskInformationCheckFunction(chipInfo) ? 'selected' : ''
					}}"
					(click)="$event.stopPropagation(); selectRiskInformation(chipInfo)"
				>
					<div
						[attr.data-add-action-name]="'riskinfo-chip-' + chipInfo.name"
						(click)="$event.stopPropagation(); selectRiskInformation(chipInfo)"
						class="touch-target-select"
					>
						<div *ngIf="getRiskInformationCheckFunction(chipInfo)">
							<img
								src="resources/img/Blue-check.svg"
								class="blue-check"
								alt="Close"
							/>
						</div>
						<span>{{ chipInfo.name }}</span>
					</div>

					<div class="touch-target-delete">
						<div
							data-add-action-name="delete-riskinfo"
							class="delete innactive ara-risk-delete-chip {{
								disabled ? 'disabled' : ''
							}}"
							(click)="$event.stopPropagation(); deleteRiskInfoDialog(chipInfo)"
						>
							<img src="resources/img/chip-delete.svg" />
						</div>
					</div>
				</div>
			</div>
			<!-- NEW CREATION CHIP -->
			<div
				class="item active ara-riskinfo-chip"
				*ngIf="makingNewItem"
				(click)="$event.stopPropagation()"
				appDetectClickOut
				(callback)="commitNewRiskInformation()"
			>
				<input
					#inputNewItem
					data-add-action-name="new-risk-information-input"
					appInstantFocus
					[ngStyle]="{ 'width.px': width }"
					class="input-new-chip"
					(keydown)="newItemKeydownController($event, 'RISK_INFORMATION')"
					[(ngModel)]="newItem"
				/>
				<div
					*ngIf="newItem?.length! > 0"
					(click)="$event.stopPropagation(); stopCreatingNewValue()"
					class="delete ara-risk-delete-chip"
				>
					<img src="resources/img/chip-delete.svg" alt="" />
				</div>
			</div>
			<span #inputHiddenText id="inputHiddenText" class="hidden-input">{{ newItem }}</span>

			<!-- NEW CREATION BUTTON -->
			<div
				*ngIf="!makingNewItem"
				class="ara-riskinfo-chip add {{ disabled ? 'disabled' : '' }}"
				(click)="$event.stopPropagation(); openNewItem('RISK_INFORMATION')"
			>
				<button
					data-add-action-name="add-new-risk-information"
					class="main-cross-button riskinfo-chip-cross height-auto"
				>
					<img class="add-button" alt="" />
				</button>
			</div>
		</div>
	</div>
	<div class="footer">
		<button
			class="main-blue-gradient-border-button margin-right-12px ara-cancel-button-popup"
			(click)="closeDialog()"
		>
			{{ text().CANCEL }}
		</button>
		<button
			data-add-action-name="confirm-ri"
			[disabled]="!selectedRiskInformations.length && !riskInfoRelations.length"
			class="main-blue-gradient-button main-wide-button ara-cancel-button-popup display-flex"
			(click)="confirm()"
		>
			{{ text().CONFIRM }}
		</button>
	</div>
</div>
