import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { UserService } from "src/app/settings/user/user.service";
import { Decision } from "src/app/dto/decision/decision";
import { DecisionFile } from "src/app/dto/decision/decision-file";
import { DTOArray } from "src/app/dto/net/dto-array";
import { NOTIFICATION_TYPE } from "src/app/dto/notification/notification";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { FormsService } from "../forms.service";
import { LoginService } from "src/app/login/login.service";
import { FORM_SCREENS } from "../screens.enum";
import { IncidentService } from "src/app/incident/incident.service";

@Injectable({
	providedIn: "root"
})
export class DecisionLogService {
	public newDecisions: number = 0;
	public readonly text: () => LocaleMap;

	public Decisions: Array<Decision> = new Array();
	private decisionFiles: Array<DecisionFile> = new Array();

	private readonly wreq: WebRequestFactory;
	private readonly main: MainService;
	private readonly mssg: MessagingService;
	private readonly userServ: UserService;
	private readonly jesip: FormsService;
	private readonly ems: IncidentService;
	private readonly loginService: LoginService;

	constructor(wreq: WebRequestFactory, main: MainService, mssg: MessagingService, userService: UserService, textProv: TextProvider, fs: FormsService, ems: IncidentService, login: LoginService) {
		this.wreq = wreq;
		this.main = main;
		this.mssg = mssg;
		this.userServ = userService;
		this.text = textProv.getStringMap;
		this.jesip = fs;
		this.ems = ems;
		this.loginService = login;
		this.ems.loadMission$.subscribe(() => this.updateDecisions(true));
	}

	public readonly unload: Function = () => {
		this.Decisions.length = 0;
		this.decisionFiles.length = 0;
	};

	public readonly updateDecisions: (refresh_ui_indicator: boolean, id_m?: number) => Promise<boolean> = async (refresh_ui_indicator, id_m?) => {
		const id_mission = id_m ? id_m : this.main.getCurrentIncident() ? this.main.getCurrentIncident()!.id : -1;
		const jsonArray = await this.wreq.getAllDecisions(id_mission);
		if (jsonArray && jsonArray.length > 0) {
			let ans: Array<Decision> = [];
			DTOArray.UpdateFromJsonArray(ans, jsonArray, Decision);
			const jsonArray2 = await this.wreq.getDecisionFile(id_mission);
			DTOArray.UpdateFromJsonArray(this.decisionFiles, jsonArray2, DecisionFile);
			DTOArray.DeleteById(this.decisionFiles, -1);
			this.decisionFiles.forEach((decfile) => {
				decfile.format = decfile.filename.split(".")[decfile.filename.split(".").length - 1];
			});
			ans.forEach((decision) => {
				this.setDecision(decision);
				decision.files = this.decisionFiles.filter((e) => e.id_decision == decision.id);
				if (refresh_ui_indicator && !this.Decisions.find((e) => e.id == decision.id)) this.newDecisions++;
			});
			this.Decisions = ans;
			this.mssg.fire(MESSAGE_TYPE.LOAD_DECISIONS);
			return true;
		}
		return false;
	};

	public readonly updateAndNotify = (id_mission: number): void => {
		this.updateDecisions(true).then(() => {
			const currentMissionId = this.ems.getCurrentIncident()!.id;
			const lastDecision = this.Decisions.reverse()[0];
			if (lastDecision && lastDecision.name !== this.loggedUserName() && id_mission === currentMissionId) this.jesip.setNotification(NOTIFICATION_TYPE.DECISION, lastDecision.id, id_mission);
			this.jesip.jesipUpdate$.next({ form: FORM_SCREENS.DECISION, mission: id_mission });
		});
	};

	public readonly saveDecision: Function = async (decision: Decision) => {
		const json: string = await this.wreq.saveDecision(decision);
		if (json) {
			let reply: Decision;
			decision.notUploaded = false;
			reply = Decision.fromJson(json);
			reply.files = [];
			if (decision.files) {
				for (let i = 0; i < decision.files.length; i++) {
					let file = decision.files[i];
					const newDecFil = new DecisionFile(-1, reply.id, file.id_file, file.filename);
					const ans: string = await this.wreq.saveDecisionFile(newDecFil);
					const eee = DecisionFile.fromJson(ans);
					file.id_decision = eee.id_decision;
					file.id_file = eee.id_file;
					reply.files.push(file);
					this.mssg.fire(MESSAGE_TYPE.SAVE_DECISION_FILE, file);
				}
			}
			this.Decisions.push(reply);
			this.updateDecisions(false);
			this.main.setSnackbar(this.text().DECISION_LOG + " " + this.text().HAS_BEEN_SAVED);
			return reply;
		}
		return false;
	};

	public readonly getDecisionFileUpdate: Function = async (id: number) => {
		const jsonArr: Array<string> = await this.wreq.getDecisionFile();
		DTOArray.UpdateFromJsonArray(this.decisionFiles, jsonArr, DecisionFile);
		this.Decisions.forEach((decision) => {
			decision.files = this.decisionFiles.filter((e) => e.id_decision == decision.id);
		});
	};

	public readonly compareDecision: Function = (x: Decision, y: Decision) => {
		return x && y && x.id === y.id && x.name === y.name && x.type === y.type && x.description === y.description && x.result === y.result;
	};

	public readonly attachAudio: (decision: Decision, type: "content" | "result", data: Blob, fileName: string) => Promise<Decision | undefined> = async (decision, type, data, fileName) => {
		if (type.toLowerCase() !== "content" && type.toLowerCase() !== "result") {
			console.error("decision type not accepted, expected 'content' or 'result', received " + type);
			console.trace();
			return undefined;
		}
		const success: string = await this.wreq.postAudio(type, decision.id, fileName, data);
		if (!success) {
			return undefined;
		} else {
			const uploaded_info = JSON.parse(success);
			if (type === "content") {
				decision.content_audio = uploaded_info.id;
				decision.content_filename = uploaded_info.filename;
			} else {
				decision.result_audio = uploaded_info.id;
				decision.result_filename = uploaded_info.filename;
			}
			this.saveDecision(decision);
			return uploaded_info;
		}
	};

	public readonly attachFullAudio: Function = async (decision: Decision, data: any, fileName: string) => {
		const success: string = await this.wreq.postAudio("full", decision.id, fileName, data);
		if (!success) {
			return false;
		} else {
			const uploaded_info = JSON.parse(success);
			decision.full_audio = uploaded_info.id;
			decision.audio_filename = uploaded_info.filename;
			this.main.setSnackbar(this.text().MESSAGE + " " + this.text().HAS_BEEN_SAVED);
			return true;
		}
	};

	private readonly loggedUserName = (): string | undefined => {
		return this.userServ.Users.find((user) => user.id === this.loginService.user.id)?.name;
	};

	private readonly setDecision: Function = (decision: Decision) => {
		if (decision.type && decision.type != "") {
			// flags are set for old Decision objects
			var strings = decision.type.split(" ");
			if (strings.find((e) => e === "Decision" || e === "Decisión")) decision.is_decision = true;
			if (strings.find((e) => e === "Event" || e === "Evento")) decision.is_event = true;
			if (strings.find((e) => e === "Message" || e === "Medios")) decision.is_message = true;
		}
		decision.__signName = this.userServ.Users.find((e) => e.id === decision.id_fill_signature)?.name;
	};
}
