import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { IncidentType } from "src/app/dto/items/types/event-type";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";

class ListItem {
	public name: string = "";
	public color: string = "";
	public checked: boolean = false;

	constructor(name: string, color: string, checked: boolean) {
		this.name = name;
		this.color = color;
		this.checked = checked;
	}

	public readonly isTypeChecked = (): boolean => {
		return this.checked;
	};
}

interface Filter { sortDirection: string; filterValues: Array<string> }

@Component({
	selector: "app-incident-type-filter",
	templateUrl: "incident-type-filter.component.html",
	styleUrls: ["incident-type-filter.css"]
})
export class incidentTypeFilterComponent implements OnInit {
	@Input() filters: { [key: string]: any } = {};

	// Variable used to return the filter to the parent component
	@Output() applyFilterEmit = new EventEmitter<any>();
	@Output() closeModalEmit = new EventEmitter<boolean>();
	
	public readonly text: () => LocaleMap;
	public eventTypes: Array<IncidentType> = [];
	public incidentTypes: Array<ListItem> = [];
	public query: string = "";

	public filter: Filter = {
		sortDirection: "",
		filterValues: []
	};

	private readonly mssg: MessagingService;
	private readonly conf: ConfigurationService;

	constructor(conf: ConfigurationService, mssg: MessagingService, textProv: TextProvider) {
		this.conf = conf;
		this.mssg = mssg;
		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		this.mssg.registerListener(
			MESSAGE_TYPE.LOAD_CONFIGURATION,
			() => {
				this.eventTypes = this.conf.configuration.incidentTypes;
			},
			true
		);

		if (this.filters["type"]) this.filter = JSON.parse(JSON.stringify(this.filters["type"]));

		this.eventTypes.forEach((eventType: IncidentType) => {
			let name = eventType.name;
			let color = eventType.color;
			let checked = false;
			this.filter.filterValues.includes(name) ? (checked = true) : null;
			let item = new ListItem(name, color, checked);
			this.incidentTypes.push(item);
		});
	}

	public readonly setSortDirection = (direction: string): void => {
		this.filter.sortDirection = direction;
	};

	public readonly addValueToFilter = (filterValue: string, mode: string = ""): void => {
		const itemIndex = this.incidentTypes.map((e) => e.name).indexOf(filterValue);
		if (mode === "selectAll") {
			this.incidentTypes[itemIndex].checked = true;
			!this.filter.filterValues.includes(filterValue) && this.filter.filterValues.push(filterValue);
		} else {
			this.incidentTypes[itemIndex].checked = !this.incidentTypes[itemIndex].checked;
			this.filter.filterValues.includes(filterValue) ? this.filter.filterValues.splice(this.filter.filterValues.indexOf(filterValue), 1) : this.filter.filterValues.push(filterValue);
		}
	};

	public readonly selectAll = (): void => {
		this.eventTypes.forEach((eventType) => {
			this.addValueToFilter(eventType.name, "selectAll");
		});
	};

	public readonly clearAll = (): void => {
		this.filter.sortDirection = "";
		this.filter.filterValues = [];
		this.incidentTypes.forEach((incidentType) => (incidentType.checked = false));
	};

	public readonly filterFunc = (item: { [id: string]: string }): boolean => {
		if (this.query && this.query.length) {
			return item["name"].toUpperCase().includes(this.query.toUpperCase());
		}
		return true;
	};

	public applyFilter = (value: Filter): void => {
		this.applyFilterEmit.emit(value);
		this.closeModal();
	};

	public closeModal = (): void => {
		return this.closeModalEmit.emit(true);
	};
}
