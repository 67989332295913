import { Injectable } from "@angular/core";
import { ResourceType } from "src/app/dto/items/types/resource-type";
import { Area } from "src/app/dto/items/area";
import { AreaType } from "src/app/dto/items/types/area-type";
import { Camera } from "src/app/dto/resources/camera";
import { CircleArea } from "src/app/dto/items/circle-area";
import { Configuration } from "src/app/dto/user/configuration";
import { ControlMeasure } from "src/app/dto/ara/control-measure";
import { CSCommander } from "src/app/dto/command-structure/cs-commander";
import { CSSectorResourceAssignation } from "src/app/dto/command-structure/cs-resource";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { CSSupport } from "src/app/dto/command-structure/cs-support";
import { Drone } from "src/app/dto/resources/drone";

import { IncidentType } from "src/app/dto/items/types/event-type";
import { Floor } from "src/app/dto/map/floor";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { Hardware } from "src/app/dto/resources/hardware";
import { Hazard } from "src/app/dto/ara/hazard";
import { Incident } from "src/app/dto/items/incident";
import { Kml } from "src/app/dto/items/kml";
import { Level } from "src/app/dto/user/level";
import { Overlay } from "src/app/dto/items/overlay";
import { Poi } from "src/app/dto/items/poi";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceLogEntry } from "src/app/dto/resources/resource-log-entry";
import { RiskInformation } from "src/app/dto/ara/risk-information";
import { RiskInformationRelation } from "src/app/dto/ara/risk-information-relation";
import { Scenario } from "src/app/dto/ara/scenario";
import { ResourceState } from "src/app/dto/resources/state";
import { User } from "src/app/dto/user/user";
import { WSRequest } from "src/app/dto/net/wsrequest";
import { ConstantsService } from "src/app/global/constants/constants.service";
import { WebRequestsService } from "./web-request.service";
import { ControlMeasureRelation } from "src/app/dto/ara/control-measure-relation";
import { SubControlMeasure } from "src/app/dto/ara/sub-control-measure";
import { SubControlMeasureRelation } from "src/app/dto/ara/sub-control-measure-relation";
import { RiskType } from "src/app/dto/ara/risk-type";
import { Ara } from "src/app/dto/ara/ara";
import { GPSDevice } from "src/app/dto/resources/gps-device";
import { DecisionFile } from "src/app/dto/decision/decision-file";
import { ApplianceRelation } from "src/app/dto/resources/appliance-relation";
import { SkillUserRelation } from "src/app/dto/user/skill-user-relation";
import { Change } from "src/app/dto/net/change";
import { BUFFER_ITEM_TYPE, ReplayBuffer } from "src/app/dto/replay/replay-buffer";
import { Decision } from "src/app/dto/decision/decision";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { URLMap } from "src/app/global/constants/enums/url-map";
import { SHAPE_TYPE } from "src/app/dto/items/shape-type";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { Debrief } from "src/app/dto/jesip/debrief";
import { CheckList } from "src/app/dto/jesip/checklist";
import { IncidentInformation } from "src/app/dto/jesip/incident-information";
import { Member } from "src/app/dto/jesip/member";
import { Methane } from "src/app/dto/jesip/Methane";
import { IiMarch } from "src/app/dto/jesip/iimarch";
import { Jdm } from "src/app/dto/jesip/jdm";
import { BufferProvider } from "src/app/global/automation/buffer/buffer.provider";
import { MAP_ITEM_TYPE } from "../global/constants/enums/map-item-type";
import { AUTH } from "../global/constants/enums/auth";
import { Subject } from "rxjs/internal/Subject";
import { Arrow } from "../dto/items/arrow/arrow";
import { ArrowFile } from "../dto/items/arrow/arrow-file";

@Injectable({
	providedIn: "root"
})
export class WebRequestFactory {
	public requestTimestamp_ms: number | null = null;
	public requestTimestamp: string | null = null;

	public cnst: ConstantsService;
	public wreq: WebRequestsService;
	public mssg: MessagingService;
	public bufferProvider: BufferProvider;
	public isLive: boolean = true;
	public isFileLoading: boolean = false;
	public onFileLoaded$ = new Subject<void>();

	constructor(
		cnst: ConstantsService,
		wreq: WebRequestsService,
		mssg: MessagingService,
		buff: BufferProvider
	) {
		this.cnst = cnst;
		this.wreq = wreq;
		this.mssg = mssg;
		this.bufferProvider = buff;

		mssg.registerListener(
			MESSAGE_TYPE.PLAY_MODE_CHANGE,
			(params: { liveMode: boolean; buffered: boolean; buffer: ReplayBuffer }) => {
				this.isLive = params.liveMode;
				//	this.isBuffered = params.buffered;

				//	this.buffer = params.buffer;
			}
		);
	}

	setTimestamp: Function = (isoDate: string, time_ms: number) => {
		this.requestTimestamp = isoDate;
		this.requestTimestamp_ms = time_ms;
	};

	public readonly getAuthHeader: Function = () => {
		return this.wreq.authHeader;
	};

	beginRequest: Function = () => {
		return this.wreq
			.getWSJSON(URLMap.WSURL() + URLMap.BEGIN_REQUEST)
			.catch(this.cnst.errorHandler);
	};
	GetLastChange: Function = (last_change_local: Change) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.LAST_CHANGE,
				this.getDAORequest(last_change_local)
			)
			.catch(this.cnst.errorHandler);
	};

	GetSyncChanges(last_change_local: Change): string[] {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.SYNC_CHANGE,
				this.getDAORequest(last_change_local)
			)
			.catch(this.cnst.errorHandler);
	}

	/* GET DATA */

	getBufferData: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.MISSION_DATA.BUFFER_DATA,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	/*getRecordedRTMPnames: Function = () => {
        return this.wreq.getRecordedRTMPnames();
    }*/

	async getAttachments(mission_id: number, isPlayback?: boolean): Promise<Array<string>> {
		if (isPlayback && this.bufferProvider.buffer.attachmentsLoaded)
			return this.bufferProvider.buffer.get(
				BUFFER_ITEM_TYPE.ATTACHMENT,
				this.requestTimestamp_ms!
			);
		else {
			const ans = await this.wreq
				.postWSJSON(
					URLMap.WSURL() + URLMap.GET.ATTACHMENTS,
					this.getElementRequest(mission_id)
				)
				.catch(this.cnst.errorHandler);
			if (isPlayback && !this.bufferProvider.buffer.attachmentsLoaded)
				this.bufferProvider.buffer.set(BUFFER_ITEM_TYPE.ATTACHMENT, ans);
			return ans;
		}
	}

	//TODO: control that return is not empty
	getMissionDetails: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.MISSION, this.getElementRequest(id_incident))
			.catch(this.cnst.errorHandler);
	};
	/**
	 * Returns array of json strings containing mission data
	 * @method getAllMissions
	 * @return {String[]} The available missions
	 */
	getAllMissions: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.MISSION, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getAllPlans: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.TACTICS, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getAllOverlays: Function = (isPlayback?: boolean, id_incident?: number) => {
		if (isPlayback)
			return this.bufferProvider.buffer.get(
				BUFFER_ITEM_TYPE.OVERLAY,
				this.requestTimestamp_ms!
			);
		else
			return this.wreq
				.postWSJSON(
					URLMap.WSURL() + URLMap.GET.OVERLAYS,
					this.getIncidentRequest(id_incident)
				)
				.catch(this.cnst.errorHandler);
	};

	getOverlayById: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.OVERLAY, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getFloors: Function = (parent_id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.FLOORS, this.getElementRequest(parent_id))
			.catch(this.cnst.errorHandler);
	};

	/* TIMESTAMP DEPENDENT*/
	getAllResources: Function = (isPlayback?: boolean) => {
		if (isPlayback)
			return this.bufferProvider.buffer.get(
				BUFFER_ITEM_TYPE.RESOURCE,
				this.requestTimestamp_ms!
			);
		else
			return this.wreq
				.postWSJSON(URLMap.WSURL() + URLMap.GET.AGENT, WSRequest.getNullRequest())
				.catch(this.cnst.errorHandler);
	};
	getResourceById: Function = (agent_id: number) => {
		let wsrequest = this.getElementRequest(agent_id);
		wsrequest.timestamp = null;
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.AGENT, wsrequest)
			.catch(this.cnst.errorHandler);
	};

	getAllMissionAreas = (id_incident: number, isPlayback?: boolean): string[] => {
		if (isPlayback)
			return this.bufferProvider.buffer.get(BUFFER_ITEM_TYPE.AREA, this.requestTimestamp_ms!);
		else {
			let req: WSRequest = this.getIncidentRequest(id_incident);
			req.timestamp = -1;
			return this.wreq
				.postWSJSON(URLMap.WSURL() + URLMap.GET.MISSION_DATA.AREA, req)
				.catch(this.cnst.errorHandler);
		}
	};
	getAreaById: Function = (id_area: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.MISSION_DATA.AREA_BY_ID,
				this.getElementRequest(id_area)
			)
			.catch(this.cnst.errorHandler);
	};
	getAllMissionPois: Function = (id_incident: number, isPlayback?: boolean) => {
		if (isPlayback)
			return this.bufferProvider.buffer.get(BUFFER_ITEM_TYPE.POI, this.requestTimestamp_ms!);
		else {
			let req: WSRequest = this.getIncidentRequest(id_incident);
			req.timestamp = -1;
			return this.wreq
				.postWSJSON(URLMap.WSURL() + URLMap.GET.MISSION_DATA.POI, req)
				.catch(this.cnst.errorHandler);
		}
	};
	getPoiById: Function = (id_poi: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.MISSION_DATA.POI_BY_ID,
				this.getElementRequest(id_poi)
			)
			.catch(this.cnst.errorHandler);
	};

	getAgentLog: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.AGENT_LOG, this.getIncidentRequest(id_incident))
			.catch(this.cnst.errorHandler);
	};

	getAllCameras: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.CAMERA, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getAllDecisions: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.DECISION, this.getIncidentRequest(id_incident))
			.catch(this.cnst.errorHandler);
	};
	getAllDrones: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.DRONE, this.getIncidentRequest(id_incident))
			.catch(this.cnst.errorHandler);
	};
	getCameraById: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.CAMERA, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	getAllPois: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.POI, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getAgentTelemetry: Function = (agent: Resource) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.TELEMETRY,
				this.getElementRequest(agent.telemetry_id),
				true
			)
			.catch(this.cnst.errorHandler);
	};
	getTelemetry: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.TELEMETRY, this.getElementRequest(), true)
			.catch(this.cnst.errorHandler);
	};
	getStates: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.STATES, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getGPSDevices: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.GPS_DEVICE, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getAllStations: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.STATION, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getUsers: Function = (id?: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.USER, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getUserSSO = (): string => {
		return this.wreq
			.getWSJSON(URLMap.WSURL() + URLMap.GET.USERSSO)
			.catch(this.cnst.errorHandler);
	};

	getLevels: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.LEVEL, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};
	FetchMissionHistoryChanges: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.MISSION_DATA.HISTORY_CHANGES,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getChangesTimestamps: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.MISSION_DATA.CHANGES_TIMESTAMPS,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	/**
	 * Returns file URL with credentials, NOT A PROMISE
	 */

	getFilename: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.FILENAME + "/" + id)
			.catch(this.cnst.errorHandler);
	};

	getFilenames: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.FILENAMES)
			.catch(this.cnst.errorHandler);
	};

	//Returns File BLOB in a promise
	getFile: Function = (id: number) => {
		this.isFileLoading = true;
		return this.wreq
			.getFile(null, URLMap.WSURL() + URLMap.GET.FILE_BY_ID + "/" + id)
			.catch(this.cnst.errorHandler)
			.then((ans: any) => {
				this.isFileLoading = false;
				this.onFileLoaded$.next();
				return ans;
			});
	};

	getFileByName: Function = (fileName: string, destName: string) => {
		this.isFileLoading = true;
		const specialCharPattern = /[^a-zA-Z0-9\s]/g;
		const sanitizedDestName = destName ? destName.replace(specialCharPattern, "") : "";

		if (!fileName || !fileName.length) {
			console.error("webRequestFactory.getFileByName called with null fileName parameter at");
			console.trace();
			return false;
		}

		return this.wreq
			.getFile(
				null,
				URLMap.WSURL() +
					URLMap.GET.FILE +
					"/" +
					fileName +
					(sanitizedDestName ? "/" + sanitizedDestName : "")
			)
			.catch(this.cnst.errorHandler)
			.then((ans: any) => {
				this.isFileLoading = false;
				this.onFileLoaded$.next();
				return ans;
			});
	};

	getFileUrlById: Function = (id: number) => {
		return URLMap.WSURL() + URLMap.GET.FILE_BY_ID + "/" + id;
	};

	getAllKmls: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.KML, this.getIncidentRequest(id_incident))
			.catch(this.cnst.errorHandler);
	};
	saveKml: Function = (kml: Kml) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.KML, this.getDAORequest(kml))
			.catch(this.cnst.errorHandler);
	};
	deleteKml: Function = (kml_id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.KML, this.getElementRequest(kml_id))
			.catch(this.cnst.errorHandler);
	};
	getBoundedKmlFilename: Function = (fileName: string, circleArea: CircleArea) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.BOUND_KML_NAME + "/" + fileName,
				this.getDAORequest(circleArea)
			)
			.catch(this.cnst.errorHandler);
	};

	deattach: Function = (type: string, id: number, id_file: number) => {
		return this.wreq
			.getWSJSON(URLMap.WSURL() + URLMap.DEATTACH + "/" + type + "/" + id + "/" + id_file)
			.catch(this.cnst.errorHandler);
	};

	getHardware: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.HARDWARE, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	saveHardware: Function = (hw: Hardware) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.HARDWARE, this.getDAORequest(hw))
			.catch(this.cnst.errorHandler);
	};

	postAudio: Function = (type: string, id: number, fname: string, data: any) => {
		return this.wreq
			.uploadFile(URLMap.WSURL() + URLMap.UPLOADAUDIO + "/" + type + "/" + id, fname, data)
			.catch(this.cnst.errorHandler);
	};

	getAllCommanders: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.COMMAND_STRUCTURE.COMMANDER,
				this.getElementRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	getAllSectors: Function = (id_comm: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.COMMAND_STRUCTURE.SECTOR,
				this.getElementRequest(id_comm)
			)
			.catch(this.cnst.errorHandler);
	};
	getAllSupports: Function = (id_comm: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.COMMAND_STRUCTURE.SUPPORT,
				this.getElementRequest(id_comm)
			)
			.catch(this.cnst.errorHandler);
	};
	getAllCSResources: Function = (id_sector: number) => {
		// sector id must be passed as missionid
		let req: WSRequest = this.getIncidentRequest(id_sector);
		// @ts-ignore
		req.timestamp = null;
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.COMMAND_STRUCTURE.SECTOR_ASSIGNATION, req)
			.catch(this.cnst.errorHandler);
	};
	getCSResource: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.COMMAND_STRUCTURE.SECTOR_ASSIGNATION,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};

	//GET FSG SECTION
	getFsg: Function = (id: number, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.FSG.SIMPLE,
				this.getIncidentElementRequest(id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	getFullFsg: Function = (id: number, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.FSG.ALL,
				this.getIncidentElementRequest(id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	getFsgHistory: Function = (id: number, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.FSG.HISTORY_CHANGES,
				this.getIncidentElementRequest(id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	getFlat: Function = (id: number, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.FSG.FLAT,
				this.getIncidentElementRequest(id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	/////////////////

	getSectorById: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.COMMAND_STRUCTURE.SECTOR_ID,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};
	getSupportById: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.COMMAND_STRUCTURE.SUPPORT_ID,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};

	getAllARAs: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.ARA, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getAllMissionARAs: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.ARA, this.getIncidentRequest(id_incident))
			.catch(this.cnst.errorHandler);
	};

	getAllMissionFullARAs: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.ARA_FULL, this.getIncidentRequest(id_incident))
			.catch(this.cnst.errorHandler);
	};

	getARAScenarioRel: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.ARA_SCENARIO_REL,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getARARiskInfoRel: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.ARA_RISKINFO_REL,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getARAControlMeasureRel: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.ARA_CM_REL,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getARASubControlMeasureRel: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.ARA_SCM_REL,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	// getHazards: Function = () => {
	// 	return this.wreq.postWSJSON(URLMap.WSURL() + URLMap.GET.HAZARD, this.getElementRequest()).catch(this.cnst.errorHandler);
	// };

	getScenario: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.SCENARIO, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getRiskInformation: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.RISKINFORMATION, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getRiskInformationRelations: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.ARA_RISKINFO_REL,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getControlMeasureType: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.CONTROLMEASURETYPE, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getControlMeasureRelations: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.ARA_CM_REL,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getRiskTypes: Function = (idIncident: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.RISKTYPE, this.getIncidentRequest(idIncident))
			.catch(this.cnst.errorHandler);
	};

	getRiskRelations: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.RISKRELATION,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getSkills: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.SKILL, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};

	getUserSkillRelations: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.SKILLUSERRELATION, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};
	getUserSkillRelation: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.SKILLUSERRELATION, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getAppliances: Function = () => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.APPLIANCE, this.getElementRequest())
			.catch(this.cnst.errorHandler);
	};
	getApplianceRelation: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.APPLIANCE_RELATION, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	getClosedApplianceRelations: Function = () => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.CLOSED_APPLIANCE_RELATION,
				this.getElementRequest()
			)
			.catch(this.cnst.errorHandler);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	async getApplianceRelationExtended(id: number, isPlayback?: boolean) {
		if (isPlayback && this.bufferProvider.buffer.relationsLoaded)
			return this.bufferProvider.buffer.get(
				BUFFER_ITEM_TYPE.APPLIANCE_RELATION,
				this.requestTimestamp_ms!
			);
		else {
			let req: WSRequest = this.getElementRequest(id);
			// @ts-ignore
			req.timestamp = null;
			const ans = await this.wreq
				.postWSJSON(URLMap.WSURL() + URLMap.GET.APPLIANCE_RELATION_EXTENDED, req)
				.catch(this.cnst.errorHandler);
			if (isPlayback && !this.bufferProvider.buffer.relationsLoaded)
				this.bufferProvider.buffer.set(BUFFER_ITEM_TYPE.APPLIANCE_RELATION, ans);
			return ans;
		}
	}

	getDecisionFile: Function = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.DECISIONFILE,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getJesipMethane(methane: Methane): Promise<string[]> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.JESIP.METHANE, this.getDAORequest(methane))
			.catch(this.cnst.errorHandler);
	}

	getJesipIiMarch(iimarch: IiMarch): Promise<string[]> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.JESIP.IIMARCH, this.getDAORequest(iimarch))
			.catch(this.cnst.errorHandler);
	}

	getJesipJdm(jdm: Jdm): Promise<string[]> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.JESIP.JDM, this.getDAORequest(jdm))
			.catch(this.cnst.errorHandler);
	}

	getJesipDebrief: (deb: Debrief) => Promise<string> = (deb) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.JESIP.DEBRIEF, this.getDAORequest(deb))
			.catch(this.cnst.errorHandler);
	};

	getChecklist(checklist: CheckList): Promise<string> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.JESIP.CHECKLIST, this.getDAORequest(checklist))
			.catch(this.cnst.errorHandler);
	}

	getJesipIncidentInformation: (req: IncidentInformation) => Promise<Array<string>> = (req) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.JESIP.INCIDENT_INFORMATION,
				this.getDAORequest(req)
			)
			.catch(this.cnst.errorHandler);
	};

	getJesipCommander: (req: Member) => Promise<Array<string>> = (req) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.GET.JESIP.COMMANDER, this.getDAORequest(req))
			.catch(this.cnst.errorHandler);
	};

	getAIRecommendation: (id_incident: number) => Promise<string> = (id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.GET.AI_RECOMMENDATION,
				this.getIncidentRequest(id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	getIncidentAttachments: (mission: Incident) => Promise<File> = (mission: Incident) => {
		return this.wreq.getIncidentAttachments(this.getDAORequest(mission));
	};

	getIncidentDecisionLog(incident: Incident, essentials?: boolean): Promise<Blob> {
		const dt = new Date(incident.start_time_ms);
		return this.wreq.getFile(null, URLMap.WSURL() + URLMap.GET.INCIDENT_DOCUMENT.DECISION + incident.id, {
			timezone_offset: -dt.getTimezoneOffset(),
			essentials: essentials !== undefined ? essentials : false
		});
	}

	/* GET DATA END */

	/* UPDATE DATA */

	//SAVE/UPDATE FSG S////
	saveNewFsg: Function = (fsg: FsgBuilding) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.FSG.NEW, this.getDAORequest(fsg))
			.catch(this.cnst.errorHandler);
	};

	saveFsgFlat: Function = (fsg: FsgFlat) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.FSG.FLAT, this.getDAORequest(fsg))
			.catch(this.cnst.errorHandler);
	};

	saveFsgSimple: Function = (fsg: FsgBuilding) => {
		// updates the Fsg table
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.FSG.SIMPLE, this.getDAORequest(fsg))
			.catch(this.cnst.errorHandler);
	};

	addFsgToMission: Function = (fsg: FsgBuilding, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.ADD.FSG,
				this.getDAOIncidentRequest(fsg, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};

	addDebriefToIncident: Function = (debrief: Debrief) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.ADD.DEBRIEF, this.getDAOIncidentRequest(debrief))
			.catch(this.cnst.errorHandler);
	};
	/////////////////

	saveIncident: Function = (mission: Incident) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.SAVE.MISSION,
			this.getDAORequest(mission)
		);
	};
	newIncident: Function = (event: Event) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.NEW.EVENT_MISSION,
			this.getDAORequest(event)
		);
	};
	saveArea: Function = (area: Area, shapetype: string) => {
		if (shapetype == SHAPE_TYPE.POLYLINE) shapetype = "polygon";
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.AREA + "/" + shapetype.toUpperCase(),
				this.getDAORequest(area)
			)
			.catch(this.cnst.errorHandler);
	};
	saveIncidentArea: Function = (area: Area, shapetype: string) => {
		if (shapetype == SHAPE_TYPE.POLYLINE) shapetype = "polygon";
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.MISSION_DATA.AREA + "/" + shapetype.toUpperCase(),
				this.getDAORequest(area)
			)
			.catch(this.cnst.errorHandler);
	};
	saveResource: Function = (agent: Resource) => {
		const dao: any = this.getDAORequest(agent);
		dao.timestamp = null;
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.AGENT, dao)
			.catch(this.cnst.errorHandler);
	};
	saveState: Function = (state: ResourceState) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.STATE, this.getDAORequest(state))
			.catch(this.cnst.errorHandler);
	};
	saveDecision: Function = (decision: Decision) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.DECISION, this.getDAORequest(decision))
			.catch(this.cnst.errorHandler);
	};
	saveDrone: Function = (drone: Drone) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.DRONE, this.getDAORequest(drone))
			.catch(this.cnst.errorHandler);
	};
	saveRtmpCamera: Function = (camera: Camera) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.RTMP_CAMERA, this.getDAORequest(camera))
			.catch(this.cnst.errorHandler);
	};
	saveUser: Function = (user: User) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.USER, this.getDAORequest(user))
			.catch(this.cnst.errorHandler);
	};
	saveLevel: Function = (level: Level) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.LEVEL, this.getDAORequest(level))
			.catch(this.cnst.errorHandler);
	};
	addResourceToIncident: Function = (agent: Resource, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.ADD.MISSION_DATA.AGENT,
				this.getIncidentElementRequest(agent.id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	addDroneToIncident: Function = (drone: Drone, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.ADD.MISSION_DATA.DRONE,
				this.getIncidentElementRequest(drone.id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	removeResourceFromIncident: Function = (agent: Resource) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.REMOVE.MISSION_DATA.AGENT,
				this.getIncidentElementRequest(agent.id, agent.id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	savePoi: Function = (poi: Poi) => {
		let req = this.getDAORequest(poi);
		req.timestamp = null;
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.POI, req)
			.catch(this.cnst.errorHandler);
	};
	saveCommander: Function = (commander: CSCommander) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.COMMAND_STRUCTURE.COMMANDER,
				this.getDAORequest(commander)
			)
			.catch(this.cnst.errorHandler);
	};
	saveSector: Function = (sector: CSSector) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.COMMAND_STRUCTURE.SECTOR,
				this.getDAORequest(sector)
			)
			.catch(this.cnst.errorHandler);
	};
	saveSupport: Function = (support: CSSupport) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.COMMAND_STRUCTURE.SUPPORT,
				this.getDAORequest(support)
			)
			.catch(this.cnst.errorHandler);
	};
	saveCSResource: Function = (resource: CSSectorResourceAssignation) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.COMMAND_STRUCTURE.SECTOR_ASSIGNATION,
				this.getDAORequest(resource)
			)
			.catch(this.cnst.errorHandler);
	};

	saveOverlay: Function = (plane: Overlay) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.OVERLAY, this.getDAORequest(plane))
			.catch(this.cnst.errorHandler);
	};

	saveFloor: Function = (floor: Floor) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.FLOOR, this.getDAORequest(floor))
			.catch(this.cnst.errorHandler);
	};

	saveHazard: Function = (hazard: Hazard) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.HAZARD, this.getDAORequest(hazard))
			.catch(this.cnst.errorHandler);
	};

	saveScenario: Function = (scenario: Scenario) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.SCENARIO, this.getDAORequest(scenario))
			.catch(this.cnst.errorHandler);
	};

	saveRiskInformation: Function = (ri: RiskInformation) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.RISKINFORMATION, this.getDAORequest(ri))
			.catch(this.cnst.errorHandler);
	};

	saveRiskInformationRelation: Function = (ri: RiskInformationRelation) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.ARA_RISKINFO_REL, this.getDAORequest(ri))
			.catch(this.cnst.errorHandler);
	};

	saveControlMeasureType: Function = (cm: ControlMeasure) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.CONTROLMEASURETYPE, this.getDAORequest(cm))
			.catch(this.cnst.errorHandler);
	};

	saveControlMeasureRelation: Function = (cm: ControlMeasureRelation) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.ARA_CM_REL, this.getDAORequest(cm))
			.catch(this.cnst.errorHandler);
	};

	saveSubControlMeasureType: Function = (subcm: SubControlMeasure) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.SUBCONTROLMEASURETYPE,
				this.getDAORequest(subcm)
			)
			.catch(this.cnst.errorHandler);
	};

	saveSubControlMeasureRelation: Function = (subcm: SubControlMeasureRelation) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.ARA_SCM_REL, this.getDAORequest(subcm))
			.catch(this.cnst.errorHandler);
	};

	saveRisk: Function = (risk: RiskType) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.RISKTYPE, this.getDAORequest(risk))
			.catch(this.cnst.errorHandler);
	};

	saveFullARA: Function = (ARA: Ara) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.FULL_ARA, this.getDAORequest(ARA))
			.catch(this.cnst.errorHandler);
	};

	saveARA: Function = (ARA: Ara) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.ARA, this.getDAORequest(ARA))
			.catch(this.cnst.errorHandler);
	};

	saveRiskRelation: Function = (risk: RiskInformationRelation) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.RISKRELATION, this.getDAORequest(risk))
			.catch(this.cnst.errorHandler);
	};

	saveUserSkillRelation: Function = (relation: SkillUserRelation) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.SKILLUSERRELATION,
				this.getDAORequest(relation)
			)
			.catch(this.cnst.errorHandler);
	};

	saveAppliance: Function = (appliance: Resource) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.APPLIANCE, this.getDAORequest(appliance))
			.catch((err: any) => console.error(err));
	};

	saveApplianceRelation: Function = (appliance_rel: ApplianceRelation) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.APPLIANCE_RELATION,
				this.getDAORequest(appliance_rel)
			)
			.catch((err: any) => console.error(err));
	};

	saveDecisionFile: Function = (decision_file: DecisionFile) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.DECISIONFILE,
				this.getDAORequest(decision_file)
			)
			.catch((err: any) => console.error(err));
	};

	saveNewChecklist: Function = (checklist: CheckList) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.JESIP.NEWCHECKLIST,
				this.getDAORequest(checklist)
			)
			.catch((err: any) => console.error(err));
	};

	saveChecklist: Function = (checklist: CheckList) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.JESIP.CHECKLIST, this.getDAORequest(checklist))
			.catch((err: any) => console.error(err));
	};

	saveJesipMethane(methane: Methane): Promise<string> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.JESIP.METHANE, this.getDAORequest(methane))
			.catch((err: any) => console.error(err));
	}

	saveJesipIiMarch(iimarch: IiMarch): Promise<string> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.JESIP.IIMARCH, this.getDAORequest(iimarch))
			.catch((err: any) => console.error(err));
	}

	saveJesipJdm(jdm: Jdm): Promise<string> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.JESIP.JDM, this.getDAORequest(jdm))
			.catch((err: any) => console.error(err));
	}

	saveJesipDebrief(debrief: Debrief): Promise<string> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.JESIP.DEBRIEF, this.getDAORequest(debrief))
			.catch((err: any) => console.error(err));
	}

	saveResourceLog(entry: ResourceLogEntry): Promise<string> {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.AGENT_ENTRY, this.getDAORequest(entry))
			.catch((err: any) => console.error(err));
	}

	// UPDATE SAVE DATA END
	saveJesipIncidentInformation: (info: IncidentInformation) => Promise<string> = (info) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.JESIP.INCIDENT_INFORMATION,
				this.getDAORequest(info)
			)
			.catch((err: any) => console.error(err));
	};

	saveJesipCommander: (comm: Member) => Promise<string> = (comm) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.JESIP.COMMANDER, this.getDAORequest(comm))
			.catch((err: any) => console.error(err));
	};

	// UPDATE DATA END SAVE DATA END

	//DELETE DATA START!

	deleteFsg: Function = (fsg: FsgBuilding) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.FSG, this.getElementRequest(fsg.id_fsg))
			.catch(this.cnst.errorHandler);
	};

	deleteFlat: Function = (flat: FsgFlat) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.FLAT, this.getDAORequest(flat))
			.catch(this.cnst.errorHandler);
	};

	deleteFloor: Function = (floor: Floor) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.FLOOR,
				this.getElementRequest(floor.id_floor)
			)
			.catch(this.cnst.errorHandler);
	};

	deleteArea: Function = (id: number, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.MISSION_DATA.AREA,
				this.getIncidentElementRequest(id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	deleteAreaForever: Function = (id: number, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.MISSION_DATA.AREA_FOREVER,
				this.getIncidentElementRequest(id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	deletePoi: Function = (id: number, id_incident: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.MISSION_DATA.POI,
				this.getIncidentElementRequest(id, id_incident)
			)
			.catch(this.cnst.errorHandler);
	};
	deleteAgent: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.MISSION_DATA.AGENT,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};
	deleteState: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.STATE, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteDrone: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.MISSION_DATA.DRONE,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};
	deleteUser: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.USER, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteCamera: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.CAMERA, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteHardware: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.HARDWARE, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteIncident: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.MISSION, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	removeUserSkillRelation: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.SKILLUSERRELATION,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};
	updateMilestoneCameras: Function = () => {
		return this.wreq.getWSJSON(URLMap.WSURL() + URLMap.UPDATE.MILESTONE_CAMERAS);
	};
	saveAreaType: Function = (areaType: AreaType) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.TYPE.AREA, this.getDAORequest(areaType))
			.catch(this.cnst.errorHandler);
	};
	saveResourceType: Function = (agentType: ResourceType) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.TYPE.AGENT, this.getDAORequest(agentType))
			.catch(this.cnst.errorHandler);
	};
	savePoiType: Function = (poiType: PoiType) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.TYPE.POI, this.getDAORequest(poiType))
			.catch(this.cnst.errorHandler);
	};
	saveEventType: Function = (eventType: IncidentType) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.TYPE.EVENT, this.getDAORequest(eventType))
			.catch(this.cnst.errorHandler);
	};
	saveGPSDevice: Function = (device: GPSDevice) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.SAVE.GPS_DEVICE, this.getDAORequest(device))
			.catch(this.cnst.errorHandler);
	};
	deleteEventType: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.TYPE.EVENT, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteAreaType: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.TYPE.AREA, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteResourceType: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.TYPE.AGENT, this.getElementRequest(id))
			.catch((error: any) => {
				console.error(error);
			});
	};
	deletePoiType: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.TYPE.POI, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteGPSDevice: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.GPS_DEVICE, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};
	deleteSupport: Function = (support: CSSupport) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.STRUCTURE.SUPPORT,
				this.getElementRequest(support.id_support)
			)
			.catch(this.cnst.errorHandler);
	};
	deleteSector: Function = (sector: CSSector) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.STRUCTURE.SECTOR,
				this.getElementRequest(sector.id_sector)
			)
			.catch(this.cnst.errorHandler);
	};
	deleteResource: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.STRUCTURE.SECTOR_ASSIGNATION,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};

	deleteOverlay: Function = (plane: Overlay) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.PLANE, this.getElementRequest(plane.id))
			.catch(this.cnst.errorHandler);
	};

	deleteHazard: Function = (hazard: Hazard) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.HAZARD,
				this.getElementRequest(hazard.id_hazard)
			)
			.catch(this.cnst.errorHandler);
	};

	deleteControlMeasureType: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.CONTROLMEASURETYPE,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};

	deleteSubControlMeasureType: Function = (id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.SUBCONTROLMEASURETYPE,
				this.getElementRequest(id)
			)
			.catch(this.cnst.errorHandler);
	};

	deleteRiskInformation: Function = (id: number) => {
		return this.wreq
			.postWSJSON(URLMap.WSURL() + URLMap.DELETE.RISKINFORMATION, this.getElementRequest(id))
			.catch(this.cnst.errorHandler);
	};

	deleteRiskType: Function = (risk: RiskType) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.RISKTYPE,
				this.getElementRequest(risk.id_risk)
			)
			.catch(this.cnst.errorHandler);
	};

	deleteAppliance: Function = (appliance: Resource) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.APPLIANCE,
				this.getElementRequest(appliance.id)
			)
			.catch((err: any) => console.error(err));
	};

	deleteApplianceRelation: Function = (rel_id: number) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.DELETE.APPLIANCE_RELATION,
				this.getElementRequest(rel_id)
			)
			.catch((err: any) => console.error(err));
	};

	grantUserPermission: Function = (user_id: number, id_incident: number, grant: string) => {
		if (grant)
			return this.wreq.postWSJSON(
				URLMap.WSURL() + URLMap.GRANT,
				this.getIncidentElementRequest(user_id, id_incident)
			);
		else
			return this.wreq.postWSJSON(
				URLMap.WSURL() + URLMap.DENY,
				this.getIncidentElementRequest(user_id, id_incident)
			);
	};
	saveConfiguration: Function = (configuration: Configuration) => {
		return this.wreq
			.postWSJSON(
				URLMap.WSURL() + URLMap.SAVE.CONFIGURATION,
				this.getDAORequest(configuration)
			)
			.catch(this.cnst.errorHandler);
	};
	getConfiguration: Function = () => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.GET.CONFIGURATION,
			this.getElementRequest()
		);
	};

	public readonly getIncidentArrows: (
		incidentId: number,
		isPlayback?: boolean
	) => Promise<string[]> = (incidentId, isPlayback) => {
		if (isPlayback)
			return this.bufferProvider.buffer.get(
				BUFFER_ITEM_TYPE.ARROW,
				this.requestTimestamp_ms!
			);
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.GET.MISSION_DATA.ARROW,
			this.getIncidentRequest(incidentId)
		);
	};

	public readonly getArrowById: (arrowId: number) => Promise<string> = (arrowId) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.GET.ARROW,
			this.getElementRequest(arrowId)
		);
	};

	public readonly getArrowFiles: (incidentId: number) => Promise<string[]> = (incidentId) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.GET.MISSION_DATA.ARROW_FILE,
			this.getIncidentRequest(incidentId)
		);
	};

	public readonly saveArrowFile: (arrowFile: ArrowFile) => Promise<string> = (arrowFile) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.SAVE.ARROW_FILE,
			this.getDAORequest(arrowFile)
		);
	};

	public readonly saveArrow: (arrow: Arrow) => Promise<string> = (arrow) => {
		return this.wreq.postWSJSON(URLMap.WSURL() + URLMap.SAVE.ARROW, this.getDAORequest(arrow));
	};

	uploadFile: (name: string, file: any) => Promise<UploadedFile> = (name: string, file: any) => {
		return this.wreq
			.uploadFile(URLMap.WSURL() + URLMap.UPLOAD, name, file)
			.then((json: string) => {
				return UploadedFile.fromJson(json);
			})
			.catch(this.cnst.errorHandler);
	};
	attachPoiFile: Function = (id: number, file: any) => {
		return this.wreq
			.uploadFile(URLMap.WSURL() + URLMap.ATTACH_TO + "/poi/" + id, file.name, file)
			.catch(this.cnst.errorHandler);
	};

	attachArrowFile: Function = (id: number, file: any) => {
		return this.wreq
			.uploadFile(URLMap.WSURL() + URLMap.ATTACH_TO + "/arrow/" + id, file.name, file)
			.catch(this.cnst.errorHandler);
	};

	login: Function = async (username: string, password: string, captchaResult: string) => {
		const ans = await this.wreq.getWSJSONlogin(
			URLMap.WSURL() + URLMap.LOGIN,
			username,
			password,
			captchaResult
		);
		if (ans) {
			const user = { ...User.fromJson(ans) };
			this.wreq.setCredentials(user.name, user.token);
		}
		return ans;
	};

	public readonly loginSSO = async (params: {
		username: string;
		token: string;
	}): Promise<string> => {
		await this.wreq.setCredentials(params.username, params.token);
		return this.getUserSSO();
	};

	public readonly setAuthToken = (username: string, token: string): void => {
		return this.wreq.setCredentials(username, token);
	};

	getEsriToken: () => Promise<string> = () => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.ESRILOGIN,
			this.getDAORequest({
				referer: window.location.origin
			})
		);
	};

	logout: () => Promise<void> = () => {
		return this.wreq.getWSJSON(URLMap.WSURL() + URLMap.LOGOUT);
	};

	public readonly authenticate: (token: string) => boolean = (token) => {
		this.wreq.setCredentials("", token);
		return this.wreq.getWSJSON(URLMap.WSURL() + URLMap.AUTH);
	};

	public readonly logInformation: (title: string, message: string) => void = (title, message) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.LOG.INFORMATION,
			this.getDAORequest({ title: title, message: message })
		);
	};

	public readonly logWarning: (title: string, message: string) => void = (title, message) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.LOG.WARNING,
			this.getDAORequest({ title: title, message: message })
		);
	};

	public readonly logError: (title: string, message: string) => void = (title, message) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.LOG.ERROR,
			this.getDAORequest({ title: title, message: message })
		);
	};

	public readonly getFileToken: (name: string) => string = (name) => {
		return this.wreq.getWSJSON(URLMap.WSURL() + URLMap.GET.FILE_TOKEN.GET + name);
	};

	public readonly getFileWithToken: (token: string) => void = (token) => {
		return this.wreq.getWSJSON(URLMap.WSURL() + URLMap.GET.FILE_TOKEN.USE + token);
	};

	public readonly getFileTokenUseUrl: (token: string) => string = (token) => {
		return URLMap.WSURL() + URLMap.GET.FILE_TOKEN.USE + token;
	};

	public readonly getIncidentStreamKeys: (id_incident: number) => string = (id_incident) => {
		return this.wreq.postWSJSON(
			URLMap.WSURL() + URLMap.GET.INCIDENT_STREAMS,
			this.getIncidentRequest(id_incident)
		);
	};

	private readonly getDAORequest: Function = (element: any) => {
		return new WSRequest(null, null, element, this.requestTimestamp_ms);
	};

	private readonly getElementRequest: Function = (id: number) => {
		return new WSRequest(id ? id : null, null, null, this.requestTimestamp_ms);
	};

	private readonly getIncidentRequest: Function = (id_incident: number) => {
		return new WSRequest(
			null,
			id_incident ? id_incident : null,
			null,
			this.requestTimestamp_ms
		);
	};

	private readonly getIncidentElementRequest: Function = (id: number, id_incident: number) => {
		return new WSRequest(id, id_incident, null, this.requestTimestamp_ms);
	};

	private readonly getDAOIncidentRequest: Function = (element: any, id_incident: number) => {
		return new WSRequest(null, id_incident, element, null);
	};
}
