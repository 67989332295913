import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { Area } from "../items/area";
import { CircleArea } from "../items/circle-area";
import { Overlay } from "../items/overlay";
import { Poi } from "../items/poi";
import { PolygonArea } from "../items/polygon-area";
import { SHAPE_TYPE } from "../items/shape-type";
import { DTO } from "../net/dto";
import { Resource } from "../resources/resource";
import { BufferItem } from "./buffer-item";
import { UploadedFile } from "../net/uploaded-file";
import { ApplianceRelation } from "../resources/appliance-relation";
import { Arrow } from "../items/arrow/arrow";

export class ReplayBuffer implements DTO {
	id: number;
	timestamp: number;

	agents: Array<BufferItem>;

	areas: Array<BufferItem>;

	pois: Array<BufferItem>;

	overlays: Array<BufferItem> = [];

	arrows: Array<BufferItem> = [];

	attachmentsLoaded: boolean = false;
	attachments: Array<UploadedFile> = [];

	relationsLoaded: boolean = false;
	applianceRelations: Array<ApplianceRelation> = [];

	constructor(
		id: number,
		agents?: Array<BufferItem>,
		areas?: Array<BufferItem>,
		pois?: Array<BufferItem>,
		overlays?: Array<BufferItem>,
		arrows?: Array<BufferItem>,
		timestamp?: number,
		attachments?: Array<UploadedFile>,
		applRels?: Array<ApplianceRelation>
	) {
		this.id = id;
		this.timestamp = timestamp ? timestamp : Date.now();

		this.agents = agents
			? agents.map(function (agent) {
					return new BufferItem(
						agent.json,
						MAP_ITEM_TYPE.RESOURCE,
						agent.timestamp,
						Resource.fromJson(agent.json)
					);
			  })
			: [];
		this.areas = areas
			? areas.map(function (area) {
					const object =
						Area.getTypeFromJson(area.json) === SHAPE_TYPE.CIRCLE
							? CircleArea.fromJson(area.json)
							: PolygonArea.fromJson(area.json);
					return new BufferItem(area.json, MAP_ITEM_TYPE.AREA, area.timestamp, object);
			  })
			: [];
		this.pois = pois
			? pois.map(function (poi) {
					return new BufferItem(
						poi.json,
						MAP_ITEM_TYPE.POI,
						poi.timestamp,
						Poi.fromJson(poi.json)
					);
			  })
			: [];
		this.overlays = overlays
			? overlays.map(function (overlay) {
					return new BufferItem(
						overlay.json,
						MAP_ITEM_TYPE.OVERLAY,
						overlay.timestamp,
						Overlay.fromJson(overlay.json)
					);
			  })
			: [];

		this.arrows = arrows
			? arrows.map(function (arrow) {
					return new BufferItem(
						arrow.json,
						MAP_ITEM_TYPE.ARROW,
						arrow.timestamp,
						Arrow.fromJson(arrow.json)
					);
			  })
			: [];

		this.attachments = attachments ?? [];
		this.applianceRelations = applRels ?? [];
	}

	public static fromJson: Function = (json: string) => {
		const rb = JSON.parse(json);
		return new ReplayBuffer(
			rb.id,
			rb.agents,
			rb.areas,
			rb.pois,
			rb.overlays,
			rb.arrows,
			rb.timestamp
		);
	};

	// get the array situation for the specified timestamp
	public get: (itemName: BUFFER_ITEM_TYPE, timestamp: number) => string[] = (
		itemName,
		timestamp
	) => {
		if (
			itemName !== BUFFER_ITEM_TYPE.ATTACHMENT &&
			itemName !== BUFFER_ITEM_TYPE.APPLIANCE_RELATION
		) {
			// these items are still not temporalized, so no need to reduce;
			let array = this.getArray(itemName);
			if (!array || array.length === 0) return [];
			let filteredArray: BufferItem[] = array.reduce(
				(items: BufferItem[], item: BufferItem) => {
					if (item.timestamp <= timestamp) {
						const idx = items.findIndex((e) => e.object?.id === item.object?.id);
						if (idx === -1) items.push(item);
						else if (item.timestamp > items[idx].timestamp) items[idx] = item;
					}
					return items;
				},
				[]
			);
			return filteredArray.map((a) => a.json);
		} else {
			switch (itemName) {
				case BUFFER_ITEM_TYPE.ATTACHMENT:
					return this.attachments.map((e) => e.getJson());
					break;
				case BUFFER_ITEM_TYPE.APPLIANCE_RELATION:
					return this.applianceRelations.map((e) => e.getJson());
			}
		}
	};

	public getAllValues: (itemName: BUFFER_ITEM_TYPE, timestamp: number) => string[] = (
		itemName,
		timestamp
	) => {
		let array = this.getArray(itemName);
		if (!array || array.length === 0) return [];

		let filteredArray: BufferItem[] = array.reduce((items: BufferItem[], item: BufferItem) => {
			if (item.timestamp <= timestamp) {
				items.push(item);
			}
			return items;
		}, []);
		return filteredArray.map((a) => a.json);
	};

	public getJson = (): string =>
		JSON.stringify(this, ["id", "agents", "areas", "pois", "overlays", "arrows", "timestamp"]);

	public set(
		type: BUFFER_ITEM_TYPE.APPLIANCE_RELATION | BUFFER_ITEM_TYPE.ATTACHMENT,
		items: Array<string>
	): void {
		switch (type) {
			case BUFFER_ITEM_TYPE.APPLIANCE_RELATION:
				this.applianceRelations = items.map((e) => ApplianceRelation.fromJson(e));
				this.relationsLoaded = true;
				break;
			case BUFFER_ITEM_TYPE.ATTACHMENT:
				this.attachments = items.map((e) => UploadedFile.fromJson(e));
				this.attachmentsLoaded = true;
		}
	}

	private readonly getArray: (itemName: BUFFER_ITEM_TYPE) => Array<BufferItem> = (itemName) => {
		let array = new Array<BufferItem>();
		//Get the array and last item index
		switch (itemName) {
			case BUFFER_ITEM_TYPE.RESOURCE:
				array = this.agents;
				break;
			case BUFFER_ITEM_TYPE.AREA:
				array = this.areas;
				break;
			case BUFFER_ITEM_TYPE.POI:
				array = this.pois;
				break;
			case BUFFER_ITEM_TYPE.OVERLAY:
				array = this.overlays;
				break;
			case BUFFER_ITEM_TYPE.ARROW:
				array = this.arrows;
				break;
		}
		return array;
	};
}

export enum BUFFER_ITEM_TYPE {
	RESOURCE,
	AREA,
	POI,
	OVERLAY,
	ATTACHMENT,
	APPLIANCE_RELATION,
	ARROW
}
