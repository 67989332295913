<button
	data-add-action-name="signature-button"
	class="sign-button"
	[disabled]="disabled"
	(click)="araDetails ? openSignatureCanvas($event) : toggleSignatureCanvas()"
	[class.pending]="!ready && pendingToSign"
>
	<img data-cy="signature-error" *ngIf="!ready" class="sign-icon" />
	<div class="lottie">
		<lottie-player
			data-cy="signature-ready"
			*ngIf="ready"
			class="check signature-ready"
			src="{{ 'resources/lottie/green-check.json' | baseHref }}"
			background="transparent"
			speed="1"
			autoplay
		></lottie-player>
	</div>

	<p [ngClass]="getSignatureClass()">{{ getSignatureText() }}</p>
	<p *ngIf="!pendingToSign && isInDecisionLog && selectedUser" class="sign-text name">
		{{ selectedUser.name }}
	</p>
</button>

<div *ngIf="showCanvasModal" (click)="$event.preventDefault()">
	<app-canvas-component
		data-cy="to-sign-modal"
		(cancel)="handleCancel()"
		(closeEvent)="toggleSignatureCanvas()"
		id="signature"
		[image]="signature"
		[disabled]="disabled"
		[isDl]="isInDecisionLog"
		[araDetails]="araDetailsStyles"
		(confirm)="addSignature($event)"
		(isSomethingDrawnOut)="isSomethingDrawn($event)"
		[userName]="name"
		(userSelected)="onUserSelected($event)"
		[selectedUser]="selectedUser"
	>
	</app-canvas-component>
</div>
