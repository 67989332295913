import { Component, Input } from "@angular/core";
import { Poi } from "src/app/dto/items/poi";
import { ITEM_STATE } from "src/app/dto/items/status";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-poi-label",
	templateUrl: "poi-label.component.html",
	styleUrls: ["poi-label.css", "../map-label.css"]
})
export class PoiLabelComponent {
	@Input() poi!: Poi;

	text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	getStatusText: () => string = () => {
		switch (this.poi.status) {
			case ITEM_STATE.PREDICT:
				return this.text().POI_STATUS_PREDICT;
			case ITEM_STATE.OVER:
				return this.text().POI_STATUS_OVER;
			default:
				return this.text().CURRENT;
		}
	};
}
