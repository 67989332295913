import { Injectable, ViewChild } from "@angular/core";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { Credentials } from "src/app/dto/user/credentials";
import { User } from "src/app/dto/user/user";
import { ALERT_TYPE, MainService } from "src/app/global/main.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { AccessService } from "./access.service";
import { WebRequestFactory } from "../http/web.request.factory";
import { BehaviorSubject, Subject } from "rxjs";
import { LocaleMap } from "../global/constants/text/text-interface";
import { TextProvider } from "../global/constants/text/text-provider";
import { Router } from "@angular/router";
import { RecaptchaDirective } from "../widgets/directive/recaptcha.directive";
import { ConstantsService } from "../global/constants/constants.service";
import { SsoService } from "./sso.service";
import { AUTH } from "../global/constants/enums/auth";
import { LOG_TYPE } from "../global/constants/enums/log_types";
import { URLMap } from "../global/constants/enums/url-map";

@Injectable({
	providedIn: "root"
})
export class LoginService {
	@ViewChild(RecaptchaDirective) grecaptchaDirective!: RecaptchaDirective;

	public readonly $onLogin = new Subject<{ username: string; password: string } | string>();
	public readonly $loginFinished = new Subject<{ fullLoad: boolean; changeRoute: boolean }>();
	public autologin: { username: string; password: string } | false = false;

	public attemptCredentials: Credentials = new Credentials();
	public user: User = new User();
	// public loginError$ = new Subject<string>();
	public readonly version: string;
	public authMode: AUTH = AUTH.UNBLUR;
	public $onInitialLoadIng = new Subject<void>();
	public splashScreenReady: boolean = false;
	public hideSplashScreen: boolean = false;
	public keepSession: boolean = false;

	public logged: boolean = false;

	public $token = new BehaviorSubject("");

	private readonly wreq: WebRequestFactory;
	private readonly main: MainService;
	private readonly mssg: MessagingService;
	private readonly acc: AccessService;
	private readonly route: Router;
	private readonly text: () => LocaleMap;
	private readonly cnst: ConstantsService;
	private readonly ssoService: SsoService;

	constructor(wreq: WebRequestFactory, main: MainService, mssg: MessagingService, acc: AccessService, route: Router, textProv: TextProvider, cnst: ConstantsService, sso: SsoService) {
		this.wreq = wreq;
		this.main = main;
		this.mssg = mssg;
		this.acc = acc;
		this.route = route;
		this.text = textProv.getStringMap;
		this.cnst = cnst;
		this.ssoService = sso;
		const credentials = this.cnst.AUTO_LOGIN;
		if (credentials && credentials.username && credentials.password)
			this.autologin = {
				username: credentials.username,
				password: credentials.password
			};
		this.version = cnst.VERSION;
		this.authMode = this.cnst.AUTH;
		this.mssg.registerListener(MESSAGE_TYPE.DOLOGOUT, this.logOut);
		this.ssoService.ssoAuthenticated$.subscribe((params) => {
			if (!URLMap.use_backup) {
				this.logSso(params);
			}
		});
	}

	get authenticated(): boolean {
		return this.ssoService.authenticated;
	}

	public readonly log = async (params?: { username: string; password: string; token?: string } | false): Promise<void> => {
		// check if SSO auth
		if (this.cnst.AUTH === AUTH.SSO && !URLMap.use_backup) return this.ssoService.loginKC();
		// use params
		else if (params) {
			if (params.username !== "" && params.password !== "") {
				this.attemptCredentials.username = params.username!;
				const userJson = await this.wreq.login(params.username, params.password, params.token ? params.token : "");
				if (userJson) this.onLog(userJson);
			} else this.logOut();
		} else this.logOut();
	};

	public readonly logWithStoredSession = async (): Promise<void> => {
		try {
			const storedSession = this.readSession();
			if (!storedSession) return;
			const token = JSON.parse(storedSession!).token;
			const auth = await this.wreq.authenticate(token);
			if (storedSession && auth) this.onLog(storedSession, true);
			if (!auth) {
				localStorage.removeItem(this.getStorageKeys()[0]);
			}
		} catch (e) {
			localStorage.removeItem(this.getStorageKeys()[0]);
		}
	};

	public onLog(userJson: string, fromSession?: boolean): void {
		const user = User.fromJson(userJson);
		if (this.keepSession) this.writeSession(userJson);
		if (fromSession) this.wreq.setAuthToken(user.name, user.token!);
		CloneFactory.cloneProperties(this.user, User.fromJson(userJson));
		this.autologin = false;
		this.logged = this.main.logged = true;
		this.$onLogin.next(user.token);
		this.$token.next(user.token!);
		this.wreq.logInformation(LOG_TYPE.APP_LOGIN, "");
	}

	public readonly checkPassword = async (params: { username: string; password: string }): Promise<boolean> => {
		if (params.username !== "" && params.password !== "") {
			this.attemptCredentials.username = params.username!;
			const userJson = await this.wreq.login(params.username, params.password, "");
			if (userJson) {
				return true;
			}
		}
		return false;
	};

	public readonly logSso = async (params: { username: string; token: string }): Promise<void> => {
		if (params.token) {
			this.attemptCredentials.username = params.username;
			const userJson = await this.wreq.loginSSO(params);
			if (userJson && userJson !== "null") {
				CloneFactory.cloneProperties(this.user, User.fromJson(userJson));
				this.user.token = params.token;
				this.$token.next(params.token!);
				this.main.logged = true;
				this.$onLogin.next();
			} else this.main.addAlert(ALERT_TYPE.ERROR, this.text().INVALID_TOKEN, "", this.onLogout); //errors with the token (like base64 padding) or user duplicity
		} else this.main.addAlert(ALERT_TYPE.ERROR, this.text().INVALID_TOKEN, "", this.onLogout);
	};

	public readonly logOut: Function = () => {
		localStorage.removeItem(this.getStorageKeys()[0]);
		CloneFactory.cloneProperties(this.user, new User());
		if (this.cnst.AUTH !== AUTH.SSO) this.mssg.fire(MESSAGE_TYPE.LOGOUT);
		this.acc.setUserInfo(undefined, undefined);
	};

	public readonly onLogout = async (): Promise<void> => {
		await this.wreq.logout();
		this.logOut();
		if (this.cnst.AUTH === AUTH.SSO) {
			this.ssoService.authenticated = false;
			this.ssoService.logoutKC();
		} else {
			location.reload();
		}
		this.logged = false;
	};

	public readonly resetLogin = (): void => {
		localStorage.removeItem(this.getStorageKeys()[0]);
		this.ssoService.logoutKC();
		this.main.logged = false;
		const captcha = (window as any).grecaptcha;
		captcha && typeof captcha !== undefined && captcha.reset();
		this.cnst.AUTH !== AUTH.SSO ? this.route.navigate(["/login"]) : this.ssoService.loginKC();
	};

	private readonly writeSession = (ans: string): void => {
		const ks = this.getStorageKeys();
		let cipherValue = "";
		for (let i = 0; i < ans.length; i++) {
			cipherValue = cipherValue.concat(String.fromCharCode(ans.charCodeAt(i) + (i + 5) * ks[1]));
		}
		localStorage.setItem(ks[0], cipherValue);
	};

	private readonly readSession = (): string => {
		const ks = this.getStorageKeys();
		const value = localStorage.getItem(ks[0]);
		let ans = "";
		if (!value) return "";
		for (let i = 0; i < value.length; i++) {
			ans = ans.concat(String.fromCharCode(value.charCodeAt(i) - (i + 5) * ks[1]));
		}
		return ans;
	};

	private readonly getStorageKeys = (): [string, number] => {
		const navStr = navigator.userAgent;
		let key = "";
		let k = 0;
		for (let i = 0; i < navStr.length; i++) k += navStr.charCodeAt(i);
		for (let i = 0; i < 15; i++) {
			key = key.concat(String.fromCharCode(navigator.userAgent.charCodeAt(i) + (i + 5) * k));
		}
		return [key, k];
	};
}
