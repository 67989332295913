import { Component, Input } from "@angular/core";
import { Arrow } from "src/app/dto/items/arrow/arrow";
import { ITEM_STATE } from "src/app/dto/items/status";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-arrow-label",
	templateUrl: "arrow-label.component.html",
	styleUrls: ["arrow-label.css", "../map-label.css"]
})
export class ArrowLabelComponent {
	@Input() arrow!: Arrow;

	text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	public shouldShowName(): boolean {
		return this.arrow.name.length > 0 && !this.arrow.name.includes("Arrow-");
	}

	public getStatusText(): string {
		switch (this.arrow.status) {
			case ITEM_STATE.PREDICT:
				return this.text().PREDICTED;
				break;
			case ITEM_STATE.CURRENT:
				return this.text().CURRENT;
			default:
				return this.text().OVER;
		}
	}
}
