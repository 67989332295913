import { GMapMarker } from "../map/gmap-marker";
import { ShapeSet } from "../map/shape-set";
import { SHAPE_TYPE } from "./shape-type";
import { MapItem } from "./map-item";
import { AreaType } from "./types/area-type";
import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { UploadedFile } from "../net/uploaded-file";
import { EsriShapeComponent } from "src/app/incident/map/components/map/esri/components/graphics/esri-shape/esri-shape";
import { EsriMarker } from "src/app/incident/map/components/map/esri/components/graphics/esri-marker/esri-marker.interface";

export class Area extends MapItem {
	type: number;

	deleted: boolean;
	closed: boolean;

	shapeType: SHAPE_TYPE;

	// auxiliar objects, might have different structures or not even exist
	__typeObj: AreaType | undefined;
	__shape: ShapeSet | EsriShapeComponent | undefined;
	__marker: GMapMarker | EsriMarker | undefined;
	__isFilled: boolean = false;
	attachmentList: Array<UploadedFile> = [];

	public constructor(id_mission: number, id: number, type: number, name: string, shapeType: SHAPE_TYPE, description?: string, deleted?: boolean, attachment_id?: number, attachment_filename?: string, closed?: boolean, timestamp?: number) {
		super(id, name, id_mission, description, MAP_ITEM_TYPE.AREA, attachment_id, attachment_filename);
		this.id_incident = id_mission;
		this.type = type;
		this.deleted = deleted ? deleted : false;
		this.attachment_id = attachment_id ? attachment_id : -1;
		this.attachment_filename = attachment_filename ? attachment_filename : "";
		this.closed = closed ? closed : false;
		this.shapeType = shapeType;
	}

	public static getTypeFromJson: (json: string) => SHAPE_TYPE | null = (json) => {
		let obj = JSON.parse(json);
		switch (obj.shapeType.toUpperCase()) {
			case "POLYGON":
				if (obj.closed) return SHAPE_TYPE.POLYGON;
				else return SHAPE_TYPE.POLYLINE;
			case "CIRCLE":
				return SHAPE_TYPE.CIRCLE;
			default:
				return null;
		}
	};

	// this fromJson implementation should only be called on malformed strings without shapeType property, otherwise defer to the fromJson implementations from children of this class
	public static fromJson: (json: string) => Area = (json) => {
		let jc = JSON.parse(json);
		return new Area(jc.id_mission ? jc.id_mission : jc.mission_id, jc.id, jc.type, jc.name, jc.description, jc.deleted, jc.attachment_id, jc.attachment_filename, jc.closed, jc.timestamp);
	};
}
