<div class="container">
	<div>
		<app-round-checkbox
			[check]="isStatusPredict()"
			(clickCb)="selectStatus(States.PREDICT)"
		></app-round-checkbox
		><span>{{ text().PREDICTED }}</span>
	</div>
	<div>
		<app-round-checkbox
			[check]="isStatusCurrent()"
			(clickCb)="selectStatus(States.CURRENT)"
		></app-round-checkbox
		><span>{{ text().CURRENT }}</span>
	</div>
	<div>
		<app-round-checkbox
			[check]="isStatusOver()"
			(clickCb)="selectStatus(States.OVER)"
		></app-round-checkbox
		><span>{{ text().OVER }}</span>
	</div>
</div>
