import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PREFERRED_COLORS } from "src/app/global/constants/enums/preferred_colors";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-color-picker",
	templateUrl: "./color-picker.component.html",
	styleUrls: ["./color-picker.component.css"]
})
export class ColorPickerComponent implements OnInit {
	@Input() inputColor: string = "";
	@Input() showPreferred: boolean = true;
	@Output() selectedColor: EventEmitter<string> = new EventEmitter<string>();
	@Input() closeCb: Function = () => {};

	public readonly text: () => LocaleMap;
	public hue: string = "";
	public color: string = "";
	public showColorPicker: boolean = false;
	public arrowDirection: string = "up";
	public changeOrigin: number = 0; //0: Preferred color selected, 1: Color selected in canvas, 2: Change in hue slider
	public readonly preferredColours: Array<PREFERRED_COLORS> = [];

	constructor(private readonly textProv: TextProvider) {
		this.text = this.textProv.getStringMap;
		this.preferredColours = Object.entries(PREFERRED_COLORS).map(([key, value]) => {
			return value;
		});
	}

	ngOnInit(): void {
		if (!this.showPreferred) {
			this.showColorPicker = true;
		}

		if (this.inputColor) {
			this.color = this.hue = this.hex2rgba(this.inputColor);
		}
	}

	public readonly close: () => void = () => {
		this.selectedColor.emit(this.rgba2hex(this.color));
		this.closeCb();
	};

	public readonly update: () => void = () => {
		this.selectedColor.emit(this.rgba2hex(this.color));
	};

	public readonly selectPreferredColor: (color: string) => void = (color) => {
		this.changeOrigin = 0;

		this.selectedColor.emit(color); //Emitting color in HEXADECIMAL format
		this.color = this.hue = this.hex2rgba(color);
	};

	public readonly changeColor: (color: string) => void = (color) => {
		this.color = color;
	};

	public readonly changeOriginFunction: (origin: number) => void = (origin) => {
		this.changeOrigin = origin;
	};

	public readonly getArrowDirection: () => void = () => {
		return this.showColorPicker ? "up" : "down";
	};

	// COLOR TRANSFORMATION FUNCTIONS
	public readonly rgba2hex: (rgba: string) => string = (rgbaColor) => {
		const rgb = rgbaColor.match(
			/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
		);
		const hex =
			rgb && rgb.length === 4
				? "#" +
				  ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
				  ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
				  ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
				: "";
		return hex.toUpperCase();
	};

	private readonly hex2rgba: (hex: string) => string = (hexColor: string) => {
		const red = hexColor.substring(1, 3);
		const green = hexColor.substring(3, 5);
		const blue = hexColor.substring(5);

		const red10 = parseInt(red, 16);
		const green10 = parseInt(green, 16);
		const blue10 = parseInt(blue, 16);

		const rgba = "rgba(" + red10 + "," + green10 + "," + blue10 + ",1)";
		return rgba;
	};
}
