import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from "@angular/core";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { User } from "src/app/dto/user/user";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { IncidentService } from "src/app/incident/incident.service";
import { LoginService } from "src/app/login/login.service";
import { UserService } from "src/app/settings/user/user.service";
@Component({
	selector: "app-canvas-component",
	templateUrl: "canvas-component.component.html",
	styleUrls: ["canvas-component.css"]
})
export class SignatureCanvasComponent implements AfterViewInit {
	@ViewChild("canvasRef") canvasRef!: ElementRef;
	@Output() confirm = new EventEmitter<File>();
	@Output() cancel = new EventEmitter<void>();
	@Output() closeEvent = new EventEmitter<void>();
	@Output() isSomethingDrawnOut = new EventEmitter<boolean>();
	@Output() userSelected = new EventEmitter<User>();

	@Input() disabled: boolean = false;
	@Input() image?: File | UploadedFile;
	@Input() userName?: string = "-";
	@Input() isDl: boolean = false;
	@Input() selectedUser: User | undefined;
	@Input() araDetails: boolean = false;

	public users = new Array<User>();
	public currentUser: User | undefined;
	public readonly text: () => LocaleMap;
	public drawing: boolean = false;
	public drawn: boolean = false;
	public color: string = "white";
	public width: number = 0;
	public height: number = 0;
	public currX: number = 0;
	public currY: number = 0;
	public prevX: number = 0;
	public prevY: number = 0;
	public newDrawInput: boolean = false;

	private undoEntries: Array<any> = [];
	private undoStep: number = -1;
	private canvas!: HTMLCanvasElement;

	constructor(
		private readonly ems: IncidentService,
		private readonly loginServ: LoginService,
		private readonly userServ: UserService,
		private readonly cdr: ChangeDetectorRef,
		textProv: TextProvider
	) {
		this.text = textProv.getStringMap;
	}

	ngAfterViewInit(): void {
		this.currentUser = this.loginServ.user;
		this.users = this.userServ.Users;
		this.users.sort((a, b) => {
			if (a.name.toUpperCase() >= b.name.toUpperCase()) return -1;
			return 1;
		});

		if (this.isDl) {
			const savedUser = this.userServ.getSelectedUser();
			if (savedUser) {
				this.selectedUser = savedUser;
			} else if (this.currentUser) {
				if (!this.drawn) this.selectedUser = this.currentUser;
			}
		}
		this.canvas = this.canvasRef.nativeElement;
		this.setCanvasDimensions();
		if (this.image) {
			const ctx = this.getCtx();
			const image = new Image();
			image.onload = () => ctx?.drawImage(image, 0, 0);
			image.src = URL.createObjectURL(this.image as File);
			this.onSomethingDrawn();
			this.cdr.detectChanges();
			this.newDrawInput = false;
		}
	}

	public onSomethingDrawn: () => void = () => {
		this.drawn = true;
		this.isSomethingDrawnOut.emit(this.drawn as boolean);
		this.newDrawInput = true;
		this.addNewUndoEntry();
	};

	public readonly setCanvasDimensions: Function = () => {
		this.canvas.width = this.canvas.offsetWidth - 4;
		this.canvas.height = this.canvas.offsetHeight - 4;
		this.width = this.canvas.width;
		this.height = this.canvas.height;
	};

	/** TEMPLATE INTERFACE */
	public readonly canvasMousedown: (evt: MouseEvent) => void = (evt) => {
		this.canvasInteraction(evt.offsetX, evt.offsetY);
	};

	public readonly canvasTouchdown: (evt: TouchEvent) => void = (evt) => {
		const x = evt.touches[0].clientX - this.canvas.getBoundingClientRect().x;
		const y = evt.touches[0].clientY - this.canvas.getBoundingClientRect().y;
		this.canvasInteraction(x, y);
	};

	public readonly canvasMousemove: (evt: MouseEvent) => void = (evt) => {
		this.canvasMovement(evt.offsetX, evt.offsetY);
	};

	public readonly canvasTouchmove: (evt: TouchEvent) => void = (evt) => {
		evt.cancelable && evt.preventDefault(); // Prevent scrolling.
		const rect = this.canvas.getBoundingClientRect();
		const x = evt.touches[0].clientX - rect.x;
		const y = evt.touches[0].clientY - rect.y;
		this.canvasMovement(x, y);
	};

	public readonly canvasMouseup: (evt: MouseEvent) => void = (evt) => {
		this.canvasUp();
	};

	public readonly canvasTouchup: (evt: TouchEvent) => void = (evt) => {
		this.canvasUp();
	};

	/** TEMPLATE INTERFACE END */
	/** BUTTON EVENTS */

	public readonly undoDraw: Function = () => {
		if (this.undoStep <= 0) {
			return this.eraseAllDraw();
		}

		this.undoStep--;
		const canvasPic = new Image();
		canvasPic.src = this.undoEntries[this.undoStep];
		const ctx = this.getCtx();
		canvasPic.onload = () => {
			ctx.clearRect(0, 0, this.width, this.height);
			ctx.drawImage(canvasPic, 0, 0);
		};
	};

	public readonly eraseAllDraw = (): void => {
		if (!this.drawn) return;
		const ctx = this.getCtx();
		ctx.clearRect(0, 0, this.width, this.height);
		this.drawn = false;
		this.undoStep = -1;
		this.undoEntries = [];
		this.userName = "-";
		this.cancel.emit();
	};

	public readonly mainSave: Function = async () => {
		this.canvas.toBlob((blob: Blob | null) => {
			if (!blob) return;
			const file = new File(
				[blob],
				"signature-" +
					this.ems.getCurrentIncident()?.id +
					"-" +
					Number.parseFloat(Math.random().toFixed(3)) * 1000 +
					".png",
				blob
			);

			return this.confirm.emit(file);
		});
	};

	public readonly canvasBlur: (evt: Event) => void = (evt) => {
		if (this.drawing) {
			this.drawing = false;
			this.canvasUp();
		}
	};

	public readonly userDropdownGetMainText: () => string = () => {
		this.userSelected.emit(this.selectedUser);
		if (this.selectedUser) this.userServ.setSelectedUser(this.selectedUser);
		return this.selectedUser ? this.selectedUser.name : "";
	};

	public readonly userDropdownCompareSelect: (selected: User, option: User) => boolean = (
		selected,
		option
	) => {
		return option.id === this.selectedUser?.id;
	};

	public readonly userDropdownChangeCallback: (option: User) => void = (option) => {
		this.selectedUser = option;
		this.newDrawInput = true;
		this.userSelected.emit(option);
		this.userServ.setSelectedUser(option);
	};

	/** CANVAS INTERACTION */

	private readonly canvasInteraction: (x: number, y: number) => void = (x, y) => {
		this.drawing = true;
		this.prevX = this.currX;
		this.prevY = this.currY;
		this.currX = x;
		this.currY = y;

		const ctx = this.getCtx();
		ctx.beginPath();
		ctx.fillStyle = this.color;
		ctx.lineWidth = 2;
		ctx.fillRect(this.currX, this.currY, 2, 2);
		ctx.closePath();
	};

	private readonly canvasMovement: (x: number, y: number) => void = (x, y) => {
		if (!this.drawing) return;

		this.prevX = this.currX;
		this.prevY = this.currY;
		this.currX = x;
		this.currY = y;
		this.draw();
	};

	private readonly canvasUp: () => void = () => {
		this.drawing = false;
		this.onSomethingDrawn();
	};

	private readonly draw: Function = () => {
		const ctx = this.getCtx();

		ctx.beginPath();
		ctx.moveTo(this.prevX, this.prevY);
		ctx.lineTo(this.currX, this.currY);
		ctx.strokeStyle = this.color;
		ctx.lineWidth = 2;
		ctx.stroke();
		ctx.closePath();
	};

	private readonly addNewUndoEntry = () => {
		this.undoStep++;
		if (this.undoStep < this.undoEntries.length) {
			this.undoEntries.length = this.undoStep;
		}
		this.undoEntries.push(this.canvas.toDataURL());
	};

	private readonly getCtx = (): CanvasRenderingContext2D =>
		this.canvas.getContext("2d", { willReadFrequently: true })!;
}
