import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CSSectorResourceAssignation } from "src/app/dto/command-structure/cs-resource";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { CommandStructureService } from "src/app/incident/incident-tools/command-structure/command-structure.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { CSNode } from "../CSNode";
import { NavigationService } from "src/app/navigation/navigation.service";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { IncidentService } from "../../../../../../incident/incident.service";
import { Resource } from "src/app/dto/resources/resource";
import { Appliance } from "src/app/dto/resources/appliance";
import { Personnel } from "src/app/dto/resources/personnel";

@Component({
	selector: "app-cs-sector",
	templateUrl: "sector.component.html",
	styleUrls: ["../../chart.css", "sector.css"]
})
export class CSSectorNodeComponent implements CSNode {
	@Input() object!: CSSector;
	@Input() width!: number;
	@Input() height!: number;
	@Input() mapMode: boolean = false;
	@Input() disable: boolean = false;
	@Input() dragging: boolean = false;
	@Input() draggingPersonnel = false;

	@Output() removeSector = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();

	@Input() onResourceDragStart: Function = () => {};
	@Input() onResourceDragCancel: Function = () => {};
	@Input() onResourceDragEnd: Function = () => {};

	public readonly text: () => LocaleMap;
	public editingSector: CSSector | undefined;
	public isFocused: boolean = false;
	public shownSkillIndexes: Map<number, boolean> = new Map<number, boolean>();

	private showMenu: boolean = false;
	private nav: NavigationService;
	private ems: IncidentService;
	private cs: CommandStructureService;

	constructor(textProv: TextProvider, cs: CommandStructureService, nav: NavigationService, ems: IncidentService) {
		this.text = textProv.getStringMap;
		this.nav = nav;
		this.ems = ems;
		this.cs = cs;
	}

	public readonly onMainUnassignedClick: Function = () => {
		this.nav.changeScreen(SCREEN.INCIDENT);
		this.nav.commandStructureQuery$.next({ type: "assign", object: this.object });
	};

	public readonly isMenuOpen: Function = () => {
		return this.showMenu;
	};

	public readonly openMenu: Function = (evt: MouseEvent) => {
		this.showMenu = !this.showMenu;
		evt.stopPropagation();
		return false;
	};

	public readonly toggleEditable = (sector: CSSector): CSSector => {
		this.isFocused = true;
		return (this.editingSector = { ...sector });
	};

	public setTitleBackground(): { background?: string } {
		return this.object.id_area > -1 ? { background: this.hexToRgba(this.object.color) } : {};
	}

	public setFlagBackground(): string {
		return this.object.id > -1 ? this.object.color : "white";
	}

	public readonly setStatusBackground: Function = (resource: CSSectorResourceAssignation) => {
		return resource.__state ? { backgroundColor: resource.__state!.color } : {};
	};

	public readonly setSectorName: Function = (newData: string) => {
		this.object.name = newData;
		if (this.object.__area) this.object.__area.name = newData;
	};

	public readonly setSectorTasks: Function = (newData: string) => {
		this.object.tasks = newData;
		this.saveChanges();
	};

	public readonly saveChanges: Function = () => {
		if (this.editingSector) {
			const saveAreaName = this.object.name !== this.editingSector.name;
			this.cs.saveSector(this.object, saveAreaName);
		}
		if (!this.isIncidentClosed() && !this.disable) {
			this.cs.saveSector(this.object);
			this.editingSector = undefined;
			this.showMenu = false;
		}
	};

	public readonly goToArea: Function = () => {
		if (!this.object.__area) this.cs.setupSector(this.object);

		if (this.object.__area) {
			this.nav.changeScreen(SCREEN.INCIDENT);
			this.nav.commandStructureQuery$.next({ type: "goTo", object: this.object.__area });
		}
	};

	public isIncidentClosed(): boolean {
		const currentIncident = this.ems.getCurrentIncident();
		return currentIncident?.closed || false;
	}

	public shouldDisable(): boolean {
		return this.dragging || this.draggingPersonnel || this.isIncidentClosed();
	}

	public getCurrentIncidentId(): number | undefined {
		const currentIncidentId = this.ems.getCurrentIncident()?.id;
		if (currentIncidentId) return currentIncidentId;
		return;
	}

	public toggleSkills(index: number): void {
		if (this.shownSkillIndexes.has(index)) {
			this.shownSkillIndexes.delete(index);
		} else {
			this.shownSkillIndexes.set(index, true);
		}
	}

	public togglePersonnelSkills(index: number): void {
		if (this.shownSkillIndexes.has(index)) {
			this.shownSkillIndexes.delete(index);
		} else {
			this.shownSkillIndexes.set(index, true);
		}
	}

	public shouldDisplaySkills(person: Personnel, resource?: Resource): boolean {
		const appliance = resource as Appliance;
		const incidentId = this.getCurrentIncidentId()!;
		const displayAllowed = !!person.skills && !person.deleted;
		return (displayAllowed && person.id_incident === incidentId) || this.isOic(appliance, person);
	}

	public isOic(resource: Resource, person: Personnel): boolean {
		const appliance = resource as Appliance;
		const oic = appliance.oic;
		if (!oic) return false;
		return oic.id === person.id;
	}

	public filterClosedPersonnelList(resource: Resource): Personnel[] {
		if (!(resource instanceof Appliance)) return [];
		return resource.closed_personnel.filter((e) => e.id_incident === this.getCurrentIncidentId()).map((e) => e.object);
	}

	private hexToRgba(hex: string): string {
		if (!hex) return "#041520";
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.3)` : "#041520";
	}
}
