import { Area } from "src/app/dto/items/area";
import { CircleArea } from "src/app/dto/items/circle-area";
import { Poi } from "src/app/dto/items/poi";
import { PolygonArea } from "src/app/dto/items/polygon-area";
import { SHAPE_TYPE } from "src/app/dto/items/shape-type";
import { ITEM_STATE } from "src/app/dto/items/status";
import { WGSPoint } from "src/app/dto/map/location";

export abstract class MapAuxiliar {
	public static getShortestPoint(p: WGSPoint, arr: Array<WGSPoint>): WGSPoint {
		let diff = Math.sqrt(
			Math.pow(arr[0].latitude - p.latitude, 2) + Math.pow(arr[0].longitude - p.longitude, 2)
		);
		let currentShortestPoint = arr[0];
		for (let i = 1; i < arr.length; i++) {
			const newDiff = Math.sqrt(
				Math.pow(arr[i].latitude - p.latitude, 2) +
					Math.pow(arr[i].longitude - p.longitude, 2)
			);
			if (newDiff < diff) {
				diff = newDiff;
				currentShortestPoint = arr[i];
			}
		}
		return currentShortestPoint;
	}

	public static isClosed: Function = (area: Area | Array<WGSPoint>) => {
		if (area instanceof Area && area.shapeType === SHAPE_TYPE.CIRCLE) return true;
		const vertices = area instanceof PolygonArea ? area.vertices : (area as Array<WGSPoint>);
		const lastPoint = vertices[vertices.length - 1];
		for (let it = 0; it < vertices.length - 1; it++) {
			if (
				Math.abs(vertices[it].lat - lastPoint.lat) < 0.0001 &&
				Math.abs(vertices[it].lng - lastPoint.lng) < 0.0001
			)
				return true;
		}
		return false;
	};

	public static getAreaMarkerLocation: (area: Area) => WGSPoint = (area) => {
		switch (area.shapeType) {
			case SHAPE_TYPE.CIRCLE:
				let circle = area as CircleArea;
				const ans = MapAuxiliar.distanceFromCoords(circle.center, circle.radius);
				return ans!;
			default:
				let poly = area as PolygonArea;
				const ansp = MapAuxiliar.getHigherY(poly.vertices);
				return ansp;
		}
	};

	public static distanceFromCoords: (origin: WGSPoint, dist: number) => WGSPoint | undefined = (
		origin,
		dist
	) => {
		dist = dist / 6371000;
		var lat1 = MapAuxiliar.toRad(origin.latitude),
			lon1 = MapAuxiliar.toRad(origin.longitude);
		var lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) + Math.cos(lat1) * Math.sin(dist));
		var lon2 = lon1 + Math.atan(0 / (Math.cos(dist) - Math.sin(lat1) * Math.sin(lat2)));
		if (isNaN(lat2) || isNaN(lon2)) return undefined;
		return new WGSPoint(MapAuxiliar.toDeg(lat2), MapAuxiliar.toDeg(lon2));
	};
	public static toRad: Function = (input: number) => {
		return (input * Math.PI) / 180;
	};
	public static toDeg: Function = (input: number) => {
		return (input * 180) / Math.PI;
	};

	public static getHigherY: (points: Array<WGSPoint>) => WGSPoint = (points) => {
		var maxy = 0;
		var maxi = 0;
		//var pos;
		for (var i = 0; i < points.length; i++) {
			//pos = getCanvasXY(latlngArray[i]);
			if (points[i].latitude > maxy) {
				maxy = points[i].latitude;
				maxi = i;
			}
		}
		return new WGSPoint(points[maxi].latitude, points[maxi].longitude);
	};

	// uses Haversine function: https://en.wikipedia.org/wiki/Haversine_formula
	public static readonly distanceBetweenPointsInMeters = (p1: WGSPoint, p2: WGSPoint): number => {
		if (!p1 || !p2) {
			return 0;
		}
		var R = 6371;
		var dLat = MapAuxiliar.toRad(p2.latitude - p1.latitude);
		var dLon = MapAuxiliar.toRad(p2.longitude - p1.longitude);
		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(MapAuxiliar.toRad(p1.latitude)) *
				Math.cos(MapAuxiliar.toRad(p2.latitude)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d * 1000;
	};

	public static readonly getFullColourHexCode = (input: string): string => {
		if (input.toLowerCase().match(/#[1234567890abcdef]{6}/gm)) {
			return input;
		} else {
			if (input.toLowerCase().match(/#[1234567890abcdef]{3}/gm)) {
				return "#" + input[1] + input[1] + input[2] + input[2] + input[3] + input[3];
			} else {
				console.warn("unable to parse colour value " + input);
				return "#ffffff";
			}
		}
	};

	public static readonly HexColourToRGBA = (
		input: string,
		alpha?: number
	): [number, number, number, number] => {
		const ans: [number, number, number, number] = [0, 0, 0, 0];
		const fullLengthInput = MapAuxiliar.getFullColourHexCode(input);
		ans[0] = Number("0x" + fullLengthInput[1] + fullLengthInput[2]);
		ans[1] = Number("0x" + fullLengthInput[3] + fullLengthInput[4]);
		ans[2] = Number("0x" + fullLengthInput[5] + fullLengthInput[6]);
		ans[3] = alpha ? alpha : 1;
		return ans;
	};

	public static readonly getPoiAlphaValue = (poi: Poi): number => {
		switch (poi.status) {
			case ITEM_STATE.PREDICT:
				return 0.01;
			case ITEM_STATE.OVER:
				return 0.5;
			case ITEM_STATE.CURRENT:
				return 1;
		}
	};
}
