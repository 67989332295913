import { Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";

/**
 * Makes an item draggable and droppable to some containers.
 * To flag a container as droppable, a property "dropzone" must be set with whatever tag.
 * If the draggable element is dropped to a container that has no "dropzone" property, the drag is considered
 * cancelled.
 *
 * It doesn't do any DOM modification (it doesn't remove the element from its original father or add it to the outgoing dropzone).
 * That manipulation needs to be done at the above layer.
 */

@Directive({
	selector: "[appDroppable]"
})
export class DroppableDirective {
	@Input() dragInfo: any;
	@Input() disabled: boolean | undefined;

	@Output() dragStart = new EventEmitter<any>();
	@Output() dragEnd = new EventEmitter<{ zone: string; info: string | number | undefined; target: HTMLElement | undefined }>();
	@Output() dragCancel = new EventEmitter<{ info: any }>();

	private elem: HTMLElement;
	private draggableElem: HTMLElement | undefined;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
		if(this.disabled) return;
		this.elem.style.cursor = "grab";
		this.elem.addEventListener("mousedown", this.dragBeginClick as any);
		this.elem.addEventListener("touchstart", this.dragBeginTouch as any);
		this.elem.addEventListener("touchdown", () => {});
	}

	private readonly dragBeginTouch: Function = (evt: TouchEvent) => {
		const isSkillsOverflow = (target: HTMLElement | null): boolean => {
			if (!target) return false;
			if (target.classList.contains("skills-overflow")) return true;
			return isSkillsOverflow(target.parentElement);
		};
		if (isSkillsOverflow(evt.target as HTMLElement)) {
			evt.preventDefault();
			evt.stopPropagation();
			return;
		}
		this.dragStart.emit(this.dragInfo);
		this.setupDraggableElement(evt.touches[0].clientX, evt.touches[0].clientY);
		document.body.addEventListener("touchend", this.dragFinish as any);
		document.body.addEventListener("touchmove", this.onmove as any, { passive: false });
		evt.preventDefault();
		evt.stopPropagation();
	};

	private readonly dragBeginClick: Function = (evt: MouseEvent) => {
		const isSkillsOverflow = (target: HTMLElement | null): boolean => {
			if (!target) return false;
			if (target.classList.contains("skills-overflow")) return true;
			return isSkillsOverflow(target.parentElement);
		};
		if (isSkillsOverflow(evt.target as HTMLElement)) {
			evt.preventDefault();
			evt.stopPropagation();
			return;
		}

		this.dragStart.emit(this.dragInfo);
		this.setupDraggableElement(evt.x, evt.y);
		document.body.addEventListener("mouseup", this.dragFinish as any);
		document.body.addEventListener("mousemove", this.onmove as any);
		evt.preventDefault();
		evt.stopPropagation();
	};

	private readonly setupDraggableElement: Function = (x: number, y: number) => {
		this.draggableElem = this.elem.cloneNode(true) as HTMLElement;
		this.draggableElem.style.position = "fixed";
		this.draggableElem.style.top = y + "px";
		this.draggableElem.style.left = x + "px";
		this.draggableElem.style.pointerEvents = "none";
		this.draggableElem.style.zIndex = "15";
		document.body.appendChild(this.draggableElem);
		document.body.style.cursor = "grabbing";
	};

	private readonly dragFinish: Function = (evt: MouseEvent | TouchEvent) => {
		let target;
		if (evt instanceof MouseEvent) target = evt.target;
		if (evt instanceof TouchEvent) target = document.elementFromPoint(evt.changedTouches[0].clientX, evt.changedTouches[0].clientY);
		document.body.style.cursor = "";
		target = this.getDropzoneElement(target as HTMLElement);
		let zone = target?.getAttribute("dropzone");
		if (target) this.dragEnd.emit({ zone: target.getAttribute("dropzone")!, info: this.dragInfo, target: target as HTMLElement });
		else this.dragCancel.emit({ info: this.dragInfo });
		document.body.removeChild(this.draggableElem as any);
		document.body.removeEventListener("mouseup", this.dragFinish as any);
		document.body.removeEventListener("mousemove", this.onmove as any);
		document.body.removeEventListener("touchend", this.dragFinish as any);
		document.body.removeEventListener("touchmove", this.onmove as any);
		this.draggableElem = undefined;
	};

	private readonly onmove: Function = (evt: MouseEvent | TouchEvent) => {
		evt.stopPropagation();
		if (evt instanceof MouseEvent) {
			this.draggableElem!.style.top = evt.y + "px";
			this.draggableElem!.style.left = evt.x + "px";
		}
		if (evt instanceof TouchEvent) {
			this.draggableElem!.style.top = evt.touches[0].clientY + "px";
			this.draggableElem!.style.left = evt.touches[0].clientX + "px";
		}
		evt.preventDefault();
	};

	private readonly getDropzoneElement: (elem: HTMLElement) => HTMLElement | null = (elem) => {
		if (elem.getAttribute("dropzone")) return elem;
		else {
			if (elem.parentElement) return this.getDropzoneElement(elem.parentElement);
			else return null;
		}
	};
}
