<div *ngIf="canCreateDl" class="new-decision-form" data-cy="new-decision-form">
	<div class="body">
		<div *ngIf="dlType" class="type-logged-container">
			<div class="type-col">
				<div class="type-title-wrapper">
					<div class="new-entry-title">{{ text().TYPE }}</div>
					<p class="asterix">*</p>
				</div>
				<div data-cy="type-options" class="type-options">
					<div class="type-option" data-add-action-name="event" (click)="toggleEvent()">
						<app-blue-checkbox [check]="isEvent"></app-blue-checkbox>
						<span>{{ text().EVENT_2 }}</span>
					</div>
					<div
						class="type-option"
						data-add-action-name="decision"
						(click)="toggleDecision()"
					>
						<app-blue-checkbox [check]="isDecision"></app-blue-checkbox>
						<span>{{ text().DECISION }}</span>
					</div>
					<div
						class="type-option"
						data-add-action-name="message"
						(click)="toggleMessage()"
					>
						<app-blue-checkbox [check]="isMessage"></app-blue-checkbox>
						<span>{{ text().MESSAGE }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="content-rationale-container">
			<div data-cy="content-col" class="content-col">
				<div class="new-entry-title">{{ text().CONTENT }}</div>
				<div class="textarea-container position-relative">
					<textarea data-cy="content-textarea" [(ngModel)]="content"></textarea>
				</div>
			</div>
			<div class="record-col">
				<div class="record-button" [class.cursor-default]="predicting">
					<img
						src="resources/img/audio-record.svg"
						(click)="recordDecLog(); $event.stopPropagation()"
						appSpeechToText
						(audio)="onAudioAvailable($event)"
						(outcome)="onTranscriptionAvailable($event)"
						(recordingEnd)="recording = false"
						[class.recording]="predicting"
					/>
				</div>
				<div class="spectrogram" *ngIf="recording">
					<div *ngFor="let idx of arr" class="spectrogram-bar"></div>
				</div>
				<div class="loader" *ngIf="predicting">
					<img
						src="resources/img/animations/loader.gif"
						alt="animated circle"
						width="36"
						height="36"
					/>
				</div>
			</div>
			<div data-cy="rationale-col" class="rationale-col">
				<div class="new-entry-title">{{ text().RATIONALE }}</div>
				<div class="textarea-container">
					<textarea data-cy="rationale-textarea" [(ngModel)]="rationale"></textarea>
				</div>
			</div>
		</div>

		<!-- TACTICAL; STATUS; SIGNATURE -->
		<div *ngIf="dlOperational || dlSignature" class="wrap-tactical-signature-container">
			<div *ngIf="dlOperational" class="tactical-container">
				<div class="tactical-drop-container">
					<app-blue-dropdown
						[object]="selectedTactic"
						[options]="TacticalOptions"
						[placeholderText]="text().TACTICAL"
						[getObjectText]="tacticDropdownGetMainText"
						[getOptionText]="tacticDropdownGetOptionText"
						[compareSelect]="tacticDropdownCompareSelect"
						[height]="36"
						(itemClickCallback)="tacticDropdownChangeCallback($event)"
					>
					</app-blue-dropdown>
				</div>
				<div class="state-drop-container">
					<app-blue-dropdown
						[object]="selectedState"
						[options]="StateOptions"
						[placeholderText]="text().STATUS"
						[getObjectText]="stateDropdownGetMainText"
						[getOptionText]="stateDropdownGetOptionText"
						[compareSelect]="stateDropdownCompareSelect"
						[height]="36"
						(itemClickCallback)="stateDropdownChangeCallback($event)"
					>
					</app-blue-dropdown>
				</div>
				<app-signature-button
					*ngIf="dlSignature"
					[isInDecisionLog]="true"
					[pendingToSign]="!signature"
					[signature]="signature"
					[selectedUser]="selectedUser ? selectedUser : currentUser"
					[ready]="!!signature"
					(confirm)="addSignature($event)"
					(signatureCanceled)="onSignatureCanceled()"
				></app-signature-button>
			</div>
		</div>

		<!-- MEDIA; SAVE -->
		<div class="save-button-container save-media-container">
			<div class="media-wrapper">
				<div class="new-entry-title add-media-title" [class.files]="uploadedFiles.length">
					<div *ngIf="!uploadedFiles.length" class="media-title">Media</div>
					<div *ngIf="uploadedFiles.length" class="file-list">
						<div
							*ngFor="let file of uploadedFiles | slice : 0 : 3; let i = index"
							[attr.data-cy]="'csMediaItem-' + i"
							class="media-item media-{{ i + 1 }}"
							[class.cropped]="i >= 1"
						>
							<div *ngIf="!file.isImage()">
								<div class="thumbnail">
									<img src="{{ isAudioOrVideo(file) }}" width="12" height="12" />
								</div>
							</div>
							<div *ngIf="file.isImage()">
								<img
									class="image"
									[src]="file.__uri"
									width="{{ i === 0 ? '40' : '35' }}"
									height="40"
								/>
							</div>
						</div>

						<button
							*ngIf="uploadedFiles.length > 3"
							data-add-action-name="cs-more-button"
							class="more-button"
						>
							<span>...</span>
						</button>
					</div>
					<label class="main-blue-gradient-button add-button">
						<input
							data-add-action-name="dl-file-upload"
							(change)="addNewFile($event)"
							id="file-upload"
							type="file"
						/>
						{{ text().ADD }}
					</label>
				</div>
				<p class="filesize">{{ text().MAX_FILESIZE }} 100MB</p>
			</div>
			<button
				data-add-action-name="dl-save"
				class="main-blue-gradient-button"
				(click)="saveNewDecision()"
			>
				{{ text().SAVE }}
			</button>
		</div>
	</div>
</div>
<app-compulsory-fields
	*ngIf="showCompulsoryFields"
	[fields]="compulsoryFields"
	(cancel)="closeCompulsoryFields()"
></app-compulsory-fields>
